var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"formation-category d-flex align-center"},[_c('div',{staticClass:"formation-category__label"},[_vm._v(" "+_vm._s(_vm.label)+": ")]),(_vm.readonly)?_c('div',{staticClass:"d-flex align-center ml-4"},[_c('span',[_vm._v(_vm._s(_vm.levelLabels[_vm.value].text))]),_c('v-tooltip',{attrs:{"right":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"width":"20","height":"20","color":"primary","fab":"","x-small":"","elevation":"0"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-help")])],1)]}}],null,false,1165860545)},[_c('span',[_vm._v(_vm._s(_vm.levelLabels[_vm.value].description))])])],1):_vm._e(),(!_vm.readonly)?_c('div',{staticClass:"ml-4"},[_c('v-btn-toggle',{attrs:{"color":"primary","value":_vm.value,"dense":"","mandatory":""},on:{"change":_vm.updateValue}},[_c('v-tooltip',{attrs:{"top":"","max-width":"300","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"value":"EXPECTED"}},'v-btn',attrs,false),on),[_vm._v(" Esperado ")])]}}],null,false,894834039)},[_c('span',[_vm._v(_vm._s(_vm.levelLabels['EXPECTED'].description))])]),_c('v-tooltip',{attrs:{"top":"","max-width":"300","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"value":"RECOMMENDED"}},'v-btn',attrs,false),on),[_c('span',[_vm._v("Recomendado")])])]}}],null,false,126310799)},[_c('span',[_vm._v(" "+_vm._s(_vm.levelLabels['RECOMMENDED'].description)+" ")])])],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }