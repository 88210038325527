export default class CareerUpdatePersist {
  role: string

  seniority: string

  workplace: string

  operationModality: string

  businessUnit: string

  resultCenter: string

  manager: string

  techLead: string

  squad: string

  observation: string

  assignment: string

  secondaryAssignmentId: string;

  constructor(obj: any) {
    this.role = obj.role || '';
    this.seniority = obj.seniority || '';
    this.workplace = obj.workplace || '';
    this.operationModality = obj.operationModality || '';
    this.businessUnit = obj.businessUnit || '';
    this.resultCenter = obj.resultCenter || '';
    this.manager = obj.manager || '';
    this.squad = obj.squad || '';
    this.observation = obj.observation || '';
    this.assignment = obj.assignment || '';
    this.techLead = obj.techLead || '';
    this.secondaryAssignmentId = obj.secondaryAssignmentId || '';
  }
}
