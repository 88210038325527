import axios from 'axios';
import ResignationListFilter from '@/domain/resignation/entity/resignation-filter.entity';
import Response from '../../employees/entity/response.entity';
import ResignationFilter from '../entity/filter.entity';
import ResignationRequest from '../entity/resignation-request.entity';
import Resignation from '../entity/resignation.entity';
import ResignationDetails from '../entity/resignation-details.entity';
import RescissionEvidence from '../entity/rescission-evidence.entity';
import RescissionEvidenceRequest from '../entity/rescission-evidence-request.entity';

const RESIGNATION_COLUMN_MAPPER = {
  employeeName: 'employee.name',
  shutdownTime: 'shutdownTime',
  manager: 'employee.careerUpdates.current.manager.name',
  businessUnit: 'employee.careerUpdates.current.businessUnit.name',
};

const mapColumn = (columnName: string): string => {
  if (!columnName) return RESIGNATION_COLUMN_MAPPER.shutdownTime;
  return RESIGNATION_COLUMN_MAPPER[columnName];
};

export default class ResignationService {
  public static findResignation(
    page: number, size: number, filter: ResignationFilter,
  ): Promise<Response> {
    return axios.post('/api/resignation/staff', filter, {
      params: {
        page: page - 1,
        size,
      },
    })
      .then((response: any) => Response.of(response.data));
  }

  public static save(resignation: ResignationRequest, file: any): Promise<void> {
    const formData = new FormData();
    formData.append('json', JSON.stringify(resignation));
    formData.append('file', file);
    return axios.post('/api/resignation', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      maxBodyLength: Infinity,
      maxContentLength: Infinity,
    })
      .then((resp: any) => resp);
  }

  public static getResignations(filter: ResignationListFilter, pagination: any): Promise<any> {
    return axios.get('/api/resignation', {
      params: {
        ...filter,
        page: pagination.page - 1,
        size: pagination.itemsPerPage,
        orderBy: `${mapColumn(pagination.sortBy[0])}|${pagination.sortDesc[0] ? 'desc' : 'asc'}`,
      },
    });
  }

  public static deleteById(id: string): Promise<void> {
    return axios.delete(`/api/resignation/${id}`);
  }

  public static getResignationById(id: string): Promise<any> {
    return axios.get(`/api/resignation/${id}`);
  }

  public static updateResignation(resignation: ResignationDetails): Promise<void> {
    return axios.put(`/api/resignation/${resignation.id}`, resignation.toRequest());
  }

  public static async getRescissionEvidences(resignationId: string): Promise<RescissionEvidence[]> {
    return axios.get(`/api/resignation/${resignationId}/rescission`)
      .then((response: any) => response.data
        .map((evidence: any) => new RescissionEvidence(evidence)));
  }

  public static async getStaffAttachedRescissionEvidence(): Promise<string[]> {
    return axios.get('/api/resignation/staff/attached-rescission-evidence-names')
      .then(({ data }) => data);
  }

  public static async createRescissionEvidence(
    resignationId: string,
    data: RescissionEvidenceRequest,
  ): Promise<void> {
    const formData = new FormData();
    formData.append('area', data.area);
    if (data.attachment) {
      formData.append('attachment', data.attachment);
    }
    formData.append('observation', data.observation);
    return axios.post(`/api/resignation/${resignationId}/rescission`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public static async downloadRescissionEvidence(rescissionEvidenceId: string): Promise<Blob> {
    return axios.get(`/api/resignation/rescission-evidence/${rescissionEvidenceId}/download`, {
      responseType: 'blob',
    }).then(({ data }) => data);
  }
}
