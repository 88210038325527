import axios from 'axios';
import Filter from '../entity/filter.entity';
import Response from '../entity/response.entity';
import Skill from '../entity/skill.entity';
import SkillStatus from '../entity/skill-type.entity copy';
import EmployeeSkill from '../entity/employee-skill.entity';

export default class SkillService {
  public static getAllSkillsByEmployee(employeeId: string): Promise<Response> {
    return axios.get('/api/knowledge/employees', {
      params: {
        employeeId,
        skillActive: true,
        page: 0,
        size: 100,
      },
    })
      .then((response: any) => Response.of(response.data));
  }

  public static getAllWithEmployee(
    filter: Filter,
    page: number,
    size: number,
  ): Promise<EmployeeSkill[]> {
    return axios.get('/api/knowledge/employees', {
      params: {
        employeeId: filter.hasEmployee ? filter.employee.toString() : null,
        managers: filter.hasManager ? filter.manager.toString() : null,
        resultCenter: filter.hasResultCenter ? filter.resultCenter.toString() : null,
        knowledge: filter.allSkillFilter.length > 0 ? filter.allSkillFilter.toString() : null,
        assignments: filter.hasAssignment ? filter.assignment.toString() : null,
        roles: filter.hasRole ? filter.role.toString() : null,
        hasKnowledge: filter.hasSkill,
        businessUnits: filter.hasBusinessUnit ? filter.businessUnit.toString() : null,
        page: page - 1,
        employeeActive: true,
        size,
      },
    })
      .then((response: any) => response.data);
  }

  public static getAllActiveAndAproved(): Promise<Response> {
    return axios.get('/api/knowledge/all', {
      params: {
        active: true,
        status: SkillStatus.APPROVED,
        page: 0,
        size: 999,
      },
    })
      .then((response: any) => Response.of(response.data));
  }

  public static getAllActive(filter: Filter, page = 1, size = 10): Promise<Response> {
    return axios.get('/api/knowledge/all', {
      params: {
        knowledgeIds: filter.allSkillFilter.length > 0 ? filter.allSkillFilter.toString() : null,
        status: filter.status ? filter.status : null,
        active: true,
        page: page - 1,
        size,
      },
    })
      .then((response: any) => Response.of(response.data));
  }

  public static getAllByType(description, type): Promise<Skill[]> {
    return axios.get('/api/knowledge/allByType', {
      params: {
        active: true,
        description,
        orderBy: 'description|asc',
        type,
      },
    })
      .then((response: any) => Skill.ofList(response.data));
  }

  public static createNewSkill(skill: Skill): Promise<void> {
    return axios.post('/api/knowledge/new', skill).then((resp: any) => resp);
  }

  public static updateSkill(skill: Skill): Promise<void> {
    return axios.put(`/api/knowledge/${skill.id}`, skill).then((resp: any) => resp);
  }

  public static deleteSkillById(id: string): Promise<void> {
    return axios.delete(`/api/knowledge/${id}`);
  }

  public static addSkillToEmployee(
    employeId: string | null,
    employeeSkills: string[],
  ): Promise<void> {
    return axios.post(`/api/knowledge/${employeId}/updateKnowledge`, employeeSkills).then((resp: any) => resp);
  }

  public static getAllByEmployeeId(employeeId: string) {
    return axios.get(`/api/employee/${employeeId}/knowledge`);
  }
}
