


































import { Vue, Component, Prop } from 'vue-property-decorator';
import Agreement from '@/domain/employees/entity/agreement.entity';
import formatCurrency from '@/shared/filters/currency.filter';
import CurrencyFormater from '@/shared/utils/currency-formater';
import { Currency } from '@/domain/employees/entity/professional-data.entity';
import { FixedAgreement } from '@/views/employees/profile/tab-components/score/score-utils';
import DateFormat from '@/shared/utils/date-format';
import headers from './agreements-table-header.json';

@Component
export default class AgreementsTableComponent extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  agreements!: Agreement[];

  @Prop({
    type: Agreement,
    default: undefined,
  })
  totalAgreement!: Agreement;

  @Prop({
    required: true,
  })
  currency!: Currency;

  headers: any[] = headers;

  FixedAgreementEnum = FixedAgreement;

  formatToCurrencyIfNumericField(value: any, numericField: boolean): string {
    if (!numericField) return value;
    if (value === 'NOT_EXISTS') return '-';
    if (!value) return `${CurrencyFormater.toCurrency(0, this.currency)}`;
    const floatValue = parseFloat(value);
    return `${CurrencyFormater.toCurrency(floatValue, this.currency)}`;
  }

  formatDate(date: string): string {
    return DateFormat.standardDate(date)
  }

  formatToPercent(agreement: any): string {
    if (!agreement.numericField) return '-'
    const floatValue = parseFloat(agreement.readjustment);
    return `${formatCurrency(floatValue)}%`;
  }

  created() {
    this.removeDuczSyndicate();
  }

  removeDuczSyndicate() {
    if (this.currency.name === 'BRL') return;
    this.agreements
      .splice(this.agreements
        .findIndex(agreement => agreement.description
          === this.FixedAgreementEnum.DUCZ_SYNDICATE_FREE), 1)
  }
}
