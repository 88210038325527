



























import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import Assignment from '@/domain/drd/entity/assignment.entity';
import DrdService from '@/domain/drd/service/drd.service';
import Rules from '../rules/rules';
import LabelSlot from '../slots/label-slot.component.vue';
import debounce from '../utils/debounce';

@Component({
  components: {
    LabelSlot,
  },
})
export default class SelectAssignmentDebouceComponent extends Vue {
  @Prop({
    type: String,
    default: 'Papel',
  }) label!: string;

  @Prop({
    default: () => null,
  }) initialValue!: Assignment;

  @Prop() rules!: Rules;

  @Prop({
    type: Boolean,
    default: false,
  }) returnObject!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) clearable!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) readonly!: boolean;

  @Prop({
    default: () => null,
  }) containsDRD!: boolean | null;

  assignmentSelected: Assignment | null = null;

  searchInput: string = '';

  assignments: Assignment[] = [];

  lastFilterAssignment: string = '';

  loading: boolean = false;

  @Watch('initialValue', { immediate: true })
  onInitialValueChange() {
    if (this.initialValue) {
      this.assignmentSelected = new Assignment(this.initialValue);
      this.assignments.push(this.initialValue);
    }
  }

  findAssignments() {
    if (!this.searchInput && this.assignmentSelected) {
      this.assignmentSelected = null;
      return;
    }
    if (this.assignmentSelected) return;
    if ((this.lastFilterAssignment !== this.searchInput) && this.searchInput) {
      this.lastFilterAssignment = this.searchInput;
      if (this.searchInput.length > 3) {
        const employeeFinded = this.filterExistingAssignments();
        if (employeeFinded) return;
        debounce.debounce(() => this.getAssignment(this.searchInput))
      }
    }
  }

  filterExistingAssignments(): Assignment | undefined {
    return this.assignments
      .find(assignment => assignment.description.toLowerCase()
        .includes(this.searchInput.toLowerCase()));
  }

  getAssignment(name: string) {
    this.loading = true;
    DrdService.getAssigments(name, this.containsDRD)
      .then((response) => {
        this.assignments.push(...response);
      })
      .finally(() => this.loading = false);
  }

  update(employeeSelected: Assignment | null) {
    this.$emit('update', employeeSelected);
  }
}
