






























































import {
  Vue,
  Component,
  Watch,
  Prop,
} from 'vue-property-decorator';

@Component
export default class PerformanceDescriptionTabsComponent extends Vue {
  @Prop({
    type: Number,
    default: 0,
  })
  private value!: number;

  emitTabValue(value: number): void {
    this.$emit('input', value);
  }

  onEditMode(): boolean {
    const url = window.location.href;
    const match = url.split('/').pop();
    return match !== 'view';
  }

  editMode = this.onEditMode()
}

