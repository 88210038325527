








































































































import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import PaginationComponent from '@/shared/pagination.component.vue';
import EmployeeSchedule from '@/domain/schedule/employee/employee-schedule.entity';
import ModalEmployeeHistory from './employee-history/modal-employee-history.component.vue';
import ModalManageActivity from './manage-activity/modal-manage-activity.component.vue';
import ModalRestartSchedule from './restart-schedule/modal-restart-schedule.component.vue';
import ModalReassignActivity from './reassign-activity/modal-reassign-activity.component.vue';
import ScheduleDataTableNameComponent from './schedule-data-table-name.component.vue';
import ScheduleDataTableActivityComponent from './schedule-data-table-activity.component.vue';
import ScheduleDataTable from './schedule-data-table.entity';

@Component({
  components: {
    ScheduleDataTableNameComponent,
    ScheduleDataTableActivityComponent,
    ModalEmployeeHistory,
    ModalManageActivity,
    ModalRestartSchedule,
    ModalReassignActivity,
    PaginationComponent,
  },
})
export default class ScheduleDataTableComponent extends Vue {
  @Prop() dataTable!: ScheduleDataTable;

  @Prop() pagination!:any;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isResetEmployeeSchedule!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isReadActivity!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isUpdateActivity!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isUploadFiles!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isDeleteActivity!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isUpdateActivityStatus!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isReadActivityHistory!: boolean;

  @Prop({ type: Boolean, default: false })
  isReadEmployeeTalentLevel!: boolean;

  @Prop({ type: Boolean, default: false })
  hasRescheculeActivity!: boolean;

  private employeeId: string = '';

  private username: string = '';

  private date: string = '';

  private modal: any = {
    key: 0,
    history: {
      value: false,
    },
    reschedule: {
      value: false,
    },
    restart: {
      value: false,
    },
    detail: {
      value: false,
    },
  };

  @Prop({
    type: Boolean,
    default: false,
  }) loading!: boolean;


  private loadModal(reference: any, employee: EmployeeSchedule, date?: string): void {
    if (!this.isReadActivity) return;
    if (date) this.date = date;
    this.employeeId = employee.id;
    this.username = employee.username
    this.modal.key += 1;
    reference.value = true;
  }

  private closeModal(reference: any): void {
    reference.value = false;
    this.$emit('updateSchedule');
  }

  private getCurrentMonthClass(date: string): string {
    const nowYear = new Date().getFullYear();
    const nowMonth: number = new Date().getMonth() + 1;
    const month: string = nowMonth.toString().length === 1 ? `0${nowMonth}` : `${nowMonth}`;
    const currentDate = `${nowYear}-${month}`;
    if (date === currentDate) {
      return 'data-table-body-border current';
    }
    return 'data-table-body-border';
  }

  updateTable(pagination: any) {
    this.$emit('pagination', pagination);
  }
}
