export default class TechnicalKnowledge {
  id: string;

  description: string;

  objective: string;

  active: boolean;

  junior: string;

  plain: string;

  senior: string;

  isApproved: boolean;

  observation: string;

  isNew: boolean = false;

  loadingIsApproved: boolean = false;

  loadingRejected: boolean = false;

  loadingObservation: boolean = false;

  idTemporary: string | null = null;

  expanded: number | null = null;

  constructor(obj: any = {}) {
    this.id = obj.id || null;
    this.description = obj.description || '';
    this.objective = obj.objective || '';
    this.active = obj.active === undefined ? true : obj.active;
    this.junior = obj.junior || 'NOVICE';
    this.plain = obj.plain || 'NOVICE';
    this.senior = obj.senior || 'NOVICE';
    this.isApproved = obj.isApproved;
    this.observation = obj.observation || '';
    this.isNew = obj.isNew || false;
    this.loadingIsApproved = obj.loadingIsApproved || false;
    this.loadingRejected = obj.loadingRejeceted || false;
    this.loadingObservation = obj.loadingObservation || false;
    this.idTemporary = obj.idTemporary || null;
    this.expanded = obj.expanded;
  }

  public static ofNew() {
    return new TechnicalKnowledge(
      {
        isNew: true,
        idTemporary: new Date().getTime().toString(),
        isApproved: null,
        active: true,
        expanded: 0,
      },
    );
  }
}
