







































import { Vue, Component, Prop } from 'vue-property-decorator';
import EmployeeScoreComponent from './employee-score.component.vue';
import EmployeeRevenueComponent from './employee-revenue.component.vue';
import EmployeeCommissionComponent from './employee-commission.component.vue';
import EmployeePayslipComponent from './employee-payslip.component.vue';

@Component({
  components: {
    EmployeeScoreComponent,
    EmployeeRevenueComponent,
    EmployeeCommissionComponent,
    EmployeePayslipComponent,
  },
})
export default class EmployeeWageComponent extends Vue {
  @Prop({
    type: String,
    default: '',
  }) employeeId!: string;

  @Prop({
    type: Boolean,
    default: false,
  }) checkAccessCommission!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) checkAccessPayslip!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) isLoggedUser!: boolean;

  tab: number = 0;

  tabsComponents = {
    0: EmployeeScoreComponent,
    1: EmployeeRevenueComponent,
    2: EmployeeCommissionComponent,
    3: EmployeePayslipComponent,
  }
}
