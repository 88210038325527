







































import { Vue, Component } from 'vue-property-decorator';
import { getUserLoggedInPhoto } from '@/domain/security/auth.service';

@Component
export default class MenuHeaderUser extends Vue {
  photo: any = '';

  hasImage = false;

  photoLoading = false;

  created() {
    this.getPhoto();
  }

  async getPhoto() {
    this.photoLoading = true;
    try {
      const response = await getUserLoggedInPhoto();
      const url = URL.createObjectURL(response);
      this.photo = url;
      this.hasImage = true;
    } finally {
      this.photoLoading = false;
    }
  }

  get name() {
    return this.$store.state.name;
  }

  get username() {
    const { email } = this.$store.state;
    return email.split('@')[0];
  }

  logout() {
    this.$store.dispatch('logout');
    this.$router.push('/login');
  }

  private goToMyEmployeePage(): void {
    if (!this.username) return;
    this.$router.push({
      path: `/profile/${this.username}`,
      replace: true,
    });
  }
}
