




































import { Vue, Component } from 'vue-property-decorator';

@Component
export default class UnderMaintenance extends Vue {
  supporteLink = 'https://forms.office.com/pages/responsepage.aspx?id=GgBH6ig080COoYa9saO8hBgWvvABtWdNm20cOPgehkVUMUVTRVVPVk5WRU9DWjJRUDUxU0U4SVhNWS4u';

  toHome() {
    const redirectAfterLogin = localStorage.getItem('redirectAfterLogin');
    if (redirectAfterLogin) {
      localStorage.removeItem('redirectAfterLogin');
      this.$router.push(redirectAfterLogin);
      return;
    }
    this.$router.push('/');
  }
}
