







































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import ModalComponent from '@/shared/modal/modal.component.vue';
import ModalOptions from '@/shared/modal/modal-options.entity';
import DrdService from '@/domain/drd/service/drd.service';

@Component({
  components: {
    ModalComponent,
  },
})

export default class IndicatorRequestModal extends Vue {
  @Prop() showModal!: boolean;

  key: string = new Date().toISOString();

  request: string = '';

  loading: boolean = false;

  get optionsHandled(): ModalOptions {
    return {
      title: 'Solicitar Novo Indicador',
      titleSize: '20px',
      description: '',
      showOk: true,
      disabledOk: false,
      labelOk: 'Enviar Solicitação',
      showCancel: true,
      labelCancel: 'Cancelar',
      width: 700,
      loadingOk: this.loading,
    };
  }

  sendIndicatorRequest() {
    this.loading = true;
    DrdService.sendIndicatorRequest(this.request)
      .then(() => {
        VbSnackbarService.showSuccess('Solicitação Enviada com Sucesso');
        this.request = '';
        this.close();
      })
      .catch(err => VbSnackbarService.handleHttpError(err))
      .finally(() => {
        this.loading = false;
      });
  }

  close() {
    this.$emit('close');
  }
}
