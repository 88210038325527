


















































































































































import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import Indicators from '@/domain/drd/entity/indicators.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import IndicatorsUtils from '@/views/drd/commons/indicators-utils';
import ActiveCheckboxField from '@/views/drd/form/tabs/common-components/active-checkbox-field.component.vue';
import Rules from '@/shared/rules/rules';
import IndicatorCategory from './indicator-category.json';
import WeightCategories from './weight-categories.json';
import GoalAchievementTypes from './goal-achievement-types.json';
import GoalMaskTypes from './goal-mask-types';
import UtilityTypes from './utility-types.json';
import IndicatorLink from './indicator-link.component.vue';
import GoalMeasurementTypes from './goal-measurement-types.json';

@Component({
  components: { ActiveCheckboxField, IndicatorLink, LabelSlot },
})
export default class DefaultIndicatorsComponent extends Vue {
  @Prop({ type: Indicators, required: true })
  indicator!: Indicators;

  @Prop({ type: Boolean, default: true })
  showActions!: boolean;

  indicatorUtils: IndicatorsUtils = new IndicatorsUtils();

  rules: Rules = new Rules();

  getCategoryLabel(category: string): string {
    if (!category) return '-';
    const found = IndicatorCategory.find(it => it.value === category);
    return found ? found.name : '-';
  }

  getWeightLabel(weight: string): string {
    if (!weight) return '-';
    const found = WeightCategories.find(it => it.value === weight);
    return found ? found.text : '-';
  }

  getGoalAchievementTypeLabel(achievementType: string): string {
    if (!achievementType) return '-';
    const found = GoalAchievementTypes.find(it => it.value === achievementType);
    return found ? found.text : '-';
  }

  getGoalMaskType(mask: string): string {
    if (!mask) return '-';
    const found = GoalMaskTypes.find(it => it.value === mask);
    return found ? found.text : '-';
  }

  getUtilityType(utility: string): string {
    if (!utility) return '-';
    const found = UtilityTypes.find(it => it.value === utility);
    return found ? found.text : '-';
  }

  getGoalMeasurementUnit(unit: string): string {
    if (!unit) return '-';
    const found = GoalMeasurementTypes.find(it => it.value === unit);
    return found ? found.text : '-';
  }

  checkNullOrUndefined(value: any): string {
    if (value === null || value === undefined) return '-'
    return value;
  }

  deleteIndicator(): void {
    this.$emit('delete');
  }
}
