














import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ScrollToTop extends Vue {
  @Prop({ type: Number, default: 200 }) private threshold!: number;

  private visible: boolean = false;

  private scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  private handleScroll(): void {
    this.visible = window.scrollY > this.threshold;
  }

  private mounted(): void {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll(); // Garante que o estado inicial esteja correto
  }

  private beforeDestroy(): void {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
