




































































































































































import { VbSnackbarService } from 'bag-of-holding-library';
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import DefaultIndicatorEntity from '@/domain/drd/entity/default-indicator.entity';
import Indicators from '@/domain/drd/entity/indicators.entity';
import DrdService from '@/domain/drd/service/drd.service';
import AddButton from '@/shared/buttons/add-button.component.vue';
import ScrollToTop from '@/shared/scroll-to-top.component.vue';
import Loader from '@/views/drd/commons/loader.component.vue';
import FieldsArea from '@/views/drd/form/tabs/common-components/fields-area.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import PerformanceDescription from '@/domain/drd/entity/performance-description.entity';
import AuthorityStore from '@/domain/security/authority.store';
import AuthorityEnum from '@/domain/security/authority.enum';
import CustomIndicators from './custom-indicators.component.vue';
import DefaultIndicatorPickerDialog from './default-indicator-picker-dialog.component.vue';
import DefaultIndicators from './default-indicators.component.vue';
import IndicatorCategory from './indicator-category.json';
import ItemComponent from '../common-components/item.component.vue';
import StatusTabComponent from '../common-components/status-tab.component.vue';

@Component({
  components: {
    AddButton,
    CustomIndicators,
    DefaultIndicatorPickerDialog,
    DefaultIndicators,
    FieldsArea,
    Loader,
    ScrollToTop,
    LabelSlot,
    ItemComponent,
    StatusTabComponent,
  },
})
export default class IndicatorsComponent extends Vue {
  @Prop({
    type: Object,
  }) performanceDescription!: PerformanceDescription;

  @Prop() saveCommand!: boolean

  @Prop({
    type: Boolean,
    default: false,
  }) hasDrdApproveProccess!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) showApproveAndRejectBtns!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) showDeleteBtn!: boolean;

  loading = false;

  indicatorsData = Indicators.ofList([]);

  showDefaultIndicatorPicker: boolean = false;

  indicatorCategory = IndicatorCategory;

  tab: number = 0;

  private created() {
    this.getIndicatorData();
  }

  validateAuthorities() {
    AuthorityStore.hasAuthority([AuthorityEnum.DRD_APPROVE_PROCESS])
      .then((hasAuthority) => {
        this.hasDrdApproveProccess = hasAuthority;
      });
  }

  openDefaultIndicatorPicker(): void {
    this.showDefaultIndicatorPicker = true;
  }

  get defaultSelectedIndicators(): Indicators[] {
    return this.indicatorsData.filter(it => it.indicatorType === 'DEFAULT');
  }

  defaultIndicatorSelected(defaultIndicator: DefaultIndicatorEntity, selected: boolean): void {
    if (selected) {
      this.indicatorsData.push(Indicators.ofDefault(defaultIndicator));
      this.tab = 1;
      return;
    }
    const deletingIndex = this.indicatorsData.findIndex(it => it.id === defaultIndicator.id);
    this.indicatorsData.splice(deletingIndex, 1);
  }

  getIndicatorData() {
    this.loading = true;
    DrdService.getIndicators(this.performanceDescription.id)
      .then((resp: any) => {
        this.indicatorsData = Indicators.ofList(resp.map(it => ({ ...it, saved: true })));
      }).catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loading = false;
      });
  }

  addNewIndicator() {
    this.indicatorsData.push(Indicators.ofNew());
    this.tab = 1;
  }

  deleteIndicator(indicator: Indicators): void {
    if (!indicator.isNew) {
      const index = this.indicatorsData.findIndex(it => it.id === indicator.id);
      this.indicatorsData[index].active = false;
      indicator.loadingDelete = true;
      return;
    }
    const index = this.indicatorsData.findIndex(it => it.idTemporary === indicator.idTemporary);
    this.indicatorsData.splice(index, 1);
  }

  save(): Promise<void> {
    const { form }: any = this.$refs;
    return new Promise((resolve, reject) => {
      if (!form.validate()) {
        reject();
        return;
      }
      this.loading = true;
      DrdService.updateIndicators(this.performanceDescription.id, this.indicatorsData)
        .then(({ data }) => {
          VbSnackbarService.showSuccess('Indicadores salvos com sucesso!');
          this.indicatorsData = data.map(it => Indicators.of({ ...it, saved: true }));
          resolve();
        }).catch((err) => {
          VbSnackbarService.handleHttpError(err);
          reject();
        }).finally(() => {
          this.loading = false;
        });
    });
  }

  getCategoryName(category: string): string {
    if (!category) return '';
    return this.indicatorCategory.find(it => it.value === category)!.name || '';
  }

  approved(indicador: Indicators): void {
    indicador.loadingApprove = true;
    DrdService.approveOrRejectIndicator(indicador.performanceIndicatorId, true)
      .then(() => {
        indicador.isApproved = true;
      }).catch(VbSnackbarService.handleHttpError)
      .finally(() => indicador.loadingApprove = false);
  }

  rejected(indicador: Indicators): void {
    indicador.loadingReject = true;
    DrdService.approveOrRejectIndicator(indicador.performanceIndicatorId, false)
      .then(() => {
        indicador.isApproved = false;
      }).catch(VbSnackbarService.handleHttpError)
      .finally(() => indicador.loadingReject = false);
  }

  fetchObservation(indicator: Indicators): void {
    if (!indicator.observation) return;
    indicator.loadingObservation = true;
    DrdService.updateIndicatorObservation(indicator.performanceIndicatorId, indicator.observation)
      .catch(VbSnackbarService.handleHttpError)
      .finally(() => indicator.loadingObservation = false);
  }

  get indicatorsApprovedOrPending(): Indicators[] {
    return this.indicatorsData
      .filter(it => (it.isApproved === true) && it.active);
  }

  get indicatorsRejected(): Indicators[] {
    return this.indicatorsData
      .filter(it => it.isApproved === false && it.active);
  }

  get indicatorsPendding(): Indicators[] {
    return this.indicatorsData
      .filter(it => it.isApproved === null && it.active);
  }
}
