
















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class AddButton extends Vue {
  @Prop({
    type: String,
    default: '',
  })
  label!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  onClick(): void {
    this.$emit('click');
  }
}
