

























































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class FormationCategoryComponent extends Vue {
  @Prop({
    type: String,
    default: '',
  })
  label!: String;

  @Prop({
    type: String,
    default: 'RECOMMENDED',
  })
  value!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly!: boolean;

  updateValue(value: string): void {
    this.$emit('input', value);
  }

  levelLabels = {
    EXPECTED: {
      text: 'Esperado',
      description: 'Item Obrigatório',
    },
    RECOMMENDED: {
      text: 'Recomendado',
      description: 'Item não obrigatório, mas que agrega valor ao profissional',
    },
  }
}
