






































































































































import BehavioralSkill from '@/domain/drd/entity/behavioral-skill.entity';
import DrdService from '@/domain/drd/service/drd.service';
import AddButton from '@/shared/buttons/add-button.component.vue';
import ScrollToTop from '@/shared/scroll-to-top.component.vue';
import Loader from '@/views/drd/commons/loader.component.vue';
import { VbSnackbarService } from 'bag-of-holding-library';
import { Component, Prop, Vue } from 'vue-property-decorator';
import PerformanceDescription from '@/domain/drd/entity/performance-description.entity';
import BehavioralSkillComponent from './behavioral-skill.component.vue';
import ItemComponent from '../common-components/item.component.vue';
import StatusTabComponent from '../common-components/status-tab.component.vue';

@Component({
  components: {
    AddButton,
    Loader,
    BehavioralSkillComponent,
    ScrollToTop,
    ItemComponent,
    StatusTabComponent,
  },
})
export default class BehavioralSkillIndex extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  performanceDescription!: PerformanceDescription;

  @Prop({
    type: Boolean,
    default: false,
  }) hasDrdApproveProccess!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) showApproveAndRejectBtns!: boolean;

  loading: boolean = true;

  behavioralSkills: BehavioralSkill[] = [];

  tab: number = 0;

  updateField(index: number, field: string, value: any): void {
    this.behavioralSkills[index][field] = value;
  }

  addNewBehavioralSkill(): void {
    this.behavioralSkills.push(BehavioralSkill.ofNew())
    this.tab = 1;
  }

  deleteBehavioralSkill(behavioralSkill: BehavioralSkill): void {
    if (behavioralSkill.isNew) {
      const index = this.behavioralSkills
        .findIndex(it => it.idTemporary === behavioralSkill.idTemporary);
      this.behavioralSkills.splice(index, 1);
      return;
    }
    const index = this.behavioralSkills.findIndex(it => it.id === behavioralSkill.id);
    this.behavioralSkills[index].active = false;
  }

  approved(behavioralSkill: BehavioralSkill): void {
    behavioralSkill.loadingIsApproved = true;
    this.updateIsApproved(behavioralSkill, true)
      .finally(() => {
        behavioralSkill.loadingIsApproved = false;
      });
  }

  rejected(behavioralSkill: BehavioralSkill): void {
    behavioralSkill.loadingReject = true;
    this.updateIsApproved(behavioralSkill, false)
      .finally(() => {
        behavioralSkill.loadingReject = false;
      });
  }

  fetchObservation(behavioralSkill: BehavioralSkill): void {
    behavioralSkill.loadingObservation = true;
    DrdService.updateBehavioralSkillObservation(behavioralSkill.id, behavioralSkill.observation)
      .catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        behavioralSkill.loadingObservation = false;
      });
  }

  async updateIsApproved(behavioralSkill: BehavioralSkill, value: boolean): Promise<void> {
    return DrdService.approveOrRejectBehavioralSkill(behavioralSkill.id, value)
      .then(() => {
        behavioralSkill.isApproved = value;
      }).catch(VbSnackbarService.handleHttpError);
  }

  save(): Promise<void> {
    const { form }: any = this.$refs;
    return new Promise((resolve, reject) => {
      if (!form.validate()) {
        reject();
        return;
      }
      DrdService.updateBehavioralSkills(this.performanceDescription.id, this.behavioralSkills)
        .then(({ data }) => {
          VbSnackbarService.showSuccess('Competências comportamentais salvas com sucesso!');
          this.behavioralSkills = data.map(it => new BehavioralSkill(it));
          resolve();
        }).catch((err) => {
          VbSnackbarService.handleHttpError(err);
          reject();
        });
    });
  }

  created(): void {
    this.loadBehavioralSkills();
  }

  loadBehavioralSkills(): void {
    this.loading = true;
    DrdService.getBehavioralSkills(this.performanceDescription.id).then((data) => {
      this.behavioralSkills = data.map(it => new BehavioralSkill(it));
    }).catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loading = false;
      });
  }

  get approveOrPendingBehavioralSkills(): BehavioralSkill[] {
    return this.behavioralSkills
      .filter(it => it.isApproved && it.active);
  }

  get rejectedBehavioralSkills(): BehavioralSkill[] {
    return this.behavioralSkills
      .filter(it => it.isApproved === false && it.active);
  }

  get penddingBehavioralSkills(): BehavioralSkill[] {
    return this.behavioralSkills
      .filter(it => it.isApproved === null && it.active);
  }
}
