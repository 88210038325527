import { PerformanceDescriptionStatus } from '@/domain/drd/entity/performance-description-status.enum';

// eslint-disable-next-line import/prefer-default-export
export const StatusLabel = {
  [PerformanceDescriptionStatus.DRAFT]: {
    text: 'Rascunho',
    color: 'primary',
  },
  [PerformanceDescriptionStatus.APPROVED]: {
    text: 'Aprovado',
    color: 'success',
  },
  [PerformanceDescriptionStatus.REJECTED]: {
    text: 'Recusado',
    color: 'error',
  },
  [PerformanceDescriptionStatus.PENDING_APPROVAL]: {
    text: 'Pendente de Aprovação',
    color: 'warning',
  },
}

export const StatusListSelect = [
  {
    text: 'Todos',
    value: null,
  },
  {
    text: 'Rascunho',
    value: PerformanceDescriptionStatus.DRAFT,
  },
  {
    text: 'Aprovado',
    value: PerformanceDescriptionStatus.APPROVED,
  },
  {
    text: 'Pendente de Aprovação',
    value: PerformanceDescriptionStatus.PENDING_APPROVAL,
  },
]
