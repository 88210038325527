import DefaultIndicator from '@/domain/drd/entity/default-indicator.entity';
import IndicatorCategory from '@/views/drd/form/tabs/indicators/indicator-category.json';
import WeightCategories from '@/views/drd/form/tabs/indicators/weight-categories.json';
import GoalAchievementTypes from '@/views/drd/form/tabs/indicators/goal-achievement-types.json';
import GoalMaskTypes from '@/views/drd/form/tabs/indicators/goal-mask-types';
import UtilityTypes from '@/views/drd/form/tabs/indicators/utility-types.json';
import GoalMeasurementTypes from '@/views/drd/form/tabs/indicators/goal-measurement-types.json';

export default class Indicators {
  id: string;

  indicator: string;

  category: string;

  description: string;

  active: boolean;

  weight: string;

  goalValue: number;

  goalAchievementType: string;

  goalMeasurementUnit: string;

  goalMaskType: string;

  utility: string;

  goalMaxValue: number;

  linkGmo: string;

  linkBpg: string;

  indicatorType: string;

  saved: boolean;

  loadingDelete: boolean;

  isApproved: boolean | null;

  observation: string;

  performanceIndicatorId: string;

  loadingApprove: boolean = false;

  loadingReject: boolean = false;

  loadingObservation: boolean = false;

  idTemporary: string | null = null;

  expanded: number | null = null;

  isNew: boolean = false;

  constructor(obj: any = {}) {
    this.id = obj.id || null;
    this.indicator = obj.indicator || '';
    this.category = obj.category || '';
    this.description = obj.description || '';
    this.active = obj.active === null || obj.active === undefined ? true : obj.active;
    this.weight = obj.weight || null;
    this.goalValue = obj.goalValue || null;
    this.goalAchievementType = obj.goalAchievementType || null;
    this.goalMeasurementUnit = obj.goalMeasurementUnit || null;
    this.goalMaskType = obj.goalMaskType || null;
    this.utility = obj.utility || null;
    this.goalMaxValue = obj.goalMaxValue || null;
    this.linkGmo = obj.linkGmo || null;
    this.linkBpg = obj.linkBpg || null;
    this.indicatorType = obj.indicatorType || 'CUSTOM';
    this.saved = obj.saved || false;
    this.loadingDelete = obj.loadingDelete;
    this.isApproved = obj.isApproved;
    this.observation = obj.observation || '';
    this.performanceIndicatorId = obj.performanceIndicatorId || null;
    this.idTemporary = obj.idTemporary || null;
    this.expanded = obj.expanded;
    this.isNew = obj.isNew || false;
  }

  public static of(indicators: any): Indicators {
    return new Indicators(indicators || {});
  }

  public static ofNew(): Indicators {
    return new Indicators({
      idTemporary: new Date().getTime().toString(),
      active: true,
      isApproved: null,
      expanded: 0,
      isNew: true,
    });
  }

  public static ofList(obj: any[]): Indicators[] {
    return obj.map(this.of);
  }

  public static ofDefault(defaultIndicator: DefaultIndicator): Indicators {
    return new Indicators({
      ...defaultIndicator,
      indicatorType: 'DEFAULT',
      isApproved: null,
    });
  }

  equals(obj: Indicators): boolean {
    return this.id === obj.id
    && this.indicator === obj.indicator
    && this.category === obj.category
    && this.description === obj.description
    && this.active === obj.active
    && this.goalValue === obj.goalValue
    && this.goalAchievementType === obj.goalAchievementType
    && this.goalMeasurementUnit === obj.goalMeasurementUnit
    && this.goalMaskType === obj.goalMaskType;
  }

  get categoryLabel(): string {
    if (!this.category) return '-';
    const found = IndicatorCategory.find(it => it.value === this.category);
    return found ? found.name : '-';
  }

  get weightLabel(): string {
    if (!this.weight) return '-';
    const found = WeightCategories.find(it => it.value === this.weight);
    return found ? found.text : '-';
  }

  get goalAchievementTypeLabel(): string {
    if (!this.goalAchievementType) return '-';
    const found = GoalAchievementTypes.find(it => it.value === this.goalAchievementType);
    return found ? found.text : '-';
  }

  get goalMaskTypeLabel(): string {
    if (!this.goalMaskType) return '-';
    const found = GoalMaskTypes.find(it => it.value === this.goalMaskType);
    return found ? found.text : '-';
  }

  get utilityLabel(): string {
    if (!this.utility) return '-';
    const found = UtilityTypes.find(it => it.value === this.utility);
    return found ? found.text : '-';
  }

  get goalMeasurementUnitLabel(): string {
    if (!this.goalMeasurementUnit) return '-';
    const found = GoalMeasurementTypes.find(it => it.value === this.goalMeasurementUnit);
    return found ? found.text : '-';
  }
}
