




























































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VueEditor } from 'vue2-editor';
import { VbSnackbarService } from 'bag-of-holding-library';
import KeyArea from '@/domain/drd-2.0/entity/key-area.entity';
import Rules from '@/shared/rules/rules';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Level from '@/domain/drd-2.0/entity/level.entity';
import Skill from '@/domain/drd-2.0/entity/skill.entity';
import KeyAreaService from '@/domain/drd-2.0/service/key-area.service';
import KeyAreaSkillLevel from '@/domain/drd-2.0/entity/key-area-skill-level.entity';
import KeyAreaSkill from '@/domain/drd-2.0/entity/key-area-skill.entity';
import AutocompleteEditableItems from '@/shared/autocompletes/autocomplete-editable-items.vue';
import Loading from '@/shared/loading/loading.component.vue';
import Indicators from '@/domain/drd/entity/indicators.entity';
import ToolbarConfig from './toolbar-config.json';
import SkillTypes from '../../skill/skill-types.json';
import SelectSkillsDialog from './select-skills-dialog.component.vue';
import KeyAreaSkillForm from './skill-form.component.vue';
import IndicatorsComponent from '../../components/indicators.component.vue';
import ResponsibilityTreeComponent from '../../responsibility/responsibility-tree.component.vue';
import ResponsibilityFormComponent, { ResponsibilityText } from '../../responsibility/responsibility-form.component.vue';

@Component({
  components: {
    LabelSlot,
    SelectSkillsDialog,
    VueEditor,
    AutocompleteEditableItems,
    Loading,
    KeyAreaSkillForm,
    IndicatorsComponent,
    ResponsibilityTreeComponent,
    ResponsibilityFormComponent,
  },
})
export default class FormComponent extends Vue {
  @Prop({
    required: true,
  }) keyArea!: KeyArea;

  @Prop({
    type: Boolean,
    default: false,
  }) isUpdate!: boolean;

  tab = null;

  levelsSelected: Level[] = [];

  rules: Rules = new Rules();

  toolbar = ToolbarConfig;

  skillTypes = SkillTypes;

  openSelectSkillDialog: boolean = false;

  levels: Level[] = [];

  editing: Level = { id: '', name: '', disabled: false };

  editingIndex = -1;

  loadingUpdateLevel: boolean = false;

  loadingDeleteLevel: boolean = false;

  levelSearchName: string = '';

  loadingCreateLevel: boolean = false;

  responsibilityOptions: any = [];

  showConfig: boolean = false;

  languageOptions = [
    {
      text: 'Português',
      value: 'PTBR',
    },
    {
      text: 'Inglês',
      value: 'EN',
    },
    {
      text: 'Espanhol',
      value: 'ES',
    },
  ]

  languageSelected = 'PTBR';

  getResponsibilityOptions() {
    KeyAreaService.getResponsiblesOptions()
      .then((data) => {
        this.responsibilityOptions = data;
      })
  }

  editLevel(index: number, item: Level) {
    if (this.editing !== item) {
      this.editing = item;
      this.editingIndex = index;
      return;
    }
    this.resetEditLevel();
  }

  resetEditLevel() {
    this.editing = { id: '', name: '', disabled: false };
    this.editingIndex = -1;
  }

  created() {
    this.getResponsibilityOptions()
    this.getAllLevels();
  }

  getOptionsBySkillType(type: string) {
    return this.skillTypes.filter(it => it.value === type)[0].options
      .filter(it => it.isSelected);
  }

  addLevel(level: Level, skill: KeyAreaSkill) {
    const skillFiltered = this.keyArea.skills
      .filter(it => it.skill.id === skill.skill.id)[0];
    const levelAlreadyAdded = skillFiltered.levels
      .filter(it => it.name === level.name);
    if (levelAlreadyAdded.length > 0) return;
    this.$emit('addLevel', { level, skillFiltered });
  }

  disableOrActiveLevel(skill: KeyAreaSkill, level: KeyAreaSkillLevel) {
    this.$emit('disableOrActiveLevel', { skill, level });
  }

  removeLevel(skill: KeyAreaSkill, level: KeyAreaSkillLevel) {
    this.$emit('removeLevel', { skill, level });
  }

  get selectedSkills() {
    return this.keyArea.skills.map(it => it.skill);
  }

  skillSelected(skill: Skill, selected: boolean) {
    if (selected) {
      this.$emit('addSkill', { skill, levels: this.levelsSelected });
      return;
    }
    this.emitRemoveSkill(skill.id);
  }

  emitRemoveSkill(skillId: string) {
    this.$emit('removeSkill', skillId);
  }

  filterSkillsByType(type: string) {
    if (this.keyArea.skills.length === 0) return [];
    return this.keyArea.skills.filter(it => it.skill.type === type);
  }

  getAllLevels() {
    KeyAreaService.findAllLevels()
      .then(levels => this.levels = levels);
  }

  updateLevel(level: Level) {
    this.loadingUpdateLevel = true;
    KeyAreaService.updateLevel(level)
      .then(() => {
        VbSnackbarService.showSuccess('Nível atualizado!');
      }).catch((err) => {
        VbSnackbarService.showError(err.response.data.messages.toString());
      }).finally(() => {
        this.loadingUpdateLevel = false;
        this.resetEditLevel();
      });
  }

  deleteLevel(level: Level) {
    this.loadingDeleteLevel = true;
    KeyAreaService.deleteLevel(level.id)
      .then(() => {
        VbSnackbarService.showSuccess('Nível excluído!');
      }).catch((err) => {
        VbSnackbarService.showError(err.response.data.messages.toString());
      }).finally(() => {
        this.resetEditLevel();
        this.getAllLevels();
        this.loadingDeleteLevel = false;
      });
  }

  createLevel(levelName: string) {
    this.loadingCreateLevel = true;
    KeyAreaService.createLevel(levelName)
      .then(({ data }) => {
        this.levels.push({ ...data });
        this.levelSearchName = '';
      }).catch((err) => {
        VbSnackbarService.showError(err.response.data.messages.toString());
      }).finally(() => {
        this.loadingCreateLevel = false;
      })
  }

  selectedLevel(level: Level[]) {
    this.levelsSelected = level;
  }

  emitEvent(event: string, payload: any) {
    this.$emit(event, payload);
  }

  addNewIndicator(indicator: Indicators) {
    this.keyArea.indicators.push(indicator);
  }

  deleteIndicator(index: number) {
    this.$emit('deleteIndicator', index);
  }

  addNewResponsibility(data: ResponsibilityText) {
    this.keyArea.addNewResponsibility(data, this.levelsSelected);
  }

  get responsibilitiesActive() {
    return this.keyArea.responsibilities.filter(it => it.active);
  }

  deleteResponsibility(index: number) {
    this.keyArea.responsibilities.splice(index, 1);
  }
}
