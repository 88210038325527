




























import KeyAreaResponsibility from '@/domain/drd-2.0/entity/key-area-responsibility.entity';
import { Vue, Component, Prop } from 'vue-property-decorator';
import draggable from 'vuedraggable'
import ResponsibilityNodeComponent from './responsibility-node.component.vue';

@Component({
  name: 'ResponsibilityTreeComponent',
  components: {
    ResponsibilityNodeComponent,
    draggable,
  },
})
export default class ResponsibilityTreeComponent extends Vue {
  @Prop() responsibilities!: KeyAreaResponsibility[];

  @Prop() responsibilityOptions!: any;

  @Prop({
    type: String,
    default: 'PTBR',
  }) languageSelected!: string;

  @Prop({
    type: Array,
    default: [],
  }) languageOptions!: any[];

  @Prop({
    type: Number,
    default: 0,
  }) hiearchyLevel!: number;

  get dragOptions() {
    return {
      animation: 200,
      disabled: false,
      ghostClass: 'ghost',
      scroll: true,
      scrollEl: this.$refs.drawer,
      scrollSensitivity: 200,
      scrollSpeed: 10,
    };
  }

  getBackgroundColor(hiearchyLevel: number) {
    const colors = [
      'rgb(255 255 255)',
      'rgb(248 250 252)',
      'rgb(239 246 255)',
      'rgb(238 242 255)',
      'rgb(250 245 255)',
      'rgb(253 242 248)',
    ]
    return colors[hiearchyLevel % colors.length]
  }
}
