












































































import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import { DrdItemStatusConfig } from '@/domain/drd/entity/drd-item-approve-status.type';
import ModalOptions from '@/shared/modal/modal-options.entity';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';

@Component({
  components: {
    LabelSlot,
    ModalWarning,
  },
})
export default class ItemComponent extends Vue {
  @Prop({ type: Boolean, default: true })
  showObservation!: boolean;

  @Prop({ type: String, default: '' })
  observation!: string;

  @Prop({ required: true })
  isApproved!: boolean | null;

  @Prop({ type: Boolean, default: false })
  loadingObservation!: boolean;

  @Prop({ type: Boolean, default: false })
  loadingApprove!: boolean;

  @Prop({ type: Boolean, default: false })
  loadingReject!: boolean;

  @Prop({ type: Boolean, default: false })
  loadingDelete!: boolean;

  @Prop({ type: Number, default: null })
  value!: number | null;

  @Prop({ type: Boolean, default: false })
  showApproveBtn!: boolean;

  @Prop({ type: Boolean, default: false })
  showRejectBtn!: boolean;

  @Prop({ type: Boolean, default: false })
  readOnlyObservation!: boolean;

  isApprovedConfig: Record<string, DrdItemStatusConfig> = {
    true: {
      color: 'success',
      text: 'Aprovado',
    },
    false: {
      color: 'error',
      text: 'Recusado',
    },
    null: {
      color: '',
      text: 'Pendente',
    },
  };

  deleteModalWarningOptions: ModalOptions = {
    title: 'Atenção',
    description: 'Tem certeza que deseja excluir este item?',
    labelCancel: 'Cancelar',
    labelOk: 'Excluir',
    okColor: 'error',
    showCancel: true,
    showOk: true,
    width: 400,
  }

  showDeleteModalWarning: boolean = false;

  get isApprovedText(): string {
    return this.isApproved === null ? 'Pendente' : this.isApprovedConfig[String(this.isApproved)].text;
  }

  get isApprovedColor(): string {
    return this.isApproved === null ? '' : this.isApprovedConfig[String(this.isApproved)].color;
  }

  handleDelete() {
    this.showDeleteModalWarning = true;
  }

  deleted() {
    this.showDeleteModalWarning = false;
    this.$emit('delete');
  }

  blur(event: any) {
    if (this.readOnlyObservation) return;
    this.$emit('blur', event)
  }
}
