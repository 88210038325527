import moment from 'moment';
import Vue from 'vue';
import Vuex from 'vuex';
import { jwtDecode } from 'jwt-decode';

Vue.use(Vuex);

export const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';

interface UserLoggedIn {
  name: string | null;
  email: string | null;
  token: string | null;
  refreshToken: string | null;
  expirationAt: string | null | number;
}

interface UpdateTokenPayload {
  token: string;
  refreshToken: string;
  expirationAt: string;
}

function loadUserFromLocalStorage(): UserLoggedIn {
  const user = localStorage.getItem('user');
  if (user) {
    return JSON.parse(user);
  }
  return {
    name: null,
    email: null,
    token: null,
    refreshToken: null,
    expirationAt: null,
  };
}

function formatExpirationAt(expirationAt: number | null | string) {
  return moment().add(expirationAt, 'seconds')
    .subtract(60, 'seconds')
    .format(DATETIME_FORMAT);
}

export default new Vuex.Store({
  state: loadUserFromLocalStorage(),
  mutations: {
    setUser(state, user: UserLoggedIn) {
      const tokenPayload = jwtDecode<{ name: string, 'unique_name': string }>(user.token!);

      state.name = tokenPayload.name;
      state.email = tokenPayload.unique_name;
      state.token = user.token;
      state.refreshToken = user.refreshToken;
      const expirationAtFormatted = formatExpirationAt(user.expirationAt);
      state.expirationAt = expirationAtFormatted;
      user.expirationAt = expirationAtFormatted;
      user.name = tokenPayload.name;
      user.email = tokenPayload.unique_name;

      localStorage.setItem('user', JSON.stringify(user));
    },
    clearUser(state) {
      state.name = null;
      state.email = null;
      state.token = null;
      state.refreshToken = null;
      state.expirationAt = null;

      localStorage.removeItem('user');
    },
    updateTokens(state, { token, refreshToken, expirationAt }: Partial<UserLoggedIn>) {
      state.token = token!;
      state.refreshToken = refreshToken!;
      const expirationAtFormatted = formatExpirationAt(expirationAt!)
      state.expirationAt = expirationAtFormatted;

      const userLocalStorage = localStorage.getItem('user');
      const userData = JSON.parse(userLocalStorage || '{}');
      localStorage.setItem(
        'user',
        JSON.stringify({
          ...userData,
          token,
          refreshToken,
          expirationAt: expirationAtFormatted,
        }),
      );
    },
  },
  actions: {
    login({ commit }, user: UserLoggedIn) {
      commit('setUser', user);
    },
    updateToken({ commit }, payload: UpdateTokenPayload) {
      commit('updateTokens', payload);
    },
    logout({ commit }) {
      commit('clearUser');
    },
  },
  getters: {
    isAuthenticated: (state) => {
      const expirationAt = moment(state.expirationAt, DATETIME_FORMAT);
      const tokenExpiration = moment().isAfter(expirationAt);
      return !!state.token && !tokenExpiration;
    },
    getUser: state => state,
  },
})
