

























































import {
  Vue, Component, Prop, Emit,
} from 'vue-property-decorator';
import ModalHeader from './modal-header.component.vue';
import ModalDescription from './modal-description.component.vue';
import ModalOptions from './modal-options.entity';

@Component({
  components: {
    'modal-header': ModalHeader,
    'modal-description': ModalDescription,
  },
})
export default class ModalComponent extends Vue {
  @Prop({
    required: true,
  })
  value!: boolean;

  @Prop({
    required: true,
  })
  options!: ModalOptions;

  @Prop() keyValue!: string;

  @Prop() titleColor!: string;

  @Prop() scrollable!: boolean;

  @Emit('ok')
  ok(): void {}

  @Emit('cancel')
  cancel(): void {}

  get optionsHandled(): ModalOptions {
    return {
      title: this.options.title || '',
      titleSize: this.options.titleSize || '20px',
      description: this.options.description || '',
      showOk: this.options.showOk || false,
      disabledOk: this.options.disabledOk || false,
      labelOk: this.options.labelOk || 'Ok',
      okColor: this.options.okColor || 'primary',
      showCancel: this.options.showCancel || false,
      labelCancel: this.options.labelCancel || 'Cancelar',
      width: this.options.width || 500,
      loadingOk: this.options.loadingOk || false,
    };
  }
}
