
































































import { Vue, Component } from 'vue-property-decorator';
import PerformanceDescriptionEntity from '@/domain/drd/entity/performance-description.entity';
import DrdService from '@/domain/drd/service/drd.service';
import EmployeeService from '@/domain/employees/service/employee.service';
import Loading from '@/shared/loading/loading.component.vue';
import PerformanceDescriptionTableReadHeader from './performance-description-table-read-header.component.vue';
import TabsComponent from '../commons/performance-description-tabs.component.vue';
import IndicatorsComponent from './indicators/index.vue';
import AreaKeyComponent from './area-key/index.vue';
import BehavioralSkillComponent from './behavioral-skill/index.vue';
import TechnicalKnowledgeComponent from './technical-knowledge/index.vue';
import FormationComponent from './formation/index.vue';
import ReviewWarningComponent from '../commons/review-warning.component.vue';
import PerformanceDescriptionHeader from '../form/performance-description-header.component.vue';

@Component({
  components: {
    AreaKeyComponent,
    BehavioralSkillComponent,
    FormationComponent,
    IndicatorsComponent,
    PerformanceDescriptionTableReadHeader,
    TabsComponent,
    TechnicalKnowledgeComponent,
    ReviewWarningComponent,
    Loading,
    PerformanceDescriptionHeader,
  },
})
export default class PerformanceDescriptionReadComponent extends Vue {
  performanceDescriptionId: string = '';

  selectedTab: number = 0;

  performanceDescription: PerformanceDescriptionEntity = PerformanceDescriptionEntity.of({});

  existsReviewOpen: boolean = false;

  loading: boolean = false;

  goToPreviousTab(): void {
    if (this.selectedTab === 0) {
      this.goToListPage();
      return;
    }
    this.selectedTab -= 1;
  }

  goToNextTab(): void {
    if (this.isLastTab()) {
      this.goToListPage();
      return;
    }
    this.selectedTab += 1;
  }

  get forwardButtonLabel(): string {
    if (this.isLastTab()) return 'Fechar';
    return 'Avançar';
  }

  isLastTab(): boolean {
    return this.selectedTab === 4;
  }

  goToListPage(): void {
    this.$router.push({ path: '/performance-description-list' });
  }

  async loadPerformanceDescription() {
    this.loading = true;
    await DrdService.getPerformanceDescription(this.performanceDescriptionId)
      .then((resp: PerformanceDescriptionEntity) => {
        this.performanceDescription = resp;
      }).finally(() => {
        this.loading = false;
      });
    this.checkExistsReviewOpen();
  }

  async checkExistsReviewOpen() {
    this.existsReviewOpen = await EmployeeService
      .checkExistsDrdReviewOpen(this.performanceDescription!.assignmentId);
  }

  created(): void {
    this.$destroy();
    this.performanceDescriptionId = this.$route.params.id;
    this.loadPerformanceDescription();
  }
}
