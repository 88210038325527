import CategoryQuantities from './category-quantities.entity';

export default class Analysis {
  public totalQuantityDTO: CategoryQuantities;

  public technicalKnowledgeQuantityDTO: CategoryQuantities;

  public areaKeysQuantityDTO: CategoryQuantities;

  public behavioralSkillsQuantityDTO: CategoryQuantities;

  public formationsQuantityDTO: CategoryQuantities;

  constructor(obj: any = {}) {
    this.totalQuantityDTO = new CategoryQuantities(obj.totalQuantityDTO);
    this.technicalKnowledgeQuantityDTO = new CategoryQuantities(obj.technicalKnowledgeQuantityDTO);
    this.areaKeysQuantityDTO = new CategoryQuantities(obj.areaKeysQuantityDTO);
    this.behavioralSkillsQuantityDTO = new CategoryQuantities(obj.behavioralSkillsQuantityDTO);
    this.formationsQuantityDTO = new CategoryQuantities(obj.formationsQuantityDTO);
  }
}
