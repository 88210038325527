export default class DrdFilter {
    role?: string;

    assignment?: string;

    ecosystem?: string;

    activeAssignment: boolean;

    status: string | null = null;

    constructor(obj: any) {
      this.role = obj.role;
      this.assignment = obj.assignment;
      this.ecosystem = obj.ecosystem;
      this.activeAssignment = obj.activeAssignment || true;
      this.status = obj.status || null
    }

    public static of(value: any): DrdFilter {
      return new DrdFilter(value || {});
    }
}
