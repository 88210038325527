






































import Analysis from '@/domain/drd/entity/analysis/analysis.entity';
import { VbTooltipSlot } from 'bag-of-holding-library';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    VbTooltipSlot,
  },
})
export default class AreaKeyTable extends Vue {
  @Prop({
    type: Analysis,
    required: true,
  })
  private analysisData!: Analysis;

  private quantityLabelsOptions = {
    expectedQuantity: 'Esperado',
    recomendedQuantity: 'Recomendada',
    noviceQuantity: 'Novato',
    practiceQuantity: 'Praticante',
    professionalQuantity: 'Profissional',
    especialistQuantity: 'Expert',
  };

  private levels = ['juniorQuantity', 'plainQuantity', 'seniorQuantity'];

  private mapLevelsAndData(levels: string[], data: Record<string, any>) {
    return levels.map((level) => {
      const orderedKeys = Object.keys(this.quantityLabelsOptions);

      return orderedKeys
        .filter(key => data[level][key] !== undefined)
        .map(key => ({
          numbers: data[level][key],
          labels: this.quantityLabelsOptions[key],
        }));
    });
  }

  private transformResponseData(response: Analysis) {
    const {
      totalQuantityDTO,
      technicalKnowledgeQuantityDTO,
      areaKeysQuantityDTO,
      behavioralSkillsQuantityDTO,
      formationsQuantityDTO,
    } = response;

    const categories = [
      { type: 'Área Chave', data: areaKeysQuantityDTO },
      { type: 'Comp. Comportamentais', data: behavioralSkillsQuantityDTO },
      { type: 'Comp. Técnicas', data: technicalKnowledgeQuantityDTO },
      { type: 'Formações/Leitura', data: formationsQuantityDTO },
      { type: 'Total Geral', data: totalQuantityDTO },
    ];

    return categories.map(({ type, data }) => ({
      type,
      values: this.mapLevelsAndData(this.levels, data),
    }));
  }

  get transformedRows() {
    return this.transformResponseData(this.analysisData);
  }

  rowColorClass(rowIndex: number) {
    const colors = [
      'row-color-blue',
      'row-color-green',
      'row-color-yellow',
      'row-color-purple',
      'row-color-grey',
    ];
    return colors[rowIndex % colors.length];
  }
}
