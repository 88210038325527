import Dinamics from './survey.view.vue';

export default [
  {
    path: '/survey',
    component: Dinamics,
    name: 'Formulario',
    meta: {
      hideMenus: true,
      requiresAuth: false,
    },
  },
];
