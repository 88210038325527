










import {
  Vue, Component, Prop,
} from 'vue-property-decorator';

@Component
export default class ModalHeaderComponent extends Vue {
  @Prop() title!: string;

  @Prop() titleSize?: string;

  @Prop() titleColor?: string;

  @Prop({
    type: Boolean,
    default: false,
  }) closePermited!: boolean;

  close() {
    if (!this.closePermited) return;
    this.$emit('close');
  }
}
