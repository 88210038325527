import { render, staticRenderFns } from "./responsibility-node.component.vue?vue&type=template&id=5f066ad2&scoped=true&"
import script from "./responsibility-node.component.vue?vue&type=script&lang=ts&"
export * from "./responsibility-node.component.vue?vue&type=script&lang=ts&"
import style0 from "./responsibility-node.component.vue?vue&type=style&index=0&id=5f066ad2&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f066ad2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VScrollYTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VBtn,VChip,VIcon,VRadio,VRadioGroup,VScrollYTransition})
