import AreaKeyItem from './area-key-item.entity';

export default class AreaKey {
  id: string;

  description: string;

  objective: string;

  active: boolean;

  items: AreaKeyItem[];

  isApproved: boolean | null;

  observation: string;

  loadingDelete: boolean = false;

  loadingApprove: boolean = false;

  loadingReject: boolean = false;

  loadingObservation: boolean = false;

  expanded: number | null = null;

  isNew: boolean = false;

  idTemporary: string | null = null;

  constructor(obj: any = {}) {
    this.id = obj.id || null;
    this.description = obj.description || '';
    this.objective = obj.objective || '';
    this.active = obj.active === undefined ? true : obj.active;
    this.items = obj.items === undefined ? [] : obj.items.map(it => new AreaKeyItem(it));
    this.isApproved = obj.isApproved;
    this.observation = obj.observation || '';
    this.expanded = obj.expanded;
    this.isNew = obj.isNew || false;
    this.idTemporary = obj.idTemporary || null;
  }

  public static ofNew(): AreaKey {
    return new AreaKey(
      {
        isNew: true,
        expanded: 0,
        isApproved: null,
        active: true,
        idTemporary: new Date().getTime().toString(),
      },
    );
  }

  public pushNewItem(): void {
    this.items.push(AreaKeyItem.ofNew());
  }

  public deleteItem(index: number): void {
    this.items.splice(index, 1);
  }
}
