


















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ResponsibilityLanguageLabelComponent extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) responsibility!: any;

  @Prop({
    type: String,
    required: true,
  }) languageSelected!: string;

  @Prop({
    type: Array,
    default: [],
  }) languageOptions!: any[];
}
