import Vue from 'vue';
import LottieAnimation from 'lottie-web-vue'
import Donut from 'vue-css-donut-chart';
import VueMask from 'v-mask';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import 'vue-css-donut-chart/dist/vcdonut.css';
import httpRequester from './plugins/httpRequester';

Vue.use(Donut);
Vue.config.productionTip = false;
Vue.use(httpRequester);
Vue.use(VueMask)
Vue.use(LottieAnimation)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
