


































import { Vue, Component } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import ResignationFilter from '@/domain/resignation/entity/resignation-filter.entity';
import ResignationService from '@/domain/resignation/service/resignation.service';
import ResignationSummary from '@/domain/resignation/entity/resignation-summary.entity';
import Authority from '@/domain/security/authority.entity';
import AuthorityStore from '@/domain/security/authority.store';
import AuthorityEnum from '@/domain/security/authority.enum';
import ResignatioFiltersComponent from './resignation-filters.component.vue';
import ResignationListComponent from './resignation-list.component.vue';
import ResignationEditModal from './resignation-edit-modal.component.vue'

@Component({
  components: { ResignatioFiltersComponent, ResignationListComponent, ResignationEditModal },
})
export default class ResignationsListIndex extends Vue {
  filters: ResignationFilter = new ResignationFilter();

  resignations: ResignationSummary[] = [];

  loadingResignations: boolean = true;

  editingResignationId: string | null = null;

  pagination: any = {
    itemsPerPage: 10,
    page: 1,
    sortBy: ['shutdownTime'],
    sortDesc: [true],
    total: 0,
    pageCount: 0,
  };

  authority!: Authority;

  deleteResignations: boolean = false;

  updateResignation: boolean = false;

  hasReadRescissionEvidences: boolean = false;

  hasCreateRescissionEvidences: boolean = false;

  hasReadResignationDetails: boolean = false;

  updateFilter(field: string, value: any): void {
    this.filters[field] = value;
    this.loadResignations();
  }

  loadResignations() {
    this.loadingResignations = true;
    ResignationService.getResignations(this.filters, this.pagination).then(({ data }) => {
      this.resignations = data.content.map(it => new ResignationSummary(it));
      this.pagination.total = data.totalElements;
      this.pagination.pageCount = data.totalPages;
    }).catch(VbSnackbarService.handleHttpError)
      .finally(() => this.loadingResignations = false);
  }

  updatePagination(pagination: any): void {
    if (this.pagination.itemsPerPage < pagination.itemsPerPage) {
      pagination.page = 1;
    }
    this.pagination = pagination;
    this.loadResignations();
  }

  goToEditResignationPage(resignationId: string): void {
    this.editingResignationId = resignationId;
  }

  exitEditMode():void {
    this.editingResignationId = null;
  }

  async created() {
    this.$destroy();
    this.loadResignations();
    await this.validationAuthorities();
  }

  async validationAuthorities() {
    await this.getAuthority();
    this.deleteResignations = this.authority.hasDeleteResignations();
    this.updateResignation = this.authority.hasUpdateResignations();
    this.hasCreateRescissionEvidences = this.authority
      .hasAuthorities([AuthorityEnum.CREATE_RESIGNATION_RESCISSION_EVIDENCES]);
    this.hasReadRescissionEvidences = this.authority
      .hasAuthorities([AuthorityEnum.READ_RESIGNATION_RESCISSION_EVIDENCES]);
    this.hasReadResignationDetails = this.authority
      .hasAuthorities([AuthorityEnum.READ_RESIGNATION_DETAILS]);
  }

  async getAuthority() {
    await AuthorityStore.fetchedAuthority().then(authority => this.authority = authority);
  }
}
