import _ from 'lodash';
import Assignment from '@/domain/drd/entity/assignment.entity';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import UpdateAtribute from './update-atribute.entity';
import TechLead from './tech-lead.entity';
import CareerUpdatePersist from './career-update-persist.entity';

export default class CareerUpdate {
  id: string;

  date: string;

  role: UpdateAtribute;

  seniority: UpdateAtribute;

  workplace: UpdateAtribute;

  operationModality: UpdateAtribute;

  businessUnit: UpdateAtribute;

  resultCenter: UpdateAtribute | null;

  manager: EmployeeSummary;

  techLead: TechLead;

  squad: string;

  observation: string;

  hasInconsistency: boolean;

  selected?: any;

  assignment: Assignment;

  secondaryAssignment: Assignment;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.date = obj.date || '';
    this.seniority = obj.seniority || '';
    this.workplace = obj.workplace || '';
    this.operationModality = obj.operationModality || '';
    this.businessUnit = obj.businessUnit || '';
    this.role = obj.role || '';
    this.manager = obj.manager || '';
    this.resultCenter = obj.resultCenter || '';
    this.squad = obj.squad || '';
    this.observation = obj.observation || '';
    this.hasInconsistency = obj.hasInconsistency || '';
    this.assignment = obj.assignment || '';
    this.techLead = obj.techLead || '';
    this.secondaryAssignment = obj.secondaryAssignment || '';
  }

  public static of(obj: any): CareerUpdate {
    if (obj) {
      return new CareerUpdate(_.cloneDeep(obj));
    }
    return new CareerUpdate(obj || {});
  }

  public static ofList(obj: any[]): CareerUpdate[] {
    return obj.map(this.of);
  }

  public select() {
    this.selected = true;
  }

  public unselect() {
    this.selected = false;
  }

  public setUpdateValues(): CareerUpdatePersist {
    return new CareerUpdatePersist({
      role: this.role.id,
      seniority: this.seniority.id,
      workplace: this.workplace.id,
      operationModality: this.operationModality.id,
      businessUnit: this.businessUnit.id,
      resultCenter: this.resultCenter!.id,
      manager: this.manager.id,
      techLead: (this.techLead !== undefined && this.techLead !== null) ? this.techLead.id : null,
      squad: this.squad,
      observation: this.observation,
      assignment: this.assignment.id,
      secondaryAssignmentId: this.secondaryAssignment ? this.secondaryAssignment.id : null,
    });
  }

  public setOperationModality() {
    if (this.workplace.defaultOperationModality) {
      this.operationModality = this.workplace.defaultOperationModality;
    }
  }

  public hasNoOperationModality() : Boolean {
    return Object.values(this.operationModality).every((el: any) => el === '');
  }

  public setDefaultBusinessUnit() {
    if (this.manager.businessUnit) {
      this.businessUnit = this.manager.businessUnit;
    }
  }

  public equals(obj: CareerUpdate): boolean {
    return this.role.id === obj.role.id
      && this.seniority.id === obj.seniority.id
      && this.workplace.id === obj.workplace.id
      && this.operationModality.name === obj.operationModality.name
      && this.businessUnit.name === obj.businessUnit.name
      && this.resultCenter!.name === obj.resultCenter!.name
      && this.manager.id === obj.manager.id
      && this.squad === obj.squad
      && this.observation === obj.observation
      && this.assignment === obj.assignment
  }
}
