































































import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
// eslint-disable-next-line import/no-cycle
import KeyAreaResponsibility from '@/domain/drd-2.0/entity/key-area-responsibility.entity';

export type ResponsibilityText = {
  portuguese: string;
  english: string;
  espanish: string;
};

@Component({
  components: {
    LabelSlot,
  },
})
export default class ResponsibilityFormComponent extends Vue {
  @Prop({
    type: Object,
    default: () => ({}),
  }) initialValue!: KeyAreaResponsibility;

  @Prop({
    type: String,
    default: '',
  }) submitButtonText!: string;

  @Prop({
    type: Boolean,
    default: false,
  }) showCancel!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) disableSubmit!: boolean;

  @Prop({
    type: String,
    default: '',
  }) submitTooltipMessage!: string;

  tab = null;

  text: ResponsibilityText = {
    portuguese: this.initialValue.descriptionPTBR,
    english: this.initialValue.descriptionEN,
    espanish: this.initialValue.descriptionES,
  };

  submit() {
    if (!this.text.portuguese) {
      return;
    }
    this.$emit('submit', this.text);
    this.clear();
  }

  clear() {
    this.text = {
      portuguese: '',
      english: '',
      espanish: '',
    };
  }

  cancel() {
    this.$emit('cancel');
  }
}
