import DateFormat from '@/shared/utils/date-format';
import { PerformanceDescriptionStatus } from './performance-description-status.enum';

export default class PerformanceDescription {
  id: string;

  roleName: string;

  assignment: string;

  assignmentId: string;

  ecosystem: string;

  objective: string;

  creationStep: number;

  cbo: string;

  createdDate: string;

  lastModifiedDate: string;

  status: PerformanceDescriptionStatus;

  constructor(obj: any = {}) {
    this.id = obj.id;
    this.roleName = obj.roleName || '';
    this.assignment = obj.assignment || '';
    this.assignmentId = obj.assignmentId || '';
    this.ecosystem = obj.ecosystem || '';
    this.objective = obj.objective || '';
    this.creationStep = obj.creationStep || 0;
    this.cbo = obj.cbo || '-'
    this.createdDate = DateFormat.dateTimeFormated(obj.createdDate) || '';
    this.lastModifiedDate = DateFormat.dateTimeFormated(obj.lastModifiedDate) || '';
    this.status = obj.status;
  }

  public static of(performanceDescription: any): PerformanceDescription {
    return new PerformanceDescription(performanceDescription);
  }

  public equals(obj: PerformanceDescription): boolean {
    return this.roleName === obj.roleName
    && this.assignment === obj.assignment
    && this.ecosystem === obj.ecosystem
    && this.objective === obj.objective
    && this.creationStep === obj.creationStep
  }

  get title(): string {
    return `DRD - ${this.assignment}`;
  }

  get isDraft(): boolean {
    return this.status === PerformanceDescriptionStatus.DRAFT;
  }

  get isPendingApproval(): boolean {
    return this.status === PerformanceDescriptionStatus.PENDING_APPROVAL;
  }

  get isApproved(): boolean {
    return this.status === PerformanceDescriptionStatus.APPROVED;
  }
}
