export default class KeyAreaResponsibilityLevel {
  id: string;

  levelId: string;

  name: string;

  expected: number;

  constructor(obj: any) {
    this.id = obj.id || '';
    this.levelId = obj.levelId || '';
    this.name = obj.name || '';
    this.expected = obj.expected;
  }

  get isConfigured(): boolean {
    return this.expected !== null;
  }
}
