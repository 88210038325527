


































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import TechnicalKnowledge from '@/domain/drd/entity/technical-knowledge.entity';
import DrdService from '@/domain/drd/service/drd.service';
import AddButton from '@/shared/buttons/add-button.component.vue';
import ScrollToTop from '@/shared/scroll-to-top.component.vue';
import Loader from '@/views/drd/commons/loader.component.vue';
import PerformanceDescription from '@/domain/drd/entity/performance-description.entity';
import TechnicalKnowledgeComponent from './technical-knowledge.component.vue';
import ItemComponent from '../common-components/item.component.vue';
import StatusTabComponent from '../common-components/status-tab.component.vue';

@Component({
  components: {
    AddButton,
    Loader,
    TechnicalKnowledgeComponent,
    ScrollToTop,
    ItemComponent,
    StatusTabComponent,
  },
})
export default class TechnicalKnowledgeIndex extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  performanceDescription!: PerformanceDescription;

  @Prop({
    type: Boolean,
    default: false,
  }) hasDrdApproveProccess!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) showApproveAndRejectBtns!: boolean;

  loading: boolean = true;

  technicalKnowledges: TechnicalKnowledge[] = [];

  tab: number = 0;

  updateField(index: number, field: string, value: any): void {
    this.technicalKnowledges[index][field] = value;
  }

  addNewTechnicalKnowledge(): void {
    this.technicalKnowledges.push(TechnicalKnowledge.ofNew());
    this.tab = 1;
  }

  deleteTechnicalKnowledge(technicalKnowledge: TechnicalKnowledge): void {
    if (technicalKnowledge.isNew) {
      const index = this.technicalKnowledges
        .findIndex(it => it.idTemporary === technicalKnowledge.idTemporary);
      this.technicalKnowledges.splice(index, 1);
      return;
    }
    const index = this.technicalKnowledges.findIndex(it => it.id === technicalKnowledge.id);
    this.technicalKnowledges[index].active = false;
  }

  save(): Promise<void> {
    const { form }: any = this.$refs;
    return new Promise((resolve, reject) => {
      if (!form.validate()) {
        reject();
        return;
      }
      DrdService.updateTechnicalKnowledges(this.performanceDescription.id, this.technicalKnowledges)
        .then(({ data }) => {
          VbSnackbarService.showSuccess('Conhecimentos técnicos salvos com sucesso!');
          this.technicalKnowledges = data.map(it => new TechnicalKnowledge(it));
          resolve();
        }).catch((err) => {
          VbSnackbarService.handleHttpError(err);
          reject();
        });
    });
  }

  created(): void {
    this.loadTechnocalKnowledges();
  }

  loadTechnocalKnowledges(): void {
    this.loading = true;
    DrdService.getTechnicalKnowledges(this.performanceDescription.id).then((data) => {
      this.technicalKnowledges = data.map((it: any) => new TechnicalKnowledge(it));
    }).catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loading = false;
      });
  }

  get technicalKnowledgeApprovedOrPending(): TechnicalKnowledge[] {
    return this.technicalKnowledges
      .filter(it => it.isApproved && it.active);
  }

  get technicalKnowledgeRejected(): TechnicalKnowledge[] {
    return this.technicalKnowledges
      .filter(it => it.isApproved === false && it.active);
  }

  get technicalKnowledgePending(): TechnicalKnowledge[] {
    return this.technicalKnowledges
      .filter(it => it.isApproved === null && it.active);
  }

  approved(technicalKnowledge: TechnicalKnowledge): void {
    technicalKnowledge.loadingIsApproved = true;
    this.updateIsApproved(technicalKnowledge, true)
      .finally(() => {
        technicalKnowledge.loadingIsApproved = false;
      });
  }

  rejected(technicalKnowledge: TechnicalKnowledge): void {
    technicalKnowledge.loadingRejected = true;
    this.updateIsApproved(technicalKnowledge, false)
      .finally(() => {
        technicalKnowledge.loadingRejected = false;
      });
  }

  fetchObservation(technicalKnowledge: TechnicalKnowledge): void {
    technicalKnowledge.loadingObservation = true;
    DrdService.updateTechnicalKnowledgeObservation(
      technicalKnowledge.id,
      technicalKnowledge.observation,
    ).catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        technicalKnowledge.loadingObservation = false;
      });
  }

  async updateIsApproved(
    technicalKnowledge: TechnicalKnowledge,
    isApproved: boolean,
  ): Promise<void> {
    return DrdService.approveOrRejectTechnicalKnowledge(technicalKnowledge.id, isApproved)
      .then(() => {
        technicalKnowledge.isApproved = isApproved;
      }).catch(VbSnackbarService.handleHttpError);
  }
}
