
































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import EmployeeRevenue from '@/domain/employees/entity/employee-revenue.entity';
import ScoreService from '@/domain/employees/service/score.service';
import ExpansionPanel from '@/shared/expansion-panel/expansion-panel.component.vue';
import ErrorMessageComponent from '@/shared/error-message.component.vue';
import Loading from '@/shared/loading/loading.component.vue';
import VueApexCharts from 'vue-apexcharts';
import Headers from './employee-revenue-header.json';
import EmployeeRevenueItems from './employee-revenue-items.json'

@Component({
  components: {
    ExpansionPanel,
    VueApexCharts,
    ErrorMessageComponent,
    Loading,
  },
})
export default class EmployeeRevenueComponent extends Vue {
  @Prop({
    type: String,
    default: '',
  }) badgeMessage!: string;

  employeeRevenues: EmployeeRevenue[] = [];

  series: any = [];

  chartOptions = {
    chart: {
      type: 'bar',
      width: 800,
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [{
      breakpoint: 480,
      options: {
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
      },
    }],
    dataLabels: {
      enabled: true,
      formatter: (val: number) => this.formatCurrency(val),
      offoffsetY: -30,
      style: {
        fontSize: '0.8rem',
        fontWeight: 'bold',
        colors: [opts => '#000'],
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
      },
    },
    xaxis: {
      type: 'category',
      categories: ['', ''],
    },
    yaxis: {
      labels: {
        formatter: (val: number) => this.formatCurrency(val),
      },
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'left',
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      enabled: true,
      custom({ seriesIndex, w }) {
        if (w.config.series[seriesIndex].name === 'Seu Rendimento Anual') {
          return '<div class="pa-2">'
              + '<span>Somam os Itens: </span>'
              + '<ul>'
                + '<li>Salário</li>'
                + '<li>Remuneração Variável (PPI, Comissão)</li>'
                + '<li>Ducz (Alimentação/Flex)</li>'
                + '<li>Ducz (Sintinorp/Livre)</li>'
                + '<li>Ajuda de Custo/Subsídios Educação</li>'
                + '<li>13° Salário</li>'
                + '<li>Auxílio Creche</li>'
                + '<li>1/3 Férias</li>'
              + '</ul>'
              + '<span class="mt-4">Subtraem os itens: </span>'
              + '<ul>'
                + '<li>INSS pago pelo colaborador</li>'
                + '<li>IRRF pago pelo colaborador</li>'
              + '</ul>'
          + '</div>'
        }
        if (w.config.series[seriesIndex].name === 'Seus benefícios anuais pagos pela empresa') {
          return '<div class="pa-2">'
              + '<span>Somam os Itens: </span>'
              + '<ul>'
                + '<li>Plano de Saude</li>'
                + '<li>Plano Dental</li>'
                + '<li>Gympass</li>'
                + '<li>Edupass</li>'
                + '<li>Treinamento</li>'
                + '<li>Seguro de Vida</li>'
              + '</ul>'
          + '</div>'
        }
        return '<div class="pa-2">'
              + '<span>Somam os Itens: </span>'
              + '<ul>'
                + '<li>Seu rendimento anual</li>'
                + '<li>FGTS</li>'
                + '<li>INSS</li>'
                + '<li>Benefícios (parte empresa)</li>'
              + '</ul>'
          + '</div>'
      },
    },
  }

  loading: boolean = false;

  errorMessage: string = '';

  showError: boolean = false;

  headers = Headers;

  items = EmployeeRevenueItems;

  created() {
    this.getEmployeeRevenue();
  }

  getEmployeeRevenue() {
    this.loading = true;
    ScoreService.getRevenueByUsername(this.$route.params.username)
      .then(({ data }) => {
        this.employeeRevenues = data.map((item: any) => new EmployeeRevenue(item));
      }).catch((error) => {
        this.showError = true;
        this.errorMessage = error.response.data.messages.toString();
      }).finally(() => this.loading = false);
  }

  formatCurrency(value: number) {
    if (!value) return 'R$ 0,00';
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }

  clickExpandRevenue() {
    this.$emit('clickExpandRevenue');
  }
}
