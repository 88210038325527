






import { Vue, Component, Prop } from 'vue-property-decorator'
import CopyClipboardService from './utils/copy-clipboard.service';

@Component
export default class CopyComponent extends Vue {
  @Prop({
    type: String,
  }) text!: string;

  copyToClipboard(text: string): void {
    CopyClipboardService.copyToClipboard(text);
  }
}
