























































































































































































import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import Indicators from '@/domain/drd/entity/indicators.entity';
import IndicatorsUtils from '@/views/drd/commons/indicators-utils';
import Rules from '@/shared/rules/rules';
import ActiveCheckboxField from '@/views/drd/form/tabs/common-components/active-checkbox-field.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import IndicatorCategory from './indicator-category.json';
import WeightCategories from './weight-categories.json';
import GoalAchievementTypes from './goal-achievement-types.json';
import GoalMaskTypes from './goal-mask-types';
import UtilityTypes from './utility-types.json';
import GoalMeasurementTypes from './goal-measurement-types.json';

@Component({
  components: {
    ActiveCheckboxField,
    LabelSlot,
  },
})
export default class CustomIndicatorsComponent extends Vue {
  @Prop({ type: Indicators, required: true })
  indicator!: Indicators;

  @Prop({ type: Boolean, default: true })
  showActions!: boolean;


  indicatorUtils: IndicatorsUtils = new IndicatorsUtils();

  rules: Rules = new Rules();

  indicatorCategory = IndicatorCategory;

  weightCategories = WeightCategories;

  goalAchievementTypes = GoalAchievementTypes;

  goalMaskTypes = GoalMaskTypes;

  utilityTypes = UtilityTypes;

  goalMeasurementTypes = GoalMeasurementTypes;

  deleteIndicator(): void {
    this.$emit('delete');
  }
}
