





































































































































import { VbSnackbarService } from 'bag-of-holding-library';
import { Vue, Component, Prop } from 'vue-property-decorator';
import Formation from '@/domain/drd/entity/formation.entity';
import DrdService from '@/domain/drd/service/drd.service';
import AddButton from '@/shared/buttons/add-button.component.vue';
import Loader from '@/views/drd/commons/loader.component.vue';
import FormationComponent from '@/views/drd/form/tabs/formation/formation.component.vue';
import ScrollToTop from '@/shared/scroll-to-top.component.vue';
import PerformanceDescription from '@/domain/drd/entity/performance-description.entity';
import ItemComponent from '../common-components/item.component.vue';
import StatusTabComponent from '../common-components/status-tab.component.vue';

@Component({
  components: {
    AddButton,
    FormationComponent,
    Loader,
    ScrollToTop,
    ItemComponent,
    StatusTabComponent,
  },
})
export default class FormationIndex extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  performanceDescription!: PerformanceDescription;

  @Prop({
    type: Boolean,
    default: false,
  }) hasDrdApproveProccess!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) showApproveAndRejectBtns!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) showDeleteBtn!: boolean;

  loading: boolean = true;

  formations: Formation[] = [];

  tab: number = 0;

  addNewFormation(): void {
    this.formations.push(Formation.ofNew());
    this.tab = 1;
  }

  deleteFormation(formation: Formation): void {
    if (formation.isNew) {
      const index = this.formations.findIndex(it => it.idTemporary === formation.idTemporary);
      this.formations.splice(index, 1);
      return;
    }
    const index = this.formations.findIndex(it => it.id === formation.id);
    this.formations[index].active = false;
  }

  updateField(index: number, field: string, value: any): void {
    this.formations[index][field] = value;
  }

  save(): Promise<void> {
    const { form }: any = this.$refs;
    return new Promise((resolve, reject) => {
      if (!form.validate()) {
        reject();
        return;
      }
      DrdService.updateFormations(this.performanceDescription.id, this.formations)
        .then(({ data }) => {
          VbSnackbarService.showSuccess('Formações e capacitações salvas com sucesso!');
          this.formations = data.map(it => new Formation(it));
          resolve();
        }).catch((err) => {
          VbSnackbarService.handleHttpError(err);
          reject();
        });
    });
  }

  created(): void {
    this.loadFormations();
  }

  loadFormations(): void {
    this.loading = true;
    DrdService.getFormations(this.performanceDescription.id).then((data) => {
      this.formations = data.map(it => new Formation(it));
    }).catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loading = false;
      });
  }

  get formationApprovedOrPending(): Formation[] {
    return this.formations
      .filter(it => it.isApproved && it.active);
  }

  get formationRejected(): Formation[] {
    return this.formations
      .filter(it => it.isApproved === false && it.active);
  }

  get formationPending(): Formation[] {
    return this.formations
      .filter(it => it.isApproved === null && it.active);
  }

  approved(formation: Formation): void {
    formation.loadingIsApproved = true;
    this.updateIsApproved(formation, true)
      .finally(() => {
        formation.loadingIsApproved = false;
      });
  }

  rejected(formation: Formation): void {
    formation.loadingRejected = true;
    this.updateIsApproved(formation, false)
      .finally(() => {
        formation.loadingRejected = false;
      });
  }

  fetchObservation(formation: Formation): void {
    formation.loadingObservation = true;
    DrdService.updateFormationObservation(
      formation.id,
      formation.observation,
    ).catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        formation.loadingObservation = false;
      });
  }

  async updateIsApproved(
    formation: Formation,
    isApproved: boolean,
  ): Promise<void> {
    return DrdService.approveOrRejectFormation(formation.id, isApproved)
      .then(() => {
        formation.isApproved = isApproved;
      }).catch(VbSnackbarService.handleHttpError);
  }
}
