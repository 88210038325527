


















import Analysis from '@/domain/drd/entity/analysis/analysis.entity';
import DrdService from '@/domain/drd/service/drd.service';
import Loader from '@/views/drd/commons/loader.component.vue';
import { VbSnackbarService } from 'bag-of-holding-library';
import { Component, Prop, Vue } from 'vue-property-decorator';
import AreaKeyTableComponent from './area-key-table.component.vue';

@Component({
  components: {
    Loader,
    AreaKeyTableComponent,
  },
})

export default class AnalysisIndex extends Vue {
    @Prop({
      type: String,
      required: true,
    })
    private performanceDescriptionId!: string;

    private loading: boolean = false;

    private analysisData: Analysis = new Analysis();

    private async loadItensQuantity(): Promise<void> {
      try {
        this.loading = true;
        const { data } = await DrdService.getItensQuantity(this.performanceDescriptionId);
        this.analysisData = new Analysis(data);
      } catch (err) {
        VbSnackbarService.handleHttpError(err);
      } finally {
        this.loading = false;
      }
    }

    created(): void {
      this.loadItensQuantity();
    }
}
