




































































import {
  Vue, Prop, Component, Emit, Watch,
} from 'vue-property-decorator';
import ObservationIcon from '@/shared/observation-icon.component.vue';
import ActivityUpdate from '@/domain/schedule/activitie/activity-update.entity';
import AuthorityStore from '@/domain/security/authority.store';
import Authority from '@/domain/security/authority.entity';
import { hasAdministratorAuthority, hasAdministratorOrManagerAuthority } from '@/shared/directive/has-authority.directive';
import ActivityDetailed from '@/domain/schedule/activitie/activity-detailed.entity';
import ManageActivityListItemContents from './manage-activity-list-item-contents.component.vue';

@Component({
  components: {
    'observation-icon': ObservationIcon,
    'list-item-contents': ManageActivityListItemContents,
  },
  directives: { hasAdministratorAuthority, hasAdministratorOrManagerAuthority },
})
export default class ManageActivityListItem extends Vue {
  @Prop({
    required: true,
  })
  private activity!: ActivityDetailed;

  @Prop() deleteLoading!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isUpdateActivity!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isUploadFiles!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isDeleteActivity!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isUpdateActivityStatus!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isReadActivity!: boolean;

  @Prop({ type: Boolean, default: false })
  isReadEmployeeTalentLevel!: boolean;

  @Prop({
    type: String,
    required: true,
  }) private employeeId!: string;

  @Prop({
    type: String,
    required: true,
  }) private username!: string;

  private newActivity: ActivityDetailed = new ActivityDetailed(this.activity);

  private redBorder: boolean = false;

  private showContents: boolean = false;

  isManager: boolean = false;

  isAdministrator: boolean = false;

  isEnabled() {
    if (!this.isReadActivity) {
      return false;
    }
    if (this.isAdministrator) {
      return true;
    }
    if (this.isManager) {
      return this.isEmployeeManager();
    }
    return true;
  }

  isEmployeeManager() {
    const managerEmails = this.newActivity.managerEmails.map(el => el.toLowerCase());
    return managerEmails.includes(this.$store.state.email)
  }

  created() {
    this.initAuthorities();
  }

  initAuthorities() {
    AuthorityStore.fetchedAuthority()
      .then((response: Authority) => {
        this.isManager = response.isManager();
        this.isAdministrator = response.isAdministrator();
      });
  }

  @Watch('activity')
  activityWatch() {
    this.newActivity = new ActivityDetailed(this.activity);
  }

  private get status(): string {
    return this.newActivity.statusValue;
  }

  refresh() {
    this.$emit('refresh')
  }

  private get icon(): object {
    return this.showContents ? this.minusBox : this.plusBox;
  }

  private get plusBox(): object {
    return {
      name: 'mdi-plus-box',
      color: 'plus-box-color',
    };
  }

  private get minusBox(): object {
    return {
      name: 'mdi-minus-box',
      color: 'minus-box-color',
    };
  }

  private handleRedBorder(value: boolean): void {
    this.redBorder = value;
  }

  @Emit('rejectActivity')
  rejectActivity(reason: string) {
    return new ActivityUpdate(this.newActivity.id, reason);
  }


  @Emit('approveActivity')
  approveActivity(reason: string) {
    return new ActivityUpdate(this.newActivity.id, reason);
  }

  @Emit('deleteActivity')
  deleteActivity(reason: string) {
    return new ActivityUpdate(this.newActivity.id, reason);
  }

  private expandItem(): void {
    this.showContents = !this.showContents;
  }
}
