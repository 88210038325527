















































import moment from 'moment';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class DatePickerMenu extends Vue {
  @Prop({
    type: String,
    default: '',
  }) initialValue!: string;

  menu: boolean = false;

  date: string = this.initialValue;

  get dateFormat() {
    return this.date ? moment(this.date).format('DD/MM/YYYY') : '';
  }

  submit() {
    this.menu = false;
    this.$emit('input', this.date);
  }
}
