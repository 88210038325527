












































































import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Loader from '@/views/drd/commons/loader.component.vue';
import Rules from '@/shared/rules/rules';
import RescissionEvidenceRequest from '@/domain/resignation/entity/rescission-evidence-request.entity';
import ResignationService from '@/domain/resignation/service/resignation.service';
import { VbSnackbarService } from 'bag-of-holding-library';

@Component({
  components: {
    LabelSlot,
    Loader,
  },
})
export default class CreateRescissionEvidenceModal extends Vue {
  @Prop() dialog!: boolean;

  @Prop() resignationId!: string;

  rules: Rules = new Rules();

  areas: string [] = [];

  rescissionEvidenceRequest: RescissionEvidenceRequest = new RescissionEvidenceRequest();

  loadingSave: boolean = false;

  created() {
    this.getAreas();
  }

  save(): void {
    const { rescissionEvidenceForm }: any = this.$refs;
    if (!rescissionEvidenceForm.validate()) {
      VbSnackbarService.showError('Existem campos obrigatórios não preenchidos');
      return;
    }
    this.loadingSave = true;
    ResignationService.createRescissionEvidence(this.resignationId, this.rescissionEvidenceRequest)
      .then(() => {
        VbSnackbarService.showSuccess('Evidência de rescisão anexada com sucesso');
      }).catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loadingSave = false;
        this.closeDialog(true);
      })
  }

  closeDialog(reload: boolean = false):void {
    this.$emit('close', reload);
  }

  getAreas() {
    ResignationService.getStaffAttachedRescissionEvidence()
      .then((response: string[]) => {
        this.areas = response;
      });
  }
}

