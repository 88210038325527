import axios from 'axios';
import ScoreList from '@/domain/employees/entity/score-list.entity'
import ScoreFilter from '@/domain/drd/entity/filter-score.entity'
import Score from '@/domain/employees/entity/score.entity';

export default class ScoreService {
  public static async findScore(
    pagination: any,
    sortBy: string,
    filter: ScoreFilter,
  ): Promise<ScoreList> {
    return axios.get('/api/employees/score', {
      params: {
        employees: filter.employees.join(','),
        responsibles: filter.managers.join(','),
        countDate: filter.countDate,
        validationDate: filter.validationDate,
        status: filter.status,
        competence: filter.competenceRequestFormat,
        page: pagination.page - 1,
        size: pagination.itemsPerPage,
        orderBy: `${sortBy}|${pagination.sortDesc[0] ? 'desc' : 'asc'}`,
      },
    })
      .then((response: any) => response.data);
  }

  public static getScoreByEmployeeId(id: string): Promise<Score> {
    return axios.get(`/api/employees/${id}/score`)
      .then((response: any) => Score.of(response.data));
  }

  public static createScore(score: Score) {
    return axios.post('/api/employees/score', score);
  }

  public static createUnscheduledScore(score: Score) {
    return axios.post('/api/employees/score/unscheduled', score);
  }

  public static updateScore(scoreId: string, score: Score): Promise<void> {
    return axios.put(`/api/employees/score/${scoreId}`, score)
      .then((resp: any) => resp);
  }

  public static getScoreById(scoreId: string): Promise<Score> {
    return axios.get(`/api/employees/score/${scoreId}`)
      .then(({ data }) => Score.ofRead(data));
  }

  public static getLoggedUserScores(employeeId: string): Promise<Score[]> {
    return axios.get(`/api/employees/${employeeId}/score/all`)
      .then(({ data }) => data.map(it => Score.ofRead(it)));
  }

  public static verifyConclusion(scoreId: string): Promise<any> {
    return axios.patch(`/api/employees/score/${scoreId}/status/validated`);
  }

  public static createInitialScore(score: Score) {
    return axios.post('/api/employees/initial-score', score);
  }

  public static async getRevenueByUsername(username: string) {
    return axios.get(`/api/employees/${username}/revenue/`);
  }

  public static async getChildrenPersonalData(id: string): Promise<any> {
    return axios.get(`/api/employees/${id}/personal/childrenByEmployee`)
  }
}
