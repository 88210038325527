import Vue from 'vue';
import VueRouter from 'vue-router';
import Employees from '@/views/employees/employees-router';
import Schedule from '@/views/schedule/schedule-router';
import Denied from '@/views/denied/denied.view.vue';
import Home from '@/views/home/home-router';
import HappinessLevel from '@/views/happiness-level/happiness-level.view.vue';
import Wages from '@/views/wages/wages-router';
import SeniorityRemuneration from '@/views/seniority-remuneration/seniority-remuneration-router';
import Attendance from '@/views/attendance/attendance-router';
import MessageSender from '@/views/message-sender/message-sender.view.vue';
import TalentLevel from '@/views/talent-level/talent-level-router';
import RiskAttendance from '@/views/risk-attendance/risk-attendance-router';
import Drd from '@/views/drd/drd-router';
import Score from '@/views/score/score-router';
import InitialScore from '@/views/initial-score/initial-score-router';
import ScoreSchedule from '@/views/schedule/score/score-schedule-router';
import Vacation from '@/views/vacation/vacation-router';
import Activity from '@/views/activity/activity-router';
import Birthday from '@/views/birthday/birthday-router';
import Survey from '@/views/survey/survey-router';
import AdmissionDateAnniversary from '@/views/admission-date-anniversary/admission-date-anniversary-router';
import UnderMaintenance from '@/views/under-maintenance/under-maintenance.view.vue';
import Security from '@/views/security/security-router';
import SalaryReadjustmentParametrizations from '@/views/salary-readjustment-parametrizations/salary-readjustment-parametrizations-router';
import SkillMap from '@/views/skill-map/skill-map-router';
import RouteChecker from '@/views/route-checker';
import SelectiveProcess from '@/views/selective-process/selective-process-router';
import IndicatorDefaultRouter from '@/views/indicators-default/indicator-default-router';
import AssessmentRouter from '@/views/assessment/assessment-router';
import SkillRouter from '@/views/drd-2.0/skill/skill.router';
import KeyAreaRouter from '@/views/drd-2.0/key-area/key-area.router';
import DRD20Router from '@/views/drd-2.0/drd-router';
import CommissionRouter from '@/views/commission/commission-router';
import InternalMovementRouter from '@/views/internal-movement/internal-movement-router';
import LoginComponent from '@/views/login/login.component.vue';
import Callback from '@/views/login/callback.component.vue';
// import { isAuthenticated } from '@/domain/security/auth.service';
import store from '../store';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...Employees,
    ...Schedule,
    ...Home,
    ...Wages,
    ...SeniorityRemuneration,
    ...Attendance,
    ...TalentLevel,
    ...RiskAttendance,
    ...Drd,
    ...Score,
    ...ScoreSchedule,
    ...Vacation,
    ...Activity,
    ...Birthday,
    ...AdmissionDateAnniversary,
    ...Security,
    ...SalaryReadjustmentParametrizations,
    ...SkillMap,
    ...InitialScore,
    ...Survey,
    ...SelectiveProcess,
    ...IndicatorDefaultRouter,
    ...AssessmentRouter,
    ...SkillRouter,
    ...KeyAreaRouter,
    ...DRD20Router,
    ...CommissionRouter,
    ...InternalMovementRouter,
    {
      path: '/maintenance',
      name: 'Página sob Manutenção',
      component: UnderMaintenance,
      meta: {
        requiresAuth: false,
        hideMenus: true,
      },
    },
    {
      path: '/denied',
      name: 'denied',
      component: Denied,
    },
    {
      path: '/happiness-level/:type/:level?',
      name: 'Felicitômetro',
      component: HappinessLevel,
    },
    {
      path: '/message-sender',
      name: 'Enviador de Mensagens',
      component: MessageSender,
      beforeEnter: RouteChecker.hasAccessMessageDaisy,
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginComponent,
      meta: {
        requiresAuth: false,
        hideMenus: true,
      },
    },
    {
      path: '/callback',
      name: 'Callback',
      component: Callback,
      meta: {
        requiresAuth: false,
        hideMenus: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const requiredAuth = to.meta.requiresAuth === undefined ? true : to.meta.requiresAuth;
  const { token } = store.state;
  const isPublic = to.path.includes('/public') || !requiredAuth
  if (isPublic) {
    next();
  }
  if (to.path.includes('/login') && token) {
    next('/')
  }
  if (!token && !isPublic) {
    localStorage.setItem('redirectAfterLogin', to.path);
    next('/login')
  }
  next();
});

export default router;
