import { render, staticRenderFns } from "./indicator-request-modal.component.vue?vue&type=template&id=d151ae78&scoped=true&"
import script from "./indicator-request-modal.component.vue?vue&type=script&lang=ts&"
export * from "./indicator-request-modal.component.vue?vue&type=script&lang=ts&"
import style0 from "./indicator-request-modal.component.vue?vue&type=style&index=0&id=d151ae78&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d151ae78",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCol,VForm,VRow,VTextarea})
