export default class BehavioralSkill {
  id: string;

  description: string;

  objective: string;

  active: boolean;

  junior: string;

  plain: string;

  senior: string;

  expanded: number | null = null;

  isApproved: boolean | null = null;

  observation: string;

  loadingIsApproved: boolean = false;

  loadingObservation: boolean = false;

  loadingReject: boolean = false;

  isNew: boolean = false;

  idTemporary: string | null = null;

  constructor(obj: any = {}) {
    this.id = obj.id || null;
    this.description = obj.description || '';
    this.objective = obj.objective || '';
    this.active = obj.active === undefined ? true : obj.active;
    this.junior = obj.junior || 'NOVICE';
    this.plain = obj.plain || 'NOVICE';
    this.senior = obj.senior || 'NOVICE';
    this.isApproved = obj.isApproved;
    this.observation = obj.observation || '';
    this.expanded = obj.expanded;
    this.idTemporary = obj.idTemporary || null;
    this.isNew = obj.isNew || false;
  }

  public static ofNew() {
    return new BehavioralSkill(
      {
        isNew: true,
        expanded: 0,
        isApproved: null,
        active: true,
        idTemporary: new Date().getTime().toString(),
      },
    );
  }
}
