import Indicators from '@/domain/drd/entity/indicators.entity';
import moment from 'moment';
import PersistAssignmentKeyArea from './persist-assignment-keyarea.entity';

export default class PersistDrd {
  assignmentId: string;

  assignmentDescription: string;

  objective: string;

  keyAreas: PersistAssignmentKeyArea[];

  indicators: Indicators[];

  roleName: string;

  revisionDate: string;

  constructor(obj: any = {}) {
    this.assignmentId = obj.assignmentId;
    this.assignmentDescription = obj.assignmentDescription;
    this.objective = obj.objective;
    this.keyAreas = obj.keyAreas || [];
    this.indicators = obj.indicators ? obj.indicators
      .map(it => new Indicators({ ...it, saved: true })) : [];
    this.roleName = obj.roleName;
    this.revisionDate = obj.revisionDate || '';
  }

  get revisionDateFormat() {
    return this.revisionDate ? moment(this.revisionDate).format('DD/MM/YYYY') : '';
  }

  get activeIndicators() {
    return this.indicators.filter(it => it.active);
  }

  get activeKeyAreas() {
    return this.keyAreas.filter(it => it.active);
  }
}
