import axios from 'axios';
import Assignment from '../entity/assignment.entity';
import AssignmentDto from '../entity/assignmentDto.entity';
import Ecosystem from '../entity/ecosystem.entity';
import RoleDrd from '../entity/roleDrd.entity';
import Response from '../entity/response.entity';
import RoleResponse from '../entity/roleResponse.entity';
import Indicators from '../entity/indicators.entity';
import AreaKey from '../entity/area-key/area-key.entity';
import BehavioralSkill from '../entity/behavioral-skill.entity';
import PerformanceDescription from '../entity/performance-description.entity';
import CreatePerformanceDescriptionRequest from '../entity/create-performance-description.request';
import TechnicalKnowledge from '../entity/technical-knowledge.entity';
import Formation from '../entity/formation.entity';
import DrdFilter from '../entity/filter-drd.entity';
import RoleDrdList from '../entity/roleDrdList.entity';
import RoleFilter from '../entity/role-filter.entity';
import AssignmentFilter from '../entity/filter-assignment.entity';

export default class DrdService {
  public static findDrd(pagination: any, sortBy: string, filter: DrdFilter): Promise<Response> {
    return axios.get('/api/drd/performance-description', {
      params: {
        ...filter,
        page: pagination.page - 1,
        size: pagination.itemsPerPage,
        orderBy: `${sortBy}|${pagination.sortDesc[0] ? 'desc' : 'asc'}`,
      },
    })
      .then((response: any) => Response.of(response.data));
  }

  public static getAllEcosystem(): Promise<Ecosystem[]> {
    return axios.get('/api/drd/ecosystems').then(({ data }) => data.map(it => new Ecosystem(it)));
  }

  public static findAssignments(
    filter: AssignmentFilter,
    page: number,
    size: number,
  ): Promise<Response> {
    return axios.get('/api/drd/assignments', {
      params: {
        role: filter.role.toString(),
        assignment: filter.assignment.toString(),
        ecosystem: filter.ecosystem.toString(),
        active: filter.active,
        page: page - 1,
        size,
      },
    })
      .then((response: any) => Response.of(response.data));
  }

  public static async getActiveAssignments(): Promise<Assignment[]> {
    return axios.get('/api/drd/assignments/all')
      .then(({ data }) => data.map(it => new Assignment(it)));
  }

  public static getAssigments(
    description: string = '',
    containsDRD: boolean | null = null,
  ): Promise<Assignment[]> {
    return axios.get(`/api/assignments?description=${description}&containsDRD=${containsDRD || ''}`)
      .then(({ data }) => data.map(it => new Assignment(it)));
  }

  public static getAssignmentById(assignmentId: string): Promise<Assignment> {
    return axios.get(`/api/drd/assignments/${assignmentId}`)
      .then(({ data }) => new Assignment(data));
  }

  public static createAssignment(assignment: AssignmentDto): Promise<void> {
    return axios.post('/api/drd/assignments', assignment);
  }

  public static updateAssignment(assignment: AssignmentDto): Promise<void> {
    return axios.put('/api/drd/assignments', assignment);
  }

  public static disableAssignment(assignmentId: string): Promise<Assignment> {
    return axios.patch(`/api/drd/assignments/${assignmentId}`)
      .then(({ data }) => new Assignment(data));
  }

  public static getRoleById(roleId: string): Promise<RoleDrd> {
    return axios.get(`/api/drd/role/${roleId}`)
      .then(({ data }) => new RoleDrd(data));
  }

  public static getAllRoles(
    page: number,
    size: number,
    sort: string,
    direction: string,
    filter: RoleFilter,
  ): Promise<RoleResponse> {
    return axios.get('/api/drd/role', {
      params: {
        roles: filter.hasRoles ? filter.roles.toString() : null,
        assignments: filter.hasAssignments ? filter.assignments.toString() : null,
        cboList: filter.hasCbo ? filter.cbo.toString() : null,
        active: filter.active,
        page: page - 1,
        size,
        orderBy: `${sort}|${direction}`,
      },
    })
      .then((response: any) => RoleResponse.of(response.data));
  }

  public static findRolesByName(roleName: string): Promise<RoleDrdList[]> {
    return axios.get(`/api/drd/role?roleName=${roleName}`)
      .then((response: any) => RoleDrdList.ofList(response.data));
  }

  public static createRole(role: RoleDrd): Promise<RoleDrd> {
    return axios.post('/api/drd/role', role);
  }

  public static updateRole(role: RoleDrd): Promise<RoleDrd> {
    return axios.put(`/api/drd/role/${role.id}`, role);
  }

  public static disableRole(roleId: string): Promise<RoleDrd> {
    return axios.patch(`/api/drd/role/${roleId}`)
      .then(({ data }) => new RoleDrd(data));
  }

  public static async getPerformanceDescription(performanceDescriptionId:string)
    : Promise<PerformanceDescription> {
    return axios.get(`/api/drd/performance-description/${performanceDescriptionId}`)
      .then((resp: any) => new PerformanceDescription(resp.data));
  }

  public static createPerformanceDescription(request: CreatePerformanceDescriptionRequest)
    : Promise<string> {
    return axios.post('/api/drd/performance-description', request);
  }

  public static replicatePerformanceDescription(
    request: CreatePerformanceDescriptionRequest,
    performanceDescriptionId: string,
  ): Promise<string> {
    return axios.post(`/api/drd/performance-description/${performanceDescriptionId}/clone`, request);
  }

  public static getIndicators(performanceDescriptionId: string): Promise<Indicators[]> {
    return axios.get(`/api/drd/performance-description/${performanceDescriptionId}/indicators`)
      .then((resp: any) => (resp.data));
  }

  public static updateIndicators(performanceDescriptionId: string, indicators: Indicators[])
    : Promise<any> {
    return axios.put(`/api/drd/performance-description/${performanceDescriptionId}/indicators`, { indicators });
  }

  public static getAreaKeys(performanceDescriptionId: string): Promise<any> {
    return axios.get(`/api/drd/performance-description/${performanceDescriptionId}/area-keys`)
      .then(({ data }: any) => data);
  }

  public static updateAreaKeys(
    performanceDescriptionId: string,
    areaKeys: AreaKey[],
  ): Promise<any> {
    return axios.put(`/api/drd/performance-description/${performanceDescriptionId}/area-keys`, { areaKeys });
  }

  public static getBehavioralSkills(performanceDescriptionId: string): Promise<any> {
    return axios.get(`/api/drd/performance-description/${performanceDescriptionId}/behavioral-skills`)
      .then(({ data }: any) => data);
  }

  public static updateBehavioralSkills(
    performanceDescriptionId: string,
    behavioralSkills: BehavioralSkill[],
  ): Promise<any> {
    return axios.put(`/api/drd/performance-description/${performanceDescriptionId}/behavioral-skills`, { behavioralSkills });
  }

  public static getTechnicalKnowledges(performanceDescriptionId: string): Promise<any> {
    return axios.get(`/api/drd/performance-description/${performanceDescriptionId}/technical-knowledge`)
      .then(({ data }: any) => data);
  }

  public static updateTechnicalKnowledges(
    performanceDescriptionId: string,
    technicalKnowledgeList: TechnicalKnowledge[],
  ): Promise<any> {
    return axios.put(`/api/drd/performance-description/${performanceDescriptionId}/technical-knowledge`, { technicalKnowledgeList });
  }

  public static getFormations(performanceDescriptionId: string): Promise<any> {
    return axios.get(`/api/drd/performance-description/${performanceDescriptionId}/formations`)
      .then(({ data }: any) => data);
  }

  public static updateFormations(
    performanceDescriptionId: string,
    formationList: Formation[],
  ): Promise<any> {
    return axios.put(`/api/drd/performance-description/${performanceDescriptionId}/formations`, { formationList });
  }

  public static getDefaultIndicators(page: number, size: number, sort: string): Promise<any> {
    return axios.get('/api/indicators/DEFAULT', {
      params: {
        page: page - 1,
        size,
        sort,
      },
    });
  }

  public static async delete(performanceDescriptionId: string, indicatorId: string): Promise<void> {
    return axios.delete(`/api/drd/performance-description/${performanceDescriptionId}/indicators/${indicatorId}`);
  }

  public static async getItensQuantity(performanceDescriptionId: string): Promise<any> {
    return axios.get(`/api/drd/performance-description/${performanceDescriptionId}/items-quantity`);
  }

  public static async updateDrdStatus(
    performanceDescriptionId: string,
    status: string,
  ): Promise<void> {
    return axios
      .patch(`/api/drd/${performanceDescriptionId}/status/${status}`)
  }

  public static async approveOrRejectIndicator(
    performanceIndicatorId: string,
    isApproved: boolean,
  ) {
    return axios.patch(`/api/drd/indicator/${performanceIndicatorId}/approve-or-reject/${isApproved}`)
  }

  public static async updateIndicatorObservation(
    performanceIndicatorId: string,
    observation: string,
  ) {
    return axios.patch(`/api/drd/indicator/${performanceIndicatorId}/observation`, { observation });
  }

  public static async approveOrRejectAreaKey(
    performanceAreaKeyId: string,
    isApproved: boolean,
  ) {
    return axios.patch(`/api/drd/area-key/${performanceAreaKeyId}/approve-or-reject/${isApproved}`)
  }

  public static async updateAreaKeyObservation(
    areaKeyId: string,
    observation: string,
  ) {
    return axios.patch(`/api/drd/area-key/${areaKeyId}/observation`, { observation });
  }

  public static async approveOrREjectAreaKeyItem(areaKeyItemId: string, isApproved: boolean) {
    return axios.patch(`/api/drd/area-key-item/${areaKeyItemId}/approve-or-reject/${isApproved}`)
  }

  public static async updateAreaKeyItemObservation(
    areaKeyItemId: string,
    observation: string,
  ) {
    return axios.patch(`/api/drd/area-key-item/${areaKeyItemId}/observation`, { observation });
  }

  public static async approveOrRejectBehavioralSkill(
    behavioralSkillId: string,
    isApproved: boolean,
  ) {
    return axios.patch(`/api/drd/behavioral-skill/${behavioralSkillId}/approve-or-reject/${isApproved}`)
  }

  public static async updateBehavioralSkillObservation(
    behavioralSkillId: string,
    observation: string,
  ) {
    return axios.patch(`/api/drd/behavioral-skill/${behavioralSkillId}/observation`, { observation });
  }

  public static async approveOrRejectTechnicalKnowledge(
    technicalKnowledgeId: string,
    isApproved: boolean,
  ) {
    return axios.patch(`/api/drd/technical-knowledge/${technicalKnowledgeId}/approve-or-reject/${isApproved}`)
  }

  public static async updateTechnicalKnowledgeObservation(
    technicalKnowledgeId: string,
    observation: string,
  ) {
    return axios.patch(`/api/drd/technical-knowledge/${technicalKnowledgeId}/observation`, { observation });
  }

  public static async approveOrRejectFormation(
    formationId: string,
    isApproved: boolean,
  ) {
    return axios.patch(`/api/drd/formation/${formationId}/approve-or-reject/${isApproved}`)
  }

  public static async updateFormationObservation(
    formationId: string,
    observation: string,
  ) {
    return axios.patch(`/api/drd/formation/${formationId}/observation`, { observation });
  }

  public static async updateObjective(
    performanceDescriptionId: string,
    objective: string,
  ) {
    return axios.patch(`/api/drd/performance-description/${performanceDescriptionId}/objective`, { objective });
  }

  public static sendIndicatorRequest(request: string): Promise<void> {
    return axios.post('/api/drd/indicator-request', { request });
  }
}
