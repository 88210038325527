














import { Vue, Component } from 'vue-property-decorator';
import { exchangeCodeForToken } from '@/domain/security/auth.service';

@Component
export default class Callback extends Vue {
  async mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (!code) {
      this.$router.push('/login');
      return;
    }

    try {
      const { data } = await exchangeCodeForToken(code);
      await this.$store.dispatch('login', {
        token: data.access_token,
        refreshToken: data.refresh_token,
        expirationAt: data.expires_in,
      })
      const redirectAfterLogin = localStorage.getItem('redirectAfterLogin');
      if (redirectAfterLogin) {
        localStorage.removeItem('redirectAfterLogin');
        this.$router.push(redirectAfterLogin);
        return;
      }
      this.$router.push('/');
    } catch (error) {
      this.$router.push('/login');
    }
  }
}
