







































import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import Indicators from '@/domain/drd/entity/indicators.entity';
import AddButton from '@/shared/buttons/add-button.component.vue';
import Loader from '@/views/drd/commons/loader.component.vue';
import FieldsArea from '@/views/drd/form/tabs/common-components/fields-area.component.vue';
import DefaultIndicatorEntity from '@/domain/drd/entity/default-indicator.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import CustomIndicatorAwarenessDialog from '../../drd/form/tabs/indicators/custom-indicator-awareness-dialog.component.vue';
import CustomIndicators from '../../drd/form/tabs/indicators/custom-indicators.component.vue';
import DefaultIndicators from '../../drd/form/tabs/indicators/default-indicators.component.vue';
import DefaultIndicatorPickerDialog from '../../drd/form/tabs/indicators/default-indicator-picker-dialog.component.vue';
import IndicatorCardComponent from './indicator-card.component.vue';

@Component({
  components: {
    AddButton,
    CustomIndicatorAwarenessDialog,
    CustomIndicators,
    DefaultIndicatorPickerDialog,
    DefaultIndicators,
    FieldsArea,
    Loader,
    LabelSlot,
    IndicatorCardComponent,
  },
})
export default class IndicatorsComponent extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  }) indicators!: Indicators[];

  @Prop({
    type: Boolean,
    default: true,
  }) showTitle!: boolean;

  loading = false;

  showIndicatorSelector: boolean = false;

  showIndicatorAwarenessDialog: boolean = false;

  customIndicatorAwared: boolean = false;

  showDefaultIndicatorPicker: boolean = false;

  awareCustomIndicator(): void {
    this.customIndicatorAwared = true;
    this.addNewIndicator(Indicators.of({}));
  }

  openDefaultIndicatorPicker(): void {
    this.showDefaultIndicatorPicker = true;
  }

  openIndicatorAwarenessDialog(): void {
    if (!this.customIndicatorAwared) {
      this.showIndicatorAwarenessDialog = true;
      return;
    }
    this.addNewIndicator(Indicators.of({}));
  }

  openIndicatorSelector(): void {
    this.showIndicatorSelector = true;
  }

  get defaultSelectedIndicators(): Indicators[] {
    return this.indicators.filter(it => it.indicatorType === 'DEFAULT');
  }

  defaultIndicatorSelected(defaultIndicator: DefaultIndicatorEntity, selected: boolean): void {
    if (selected) {
      this.addNewIndicator(Indicators.ofDefault(defaultIndicator));
      return;
    }
    const deletingIndex = this.indicators.findIndex(it => it.id === defaultIndicator.id);
    this.indicators.splice(deletingIndex, 1);
  }

  addNewIndicator(indicators: Indicators) {
    this.$emit('addNewIndicator', indicators);
  }

  deleteIndicator(index: number): void {
    this.$emit('deleteIndicator', index);
  }
}
