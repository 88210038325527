export default class RescissionEvidenceRequest {
  area: string;

  attachment: File;

  observation: string;

  constructor(obj: any = {}) {
    this.area = obj.area || '';
    this.attachment = obj.attachment || null;
    this.observation = obj.observation || '';
  }
}
