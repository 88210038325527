








































































import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import Indicators from '@/domain/drd/entity/indicators.entity';
import IndicatorService from '@/domain/drd/service/indicator/indicator.service';
import DefaultIndicator from '@/domain/drd/entity/default-indicator.entity';
import IndicatorDefaultFilter from '@/domain/drd/entity/indicators/indicator-default-filter.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import PaginationComponent from '@/shared/pagination.component.vue';
import IndicatorsUtils from '@/views/drd/commons/indicators-utils';
import IndicatorRequestModal from './indicator-request-modal.component.vue';
import DefaultIndicatorPickerFilter from './default-indicator-picker-filter.component.vue';
import Headers from './default-indicators-header.json';
import GoalAchievementTypes from './goal-achievement-types.json';

@Component({
  components: {
    PaginationComponent,
    LabelSlot,
    IndicatorRequestModal,
    DefaultIndicatorPickerFilter,
  },
})
export default class DefaultIndicatorPickerDialogComponent extends Vue {
  @Prop({ type: Boolean, default: false })
  value!: boolean;

  @Prop({ type: Array, default: () => ([]) })
  selectedIndicators!: Indicators[];

  loading: boolean = false;

  allIndicators: DefaultIndicator[] = [];

  search: string = '';

  headers = Headers;

  indicatorUtils: IndicatorsUtils = new IndicatorsUtils();

  showModal: boolean = false;

  filter: IndicatorDefaultFilter = new IndicatorDefaultFilter();

  pagination: any = {
    page: 1,
    itemsPerPage: 10,
    sortBy: 'indicator',
    sortDesc: false,
    pageCount: 0,
    total: 0,
  };

  noResultsText: string = 'Nenhum indicador foi encontrado nessa página com o filtro buscado, procure nas próximas. Você também pode aumentar o tamanho da paginação.';

  get selected(): DefaultIndicator[] {
    return this.selectedIndicators.map(it => DefaultIndicator.fromIndicator(it));
  }

  itemSelected({ item, value }: any): void {
    this.$emit('itemSelected', item, value);
  }

  inputDialog(val: boolean): void {
    this.$emit('input', val);
  }

  updateSortBy(sortBy: string): void {
    this.pagination.sortBy = sortBy;
    this.loadDefaultIndicators();
  }

  updateSortDesc(sortDesc: boolean): void {
    this.pagination.sortDesc = sortDesc;
    this.loadDefaultIndicators();
  }

  updatePage(pagination: any): void {
    if (this.pagination.itemsPerPage < pagination.itemsPerPage) {
      pagination.page = 1;
    }
    this.pagination = pagination;
    this.loadDefaultIndicators();
  }

  get sortBy(): string {
    if (!this.pagination.sortBy) return 'indicator';
    if (this.pagination.sortBy === 'utilityText') return 'utility';
    return this.pagination.sortBy;
  }

  setFilter(filter: IndicatorDefaultFilter): void {
    this.filter = filter;
    this.loadDefaultIndicators();
  }

  loadDefaultIndicators(): void {
    this.loading = true;
    IndicatorService.getIndicatorDefaults(
      this.filter,
      this.pagination.page,
      this.pagination.itemsPerPage,
      this.sortBy,
      this.pagination.sortDesc,
    ).then(({ data }: any) => {
      this.allIndicators = data.content.map(this.mapIndicator);
      this.pagination.pageCount = data.totalPages;
      this.pagination.total = data.totalElements;
    }).catch(VbSnackbarService.handleHttpError)
      .finally(() => this.loading = false);
  }

  mapIndicator(value: any): DefaultIndicator {
    const savedSelectedIds = this.selectedIndicators
      .filter(it => it.saved)
      .map(it => it.id);

    return new DefaultIndicator({
      ...value,
      isSelectable: !savedSelectedIds.includes(value.id),
    });
  }

  formatAchievementType(type: string): string {
    if (!type) return '';
    const found = GoalAchievementTypes.find(it => it.value === type);
    return found ? found.text : '';
  }

  requestNewIndicator(): void {
    this.showModal = true;
  }

  created(): void {
    this.loadDefaultIndicators();
  }
}
