
























































import {
  Vue,
  Component,
} from 'vue-property-decorator';
import { VbTooltipSlot } from 'bag-of-holding-library';
import CardTitle from '@/shared/card/card-title.component.vue';
import { hasAdministratorAuthority, hasCreateDrd } from '@/shared/directive/has-authority.directive';
import AddButton from '@/shared/buttons/add-button.component.vue';
import FilterDrd from '@/views/drd/table/filter/filter-drd.component.vue';
import DrdFilter from '@/domain/drd/entity/filter-drd.entity';
import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';
import CareerService from '@/domain/employees/service/career.service';
import DrdService from '@/domain/drd/service/drd.service';
import Assignment from '@/domain/drd/entity/assignment.entity';
import Ecosystem from '@/domain/drd/entity/ecosystem.entity';
import PerformanceDescriptionFiltered from '@/domain/drd/entity/performance-description-filtered.entity';
import Authority from '@/domain/security/authority.entity';
import AuthorityStore from '@/domain/security/authority.store';
import CreateDrdModal from './modal/create-drd-modal.component.vue';
import ReplicateDrdModal from './modal/replicate-drd-modal.component.vue';
import PerformanceDescriptionTableComponent from './table/performance-description-table.component.vue';
import HeaderComponent from './commons/list-header.component.vue';
import { StatusListSelect } from './commons/status-label';

@Component({
  name: 'PerformanceDescriptionView',
  components: {
    AddButton,
    CardTitle,
    VbTooltipSlot,
    CreateDrdModal,
    ReplicateDrdModal,
    FilterDrd,
    PerformanceDescriptionTableComponent,
    HeaderComponent,
  },
  directives: {
    hasAdministratorAuthority,
    hasCreateDrd,
  },
})
export default class PerformanceDescriptionView extends Vue {
  filter: DrdFilter = DrdFilter.of({})

  roles: UpdateAtribute[] = [];

  assignments: Assignment[] = [];

  assignmentsWithEcosystemAndRole: Assignment[] = [];

  ecosystems: Ecosystem[] = [];

  performanceDescriptionFiltered: PerformanceDescriptionFiltered[] = [];

  sortBy: Array<string> = ['roleName'];

  sortDesc: boolean[] = [false];

  showCreateModal = false;

  loadingSave = false;

  showReplicateModal = false;

  replicatedDrdId = ''

  newSortMapping: any = {
    assignmentName: 'assignment.description',
    ecosystemName: 'assignment.ecosystem.description',
    roleName: 'assignment.role.name',
  };

  hasUpdateDrds: boolean = false;

  authority: Authority = new Authority([]);

  created() {
    CareerService.getRoles()
      .then((resp: any) => this.roles = resp.data);

    DrdService.getActiveAssignments()
      .then((resp: any) => {
        this.assignments = resp;
        this.assignmentsWithEcosystemAndRole = resp.filter(it => it.role && it.ecosystem);
      });

    DrdService.getAllEcosystem()
      .then((resp) => {
        this.ecosystems = resp
      }).finally(() => this.updateDrdFilter())
    this.validationAuthority();
  }

  defineSortBy(value: any): any {
    if (!value) {
      return 'role';
    }
    return this.newSortMapping[value];
  }

  updateDrdFilter(): void {
    this.loadingSave = true;
    DrdService.findDrd(
      this.pagination,
      this.defineSortBy(this.pagination.sortBy[0]),
      this.filter,
    ).then((resp: any) => {
      this.performanceDescriptionFiltered = PerformanceDescriptionFiltered.ofList(resp.content)
      this.pagination.total = resp.totalElements || 0;
      this.pagination.pageCount = resp.totalPages;
    })
      .finally(() => this.loadingSave = false)
  }

  openDialogForNewDrd() {
    this.showCreateModal = true;
  }

  closeCreateModal() {
    this.showCreateModal = false;
  }

  openDialogForReplicateDrd(item: any) {
    this.replicatedDrdId = item.id;
    this.showReplicateModal = true;
  }

  closeReplicateModal() {
    this.showReplicateModal = false;
  }

  pagination: any = {
    itemsPerPage: 10,
    page: 1,
    sortBy: this.sortBy,
    sortDesc: this.sortDesc,
    total: 0,
    pageCount: 0,
  }

  applyFilter(value: DrdFilter) {
    this.pagination.page = 1;
    this.filter = value;
    this.updateDrdFilter();
  }

  setFilterValues(value: any) {
    if (this.pagination.itemsPerPage < value.itemsPerPage) {
      value.page = 1;
    }
    this.pagination = value;
    this.updateDrdFilter();
  }

  async validationAuthority() {
    await this.getAuthority();
    this.hasUpdateDrds = this.authority.hasUpdateDrds();
  }

  async getAuthority() {
    await AuthorityStore.fetchedAuthority().then(authority => this.authority = authority);
  }
}
