






















































import RescissionEvidence from '@/domain/resignation/entity/rescission-evidence.entity';
import ResignationService from '@/domain/resignation/service/resignation.service';
import { VbSnackbarService } from 'bag-of-holding-library';
import DateFormat from '@/shared/utils/date-format';
import { Vue, Component, Prop } from 'vue-property-decorator';
import CreateRescissionEvidenceModal from './create-rescission-evidences-modal.component.vue';

@Component({
  components: {
    CreateRescissionEvidenceModal,
  },
})
export default class RescissionEvidencesListComponent extends Vue {
  @Prop({
    type: String,
    required: true,
  }) resignationId!: string;

  @Prop() hasCreateRescissionEvidences!: boolean;

  loadingGetRescissionEvidences: boolean = false;

  rescissionEvidences: RescissionEvidence[] = [];

  rescissionTableHeaders: any = [
    {
      text: 'Área',
      value: 'area',
      sortable: false,
    },
    {
      text: 'Observação',
      value: 'observation',
      sortable: false,
    },
    {
      text: 'Data de envio',
      value: 'createdDate',
      sortable: false,
    },
    {
      text: 'Enviado por',
      value: 'createdBy',
      sortable: false,
    },
    {
      text: 'Ações',
      value: 'actions',
      sortable: false,
    },
  ]

  showCreateRescissionEvidenceModal: boolean = false;

  createRescissionEvidenceModalKey: number = 0;

  created() {
    this.getRescissionEvidences();
  }

  getRescissionEvidences() {
    this.loadingGetRescissionEvidences = true;
    ResignationService.getRescissionEvidences(this.resignationId)
      .then((data: RescissionEvidence[]) => {
        this.rescissionEvidences = data;
      }).catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loadingGetRescissionEvidences = false;
      });
  }

  handleCloseCreateRescissionEvidenceModal(reload: boolean) {
    this.showCreateRescissionEvidenceModal = false;
    this.createRescissionEvidenceModalKey += 1;
    if (reload) {
      this.getRescissionEvidences();
    }
  }

  async downloadRescissionEvidence(rescissionEvidence: RescissionEvidence) {
    rescissionEvidence.loadingDownload = true;
    try {
      const file = await ResignationService.downloadRescissionEvidence(rescissionEvidence.id);
      const linkElement = document.createElement('a');
      const url = window.URL.createObjectURL(file);
      linkElement.href = url;
      linkElement.download = rescissionEvidence.attachmentName;
      document.body.appendChild(linkElement);
      linkElement.click();

      document.body.removeChild(linkElement);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      VbSnackbarService.handleHttpError(error);
    } finally {
      rescissionEvidence.loadingDownload = false;
    }
  }

  formatDate(date: string): string {
    return DateFormat.standardDate(date);
  }
}
