

















import { authConfig } from '@/config/authConfig';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class LoginComponent extends Vue {
  login() {
    const authUrl = `${authConfig.authEndpoint}?client_id=${authConfig.clientId}`
    + '&response_type=code'
    + `&redirect_uri=${encodeURIComponent(`${authConfig.redirectUri}callback`)}`
    + `&scope=${authConfig.scopes}`;

    window.location.href = authUrl;
  }
}
