















































































































































































































































import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import Employee from '@/domain/employees/entity/employee.entity';
import EmployeeAccompaniment from '@/domain/employees/entity/employee-accompaniment.entity';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue'
import DateHandler from '@/shared/utils/date-format';
import TabButton from '@/shared/tab-button.component.vue';
import MobileHandler from '@/shared/utils/mobile-handler';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import EmployeeService from '@/domain/employees/service/employee.service';
import Resignation from '@/domain/resignation/entity/resignation.entity';
import { VbSnackbarService } from 'bag-of-holding-library';
import PersonalData from '@/domain/employees/entity/personal-data.entity';
import PersonalDataService from '@/domain/employees/service/personal-data.service';
import GodfatherService from '@/domain/employees/service/godfather.service';
import GodfatherRequest from '@/domain/employees/entity/godfather-request.entity';
import Rules from '@/shared/rules/rules';
import DateFormat from 'bag-of-holding-library/src/utils/date-format';
import EmployeeTalentLevel from '@/domain/employees/entity/employee-talent-level.entity';
import { getUserPhotoByEmail } from '@/domain/security/auth.service';
import EmployeeTalentLevelModal from './modal/employee-talent-level-modal.vue';
import ResignationModal from './resignation/resignation-modal.component.vue';

@Component({
  components: {
    TabButton,
    ModalWarning,
    ResignationModal,
    DatePickerField,
    EmployeeTalentLevelModal,
  },
  filters: {
    formatDate(value: string) {
      return DateHandler.timestampToLocal(value.split('T')[0]);
    },
    formatDateTime(value: string) {
      return DateHandler.fullDateAndTime(value);
    },
  },
})
export default class Employees extends Vue {
  @Prop({
    required: true,
  }) employee!: Employee;

  @Prop() checkAccessCreateResignation!: boolean;

  @Prop() checkAccessUpdateGodfather!: boolean;

  @Prop() checkAccessUpdateAcompanied!: boolean;

  @Prop() checkAccessUpdateNeedTraining!: boolean;

  @Prop({
    type: String,
  }) performanceDescriptionId!: string;

  @Prop({
    type: String,
    default: '',
  }) assignmentId!: string;

  @Prop({
    type: String,
    default: '',
  }) secondaryPerforamnceDescriptionId!: string;

  resignation!: Resignation;

  isMobile: boolean = false;

  warningModalValue: boolean = false;

  warningModalValueNeedTraining: boolean = false;

  warningModalValueStatus: boolean = false;

  warningModalValueStatusByTrainingDate: boolean = false;

  employeeAccompaniment: EmployeeAccompaniment = EmployeeAccompaniment.of(this.employee);

  showResignationModal: boolean = false;

  modalKey: number = 0;

  tab: number = 0;

  items: Array<string> = ['Nomenclaturas'];

  image: any = localStorage.getItem('photo');

  hasImage = false;

  photoLoading = true;

  personalData: PersonalData = PersonalData.of({});

  keyStatusValue: number = 0;

  rules: Rules = new Rules();

  openTalentLevelModal: boolean = false;

  talentLevelHistory: EmployeeTalentLevel[] = []

  showMenu: boolean = false;

  @Watch('tab')
  selectTab() {
    this.$emit('selectTab', this.tab);
  }

  onResize(): void {
    this.isMobile = MobileHandler.isMobile();
  }

  changeAccompanimentValue(): void {
    this.warningModalValue = true;
  }

  changeNeedTrainingValue(): void {
    this.warningModalValueNeedTraining = true;
  }

  changeStatusValueByTrainingDate(): void {
    this.warningModalValueStatusByTrainingDate = true;
  }

  changeStatusValue(): void {
    if (!this.employee.godfather.status && this.employee.godfather.trainingDate !== '') {
      this.warningModalValueStatus = true;
    }
  }

  created() {
    this.getUserPhoto();
  }

  async getUserPhoto() {
    this.photoLoading = true;
    const email = `${this.$route.params.username.toLocaleLowerCase()}@db1.com.br`;
    try {
      const response = await getUserPhotoByEmail(email)
      const photoUrl = URL.createObjectURL(response);
      this.image = photoUrl;
      this.hasImage = true;
    } finally {
      this.photoLoading = false;
    }
  }

  confirm(): void {
    EmployeeService.updateAccompaniment(this.employee.id, this.employeeAccompaniment)
      .then(() => VbSnackbarService.showSuccess('Alteração realizada com sucesso!'))
      .catch((error: any) => {
        VbSnackbarService.handleHttpError(error);
        this.employeeAccompaniment.isAccompanied = !this.employeeAccompaniment.isAccompanied;
      })
      .finally(() => {
        this.warningModalValue = false;
      });
  }

  confirmNeedTraining(): void {
    const { needTraining } = this.employee.godfather;
    const { id } = this.employee;
    GodfatherService.updateNeedTrainingGodfather(needTraining, id)
      .then(() => VbSnackbarService.showSuccess('Alteração realizada com sucesso!'))
      .catch((error: any) => {
        VbSnackbarService.handleHttpError(error);
        this.employee.godfather.needTraining = !this.employee.godfather.needTraining;
      })
      .finally(() => {
        this.warningModalValueNeedTraining = false;
      });
  }

  confirmStatusByTrainingDate(): void {
    this.employee.godfather.updateLastTrainingDate()
    const requestGodfather = GodfatherRequest.of({
      isGrandFather: this.employee.godfather.status,
      trainingDate: this.employee.godfather.trainingDate,
    })

    GodfatherService.updateGodfather(requestGodfather, this.employee.id)
      .then(() => {
        VbSnackbarService.showSuccess('Alteração realizada com sucesso!');
        this.employee.godfather.needTraining = false
      })
      .catch((err: any) => {
        VbSnackbarService.handleHttpError(err);
        this.employee.godfather.status = !this.employee.godfather.status;
      })
      .finally(() => {
        this.warningModalValueStatus = false;
        this.warningModalValueStatusByTrainingDate = false;
      })
  }

  cancelNeedTraining(): void {
    this.employee.godfather.needTraining = !this.employee.godfather.needTraining;
    this.warningModalValueNeedTraining = false;
  }

  cancelStatusByTrainingDate(): void {
    this.employee.godfather.revertTrainingDateAndStatus()
    this.warningModalValueStatusByTrainingDate = false;
  }

  confirmStatus(): void {
    this.employee.godfather.trainingDate = '';
    this.confirmStatusByTrainingDate();
  }

  cancelStatus(): void {
    this.employee.godfather.status = !this.employee.godfather.status;
    this.warningModalValueStatus = false;
  }

  close() {
    this.showResignationModal = false;
    this.modalKey += 1;
    this.$emit('refresh');
  }

  cancel(): void {
    this.employeeAccompaniment.isAccompanied = !this.employeeAccompaniment.isAccompanied;
    this.warningModalValue = false;
  }

  @Watch('employee.isAccompanied')
  accompanimentValueChanged(val: boolean): void {
    this.employeeAccompaniment.isAccompanied = val;
  }

  @Watch('employee.godfather.status')
  statusValueChanged(value: boolean): void {
    this.employee.godfather.status = value;
  }

  @Watch('employee.godfather.needTraining')
  needTrainingValueChanged(value: boolean): void {
    this.employee.godfather.needTraining = value;
  }

  @Watch('employee')
  getPersonalData() {
    PersonalDataService.findData(this.employee.id)
      .then((resp: any) => {
        this.personalData = PersonalData.of(resp);
      })
  }

  applyTrainingDate(value: string) {
    this.employee.godfather.trainingDate = value;
    if (this.keyStatusValue > 0 && this.employee.godfather.verifyChangeTrainingDate && value !== '') {
      this.changeStatusValueByTrainingDate();
      return
    }
    this.keyStatusValue += 1
  }

  clearTrainingDate() {
    if (this.employee.godfather.trainingDate !== '') {
      this.employee.godfather.trainingDate = '';
      this.employee.godfather.status = false;
      this.changeStatusValueByTrainingDate();
      return
    }
    this.employee.godfather.trainingDate = '';
  }

  redirect(url: string, queryParams?: any) {
    this.$router.push({ path: url, query: { ...queryParams } });
    this.showMenu = !this.showMenu;
  }

  formatDate(date: string) {
    return DateFormat.splitLocal(date);
  }

  fetchHistoryTalentLevel(history: EmployeeTalentLevel[]) {
    this.talentLevelHistory.push(...history);
  }

  showTalentLevelModal() {
    this.openTalentLevelModal = true;
  }

  redirectToPerformanceDescription(performanceDescriptionId: string) {
    this.$router.push(`/performance-description/${performanceDescriptionId}/view`);
    this.showMenu = !this.showMenu;
  }
}
