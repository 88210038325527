













































































































































import KeyAreaResponsibilityLevel from '@/domain/drd-2.0/entity/key-area-responsibility-level.entity';
import KeyAreaResponsibility from '@/domain/drd-2.0/entity/key-area-responsibility.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import ResponsibilityFormComponent from './responsibility-form.component.vue';
import ResponsibilityItemAction from './responsibility-item-action.component.vue';
import ResponsibilityLanguageLabelComponent from './responsibility-language-label.component.vue';

@Component({
  name: 'ResponsibilityNodeComponent',
  components: {
    ResponsibilityTreeComponent: () => import('./responsibility-tree.component.vue'),
    LabelSlot,
    ResponsibilityFormComponent,
    ResponsibilityItemAction,
    ResponsibilityLanguageLabelComponent,
  },
})
export default class ResponsibilityNodeComponent extends Vue {
  @Prop() responsibility!: KeyAreaResponsibility;

  @Prop() responsibilityOptions!: any;

  @Prop({
    type: String,
    default: 'PTBR',
  }) languageSelected!: string;

  @Prop({
    type: Array,
    default: [],
  }) languageOptions!: any[];

  @Prop({
    type: String,
    required: true,
  }) backgroundColor!: string;

  @Prop({
    type: Number,
    required: true,
  }) hiearchyLevel!: number;

  isEditing: boolean = false;

  showConfig: boolean = false;

  expandChildren: boolean = false;

  isAddingChildren: boolean = false;

  getExpectedLabel(expected: number): string {
    const optionFiltered = this.getOptionByExpected(expected);
    if (!optionFiltered) {
      return '';
    }
    return optionFiltered.name;
  }

  getOptionByExpected(expected: number): any {
    return this.responsibilityOptions
      .filter((option: any) => option.value === expected)[0];
  }

  handleAddChildren() {
    this.isAddingChildren = !this.isAddingChildren;
    this.expandChildren = true;
  }

  get responsibilityChildrenActive(): KeyAreaResponsibility[] {
    return this.responsibility.children.filter((child: KeyAreaResponsibility) => child.active);
  }

  handleDelete() {
    if (!this.responsibility.id) {
      this.$emit('delete');
      return;
    }
    this.responsibility.inactivate();
  }

  get hasLevelsNotConfigured(): boolean {
    return this.responsibility.levels
      .some((level: KeyAreaResponsibilityLevel) => !level.isConfigured);
  }

  get responsibleConteinerClasses() {
    return {
      padding: '8px',
      'border-radius': '12px',
      border: '1px solid rgb(226 232 240)',
      background: this.hasLevelsNotConfigured ? 'rgb(255 251 235)' : this.backgroundColor,
    }
  }
}
