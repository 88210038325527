


















































import { Vue, Component, Prop } from 'vue-property-decorator';
import ResignationSummary from '@/domain/resignation/entity/resignation-summary.entity';
import PaginationComponent from '@/shared/pagination.component.vue';
import DateFormat from '@/shared/utils/date-format';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import ResignationService from '@/domain/resignation/service/resignation.service';
import { VbSnackbarService } from 'bag-of-holding-library';
import ModalOptions from '@/shared/modal/modal-options.entity';
import headers from './resignation.header.json';
import ResignationEditModal from './resignation-edit-modal.component.vue';

@Component({ components: { PaginationComponent, ModalWarning, ResignationEditModal } })
export default class ResignationsListComponent extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  private resignations!: ResignationSummary[];

  @Prop({
    type: Boolean,
    default: false,
  })
  private loading!: boolean;

  @Prop({ required: true })
  private pagination!: any;

  @Prop() hasDeleteResignations!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) hasReadResignationDetails!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) hasReadRescissionEvidences!: boolean;

  deleteConfirmationOptions: ModalOptions = {
    title: 'Atenção!',
    description: 'Deseja mesmo remover essa solicitação de desligamento?',
    width: 500,
    showCancel: true,
    showOk: true,
    loadingOk: false,
    labelCancel: 'Cancelar',
    labelOk: 'Remover',
    okColor: 'error',
  };

  private headers: any = headers;

  private showDeleteConfirmationDialog: boolean = false;

  private deletingResignationId: string | null = null;

  private updateSortBy(sortBy: string[]): void {
    this.updatePagination({
      ...this.pagination,
      sortBy,
    });
  }

  private updateSortDesc(sortDesc: string[]): void {
    this.updatePagination({
      ...this.pagination,
      sortDesc,
    });
  }

  private updatePagination(newPagination: any): void {
    this.$emit('update:pagination', newPagination);
  }

  private toDate(date: string): string {
    if (!date) return '-';
    return DateFormat.dateTimeFormated(date);
  }

  private editItem(item: ResignationSummary): void {
    this.$emit('edit', item.id);
  }

  private deleteItem(): void {
    if (this.deletingResignationId === null) return;
    this.deleteConfirmationOptions.loadingOk = true;
    this.deleteConfirmationOptions.showCancel = false;
    ResignationService.deleteById(this.deletingResignationId)
      .then(() => {
        VbSnackbarService.showSuccess('Solicitação de desligamento removida com sucesso.');
        this.$emit('deleted');
        this.closeDeleteConfirmationDialog();
      })
      .catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.deleteConfirmationOptions.loadingOk = false;
        this.deleteConfirmationOptions.showCancel = true;
      });
  }

  private openConfirmationDialog(id: string): void {
    this.deletingResignationId = id;
    this.showDeleteConfirmationDialog = true;
  }

  private closeDeleteConfirmationDialog(): void {
    this.deletingResignationId = null;
    this.showDeleteConfirmationDialog = false;
  }
}
