









































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class StatusTabComponent extends Vue {
  @Prop({ type: Number, default: 0 })
  approvedOrPendingCount!: number;

  @Prop({ type: Number, default: 0 })
  rejectedCount!: number;

  @Prop({ type: Number, default: 0 })
  penddingCount!: number;

  @Prop({ type: Number, default: 0 })
  tab!: number;
}
