
























































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import AreaKey from '@/domain/drd/entity/area-key/area-key.entity';
import Rules from '@/shared/rules/rules';
import FieldsArea from '@/views/drd/form/tabs/common-components/fields-area.component.vue';
import ActiveCheckboxField from '@/views/drd/form/tabs/common-components/active-checkbox-field.component.vue';
import AddButton from '@/shared/buttons/add-button.component.vue';
import AreaKeyItemComponent from '@/views/drd/form/tabs/area-key/area-key-item.component.vue';
import AreaKeyItem from '@/domain/drd/entity/area-key/area-key-item.entity';
import DrdService from '@/domain/drd/service/drd.service';
import PerformanceDescription from '@/domain/drd/entity/performance-description.entity';
import ItemComponent from '../common-components/item.component.vue';
import StatusTabComponent from '../common-components/status-tab.component.vue';

@Component({
  components: {
    ActiveCheckboxField,
    AddButton,
    AreaKeyItemComponent,
    FieldsArea,
    LabelSlot,
    ItemComponent,
    StatusTabComponent,
  },
})
export default class AreaKeyComponent extends Vue {
  @Prop({
    type: AreaKey,
    required: true,
  })
  areaKey!: AreaKey;

  @Prop({
    type: Object,
  }) performanceDescription!: PerformanceDescription;

  @Prop({
    type: Boolean,
    default: false,
  }) hasDrdApproveProccess!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) showApproveAndRejectBtns!: boolean;

  rules: Rules = new Rules();

  expandItems: boolean = false;

  tab: number = 0;

  updateField(field: string, value: any): void {
    this.$emit(`update:${field}`, value);
  }

  addNewAreaKeyItem(): void {
    this.tab = 1;
    this.$emit('pushAreaKeyItem');
  }

  deleteAreaKey(): void {
    this.$emit('deleteAreaKey');
  }

  deleteAreaKeyItem(areaKeyItem: AreaKeyItem): void {
    this.$emit('deleteAreaKeyItem', areaKeyItem);
  }

  get approvedAndPendingAreaKeyItems(): AreaKeyItem[] {
    return this.areaKey.items
      .filter(item => item.isApproved && item.active);
  }

  get rejectedAreaKeyItems(): AreaKeyItem[] {
    return this.areaKey.items
      .filter(item => item.isApproved === false && item.active);
  }

  get penddingAreaKeyItems(): AreaKeyItem[] {
    return this.areaKey.items
      .filter(item => item.isApproved === null && item.active);
  }

  async updateIsApprovedKeyAreaItem(areaKeyItem: AreaKeyItem, value: boolean): Promise<void> {
    return DrdService.approveOrREjectAreaKeyItem(areaKeyItem.id, value)
      .then(() => {
        areaKeyItem.isApproved = value;
      })
      .catch((error) => {
        VbSnackbarService.handleHttpError(error);
      });
  }

  async approveAreaKeyItem(areaKeyItem: AreaKeyItem) {
    areaKeyItem.loadingApprove = true;
    await this.updateIsApprovedKeyAreaItem(areaKeyItem, true)
      .finally(() => {
        areaKeyItem.loadingApprove = false;
      });
  }

  async rejectAreaKeyItem(areaKeyItem: AreaKeyItem) {
    areaKeyItem.loadingReject = true;
    await this.updateIsApprovedKeyAreaItem(areaKeyItem, false)
      .finally(() => {
        areaKeyItem.loadingReject = false;
      });
  }

  fetchObservation(areaKeyItem: AreaKeyItem): void {
    areaKeyItem.loadingObservation = true;
    DrdService.updateAreaKeyItemObservation(areaKeyItem.id, areaKeyItem.observation)
      .catch((error) => {
        VbSnackbarService.handleHttpError(error);
      }).finally(() => {
        areaKeyItem.loadingObservation = false;
      });
  }
}
