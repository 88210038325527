













































































import { Vue, Component, Prop } from 'vue-property-decorator';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import ModalOptions from '@/shared/modal/modal-options.entity';

@Component({
  components: {
    ModalWarning,
  },
})
export default class ResponsibilityItemAction extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) responsibility!: any;

  @Prop({
    type: Boolean,
    default: false,
  }) showConfigButton!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) hasLevelsNotConfigured!: boolean;

  showDeleteModalWarning: boolean = false;

  deleteModalWarningOptions: ModalOptions = {
    title: 'Atenção',
    description: 'Tem certeza que deseja excluir este item?',
    labelCancel: 'Cancelar',
    labelOk: 'Excluir',
    okColor: 'error',
    showCancel: true,
    showOk: true,
    width: 400,
  }

  get configurationButtonMessage() {
    return this.hasLevelsNotConfigured
      ? 'Existem senioridades não configuradas'
      : 'Configurar Níveis por Senioridade';
  }

  get configurationButtonColor() {
    return this.hasLevelsNotConfigured ? '#e99d60' : 'black';
  }

  get configurationButtonIcon() {
    return this.hasLevelsNotConfigured ? 'mdi-alert-circle-outline' : 'mdi-cog-outline';
  }
}
