















































































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class SeniorityLevelComponent extends Vue {
  @Prop({
    type: String,
    default: '',
  })
  label!: String;

  @Prop({
    type: String,
    default: 'NOVICE',
  })
  value!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly!: boolean;

  updateValue(value: string): void {
    this.$emit('input', value);
  }

  levelLabels = {
    NOVICE: {
      text: 'Novato',
      description: 'É um iniciante no tema, não possui conhecimento e experiência',
    },
    PRACTICING: {
      text: 'Praticante',
      description: 'Possui conhecimento sobre o tema, está praticando sem maiores problemas, mas ainda tem o que aprender',
    },
    PROFESSIONAL: {
      text: 'Profissional',
      description: 'Domina o tema e possui habilidade suficiente para inclusive ensinar outras pessoas',
    },
    EXPERT: {
      text: 'Expert',
      description: 'Cria conhecimentos que melhoram seu trabalho e o da equipe, tornando-se referência e inspirando os demais.',
    },
  }
}
