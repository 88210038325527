



































































import { Vue, Component } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Utilities from '@/views/drd/form/tabs/indicators/utility-types.json'
import FilterButtonComponent from '@/shared/buttons/filter-button.component.vue';
import debounce from '@/shared/utils/debounce';
import IndicatorDefaultFilter from '@/domain/drd/entity/indicators/indicator-default-filter.entity';

@Component({
  components: {
    LabelSlot,
    FilterButtonComponent,
  },
})
export default class DefaultIndicatorPickerFilterComponent extends Vue {
  showFilter: boolean = false;

  filter: IndicatorDefaultFilter = new IndicatorDefaultFilter();

  utilities = Utilities;

  clearFilter(filter: string) {
    this.filter[filter] = '';
    this.find();
  }

  find() {
    this.$emit('find', this.filter);
  }

  findDebounce(value: string) {
    if (value.length !== 0 && value.length < 3) return;
    debounce.debounce(() => this.find(), 700);
  }
}
