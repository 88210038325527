





































































































import Indicators from '@/domain/drd/entity/indicators.entity';
import { Vue, Component, Prop } from 'vue-property-decorator';
import WeightCategories from '@/views/drd/form/tabs/indicators/weight-categories.json';
import IndicatorsUtils from '@/views/drd/commons/indicators-utils';
import LabelSlot from 'bag-of-holding-library/src/slot/label-slot.component.vue';

@Component({
  components: {
    LabelSlot,
  },
})
export default class IndicatorCardComponent extends Vue {
  @Prop({ required: true }) indicator!: Indicators;

  editWeigth: boolean = false;

  weightCategories = WeightCategories

  indicatorUtils: IndicatorsUtils = new IndicatorsUtils();
}
