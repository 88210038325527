import IndicatorData from './indicators.entity';

export default class CreatePerformanceDescriptionRequest {
  public assignmentId: string;

  public objective: string;

  constructor(obj: any = {}) {
    this.assignmentId = obj.assignmentId;
    this.objective = obj.objective || '';
  }

  public static of(performanceDescription: any): CreatePerformanceDescriptionRequest {
    return new CreatePerformanceDescriptionRequest(performanceDescription);
  }
}
