


















































































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import PaginationComponent from '@/shared/pagination.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import MobileHandler from '@/shared/utils/mobile-handler';
import splitContent from '@/shared/directive/split-content';
import PerformanceDescriptionFiltered from '@/domain/drd/entity/performance-description-filtered.entity';
import AuthorityStore from '@/domain/security/authority.store';
import { VbSnackbarService, VbTooltipSlot } from 'bag-of-holding-library';
import DrdService from '@/domain/drd/service/drd.service';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import { PerformanceDescriptionStatus } from '@/domain/drd/entity/performance-description-status.enum';
import ModalOptions from '@/shared/modal/modal-options.entity';
import Header from './header.json';

@Component({
  components: {
    PaginationComponent,
    LabelSlot,
    VbTooltipSlot,
    ModalWarning,
  },
  filters: {
    splitContent,
  },
})
export default class PerformanceDescriptionTableComponent extends Vue {
  @Prop() items!: PerformanceDescriptionFiltered[];

  @Prop() loading!: boolean;

  @Prop() page!: any;

  @Prop() hasUpdateDrds!: boolean;

  isMobile = false;

  header = Header;

  isAdmin: boolean = false;

  loadingEdit: boolean = false;

  modalWarningOptions: ModalOptions = {
    title: 'Atenção',
    description: 'Deseja realmente editar a DRD? Ao confirmar, a DRD passa a ficar em rascunho e ficará indisponível até a aprovação.',
    showOk: true,
    labelOk: 'Editar DRD',
    showCancel: !this.loadingEdit,
    labelCancel: 'Cancelar',
    okColor: 'primary',
    loadingOk: this.loadingEdit,
    width: 500,
  }

  showEditModalWarning: boolean = false;

  drdEditId: string = '';

  performanceDescriptionStatusLabel: any = {
    [PerformanceDescriptionStatus.DRAFT]: {
      text: 'Rascunho',
      color: 'primary',
    },
    [PerformanceDescriptionStatus.APPROVED]: {
      text: 'Aprovado',
      color: 'success',
    },
    [PerformanceDescriptionStatus.REJECTED]: {
      text: 'Recusado',
      color: 'error',
    },
    [PerformanceDescriptionStatus.PENDING_APPROVAL]: {
      text: 'Pendente de Aprovação',
      color: 'warning',
    },
  }

  updateTable(pagination: any): void {
    this.$emit('update', pagination);
  }

  onResize() {
    this.isMobile = MobileHandler.isMobile();
  }

  select(item: PerformanceDescriptionFiltered): void {
    this.$router.push({
      path: `/performance-description/${item.id}/view`,
    })
  }

  editItem(item: PerformanceDescriptionFiltered): void {
    const isDraft = item.status === PerformanceDescriptionStatus.DRAFT;
    const isPendingApproval = item.status === PerformanceDescriptionStatus.PENDING_APPROVAL;
    if (isDraft
      || isPendingApproval) {
      this.redirectToEditScreen(item.id);
      return;
    }
    this.drdEditId = item.id;
    this.showEditModalWarning = true;
  }

  updateToDraft(): void {
    this.modalWarningOptions.loadingOk = true;
    this.modalWarningOptions.showCancel = false;
    DrdService.updateDrdStatus(this.drdEditId, PerformanceDescriptionStatus.DRAFT)
      .then(() => {
        this.redirectToEditScreen(this.drdEditId);
      }).catch((error) => {
        VbSnackbarService.handleHttpError(error)
      }).finally(() => {
        this.modalWarningOptions.loadingOk = false;
        this.modalWarningOptions.showCancel = true;
        this.showEditModalWarning = false;
      });
  }

  redirectToEditScreen(performanceDescriptionId: string): void {
    this.$router.push({
      path: `/performance-description/${performanceDescriptionId}`,
    })
  }

  updateSortBy(sortBy: string): void {
    this.updateTable({
      ...this.page,
      sortBy,
    });
  }

  updateSortDesc(sortDesc: string): void {
    this.updateTable({
      ...this.page,
      sortDesc,
    });
  }

  created(): void {
    AuthorityStore.isAdministrator().then((resp: boolean) => {
      this.isAdmin = resp;
    });
  }

  openReplicateDialog(item: PerformanceDescriptionFiltered): void {
    this.$emit('openReplicateDialog', item);
  }

  get headers() {
    if (!this.hasUpdateDrds) {
      return this.header.filter((it: any) => it.value !== 'status');
    }
    return this.header;
  }
}
