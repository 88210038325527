










































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { VueEditor } from 'vue2-editor';
import { VbSnackbarService } from 'bag-of-holding-library';
import Assignment from '@/domain/drd/entity/assignment.entity';
import AssignmentSingleFilter from '@/shared/filters/assignment/assignment-single-filter.component.vue';
import PersistDrd from '@/domain/drd-2.0/entity/persist-drd.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import KeyAreaService from '@/domain/drd-2.0/service/key-area.service';
import KeyAreaFilter from '@/domain/drd-2.0/entity/key-area-filter.entity';
import KeyArea from '@/domain/drd-2.0/entity/key-area.entity';
import PersistAssignmentKeyArea from '@/domain/drd-2.0/entity/persist-assignment-keyarea.entity';
import MobileHandler from '@/shared/utils/mobile-handler';
import Drd20Service from '@/domain/drd-2.0/service/drd.service';
import Loading from '@/shared/loading/loading.component.vue';
import Indicators from '@/domain/drd/entity/indicators.entity';
import CardTitle from '@/shared/card/card-title.component.vue';
import DatePickerMenu from '@/shared/date-picker/data-picker-menu.component.vue';
import SelectAssignmentDebouceComponent from '@/shared/assignment/select-assignment-debouce.component.vue';
import ToolbarConfig from './key-area/components/toolbar-config.json';
import IndicatorsComponent from './components/indicators.component.vue';
import KeyAreaFormComponent from './key-area/components/form.component.vue';
import KeyAreaDrawerComponent from './components/key-area-drawer.component.vue';
import SelectKeyAreasDialog from './key-area/components/select-keyarea-dialog.component.vue';
import KeyAreaSwapComponent from './components/key-area-swap.component.vue';
import RevisionDateHistoryDrawer from './components/revision-date-history-drawer.vue';

@Component({
  components: {
    AssignmentSingleFilter,
    LabelSlot,
    VueEditor,
    Loading,
    IndicatorsComponent,
    CardTitle,
    KeyAreaFormComponent,
    KeyAreaDrawerComponent,
    SelectKeyAreasDialog,
    KeyAreaSwapComponent,
    DatePickerMenu,
    RevisionDateHistoryDrawer,
    SelectAssignmentDebouceComponent,
  },
})
export default class CreateDrdView extends Vue {
  data: PersistDrd = new PersistDrd();

  toolbar = ToolbarConfig;

  lastFilterKeyArea: string = '';

  loadingKeyArea: boolean = false;

  foundKeyAreas: KeyArea[] = [];

  keyAreaSelected: KeyArea | null = null;

  isMobile = false;

  keyAreaName: string = '';

  loading: boolean = false;

  loadingSave: boolean = false;

  tab: number = -1;

  isUpdated: boolean = !!this.$route.params.id;

  newKeyAreaMenu: boolean = false;

  keyAreaDrawer: boolean = false;

  isUpdateKeyArea: boolean = false;

  keyAreaUpdateId: string = '';

  openSelectKeyAreaDialog: boolean = false;

  keyAreaDrawerKey: number = 1;

  showUpdateObjective: boolean = false;

  showEditRevisionDate: boolean = false;

  showRevisionDateHistory: boolean = false;

  created() {
    this.$destroy();
    this.loading = true;
    if (this.$route.params.id) {
      this.getByAssignmentId();
      return;
    }
    this.loading = false;
  }

  selectedAssignment(assignment: Assignment) {
    this.data.assignmentId = assignment.id;
    this.data.objective = assignment.objective;
  }

  findKeyAreas(value: string) {
    if ((this.lastFilterKeyArea !== value) && value) {
      this.lastFilterKeyArea = value;
      if (value.length > 2) {
        this.loadingKeyArea = true;
        KeyAreaService.search(new KeyAreaFilter({ name: value }), 1, 100)
          .then((response) => {
            this.foundKeyAreas = response.data.content;
          }).finally(() => this.loadingKeyArea = false);
      }
    }
  }

  addKeyArea() {
    if (!this.keyAreaSelected) return;
    const alreadyAdd = this.data.keyAreas
      .filter(it => it.keyAreaId === this.keyAreaSelected!.id).length > 0;
    if (alreadyAdd) return;
    const greaterOrder = this.getGreaterOrder();
    this.data.keyAreas.push(new PersistAssignmentKeyArea({
      keyAreaId: this.keyAreaSelected.id,
      keyAreaName: this.keyAreaSelected.name,
      order: greaterOrder + 1,
      active: true,
      isDeletable: true,
    }));
  }

  getGreaterOrder() {
    if (this.data.keyAreas.length === 0) return 1;
    return this.data.keyAreas.reduce(
      (max, keyArea) => (keyArea.order > max ? keyArea.order : max),
      this.data.keyAreas[0].order,
    )
  }

  deleteKeyArea(keyAreaId: string) {
    const keyAreaIndex = this.data.keyAreas
      .findIndex(it => it.keyAreaId === keyAreaId);
    if (keyAreaIndex < 0) return;
    if (this.data.keyAreas[keyAreaIndex].isDeletable) {
      this.data.keyAreas.splice(keyAreaIndex, 1);
      this.keyAreaDrawerKey += 1;
      return;
    }
    this.data.keyAreas[keyAreaIndex].active = false;
  }

  onResize() {
    this.isMobile = MobileHandler.isMobile();
  }

  getByAssignmentId() {
    Drd20Service.getByAssignmentId(this.$route.params.id)
      .then((data) => {
        this.data = new PersistDrd({
          assignmentId: data.assignmentId,
          assignmentDescription: data.assignmentDescription,
          objective: data.assignmentObjective,
          keyAreas: data.keyAreas.map(it => new PersistAssignmentKeyArea({
            keyAreaId: it.id,
            keyAreaName: it.name,
            order: it.order,
            active: it.active,
            isDeletable: false,
          })),
          indicators: data.indicators,
          revisionDate: data.revisionDate,
          roleName: data.roleName,
        })
      }).finally(() => this.loading = false);
  }

  save() {
    if (this.$route.params.id) {
      this.update();
      return;
    }
    this.create();
  }

  create() {
    if (this.data.assignmentId) {
      this.loadingSave = true;
      Drd20Service.create(this.data)
        .then(() => {
          VbSnackbarService.showSuccess('DRD criada com sucesso!');
          this.redirectToList();
        }).catch((error) => {
          VbSnackbarService.showError(error.response.data.messages.toString());
        }).finally(() => this.loadingSave = false);
      return;
    }
    VbSnackbarService.showError('Papel é obrigatório');
  }

  update() {
    this.loadingSave = true;
    Drd20Service.update(this.data)
      .then(() => {
        this.getByAssignmentId();
        this.keyAreaDrawerKey += 1;
        VbSnackbarService.showSuccess('DRD atualizada com sucesso!');
      }).catch((error) => {
        VbSnackbarService.showError(error.response.data.messages.toString());
      }).finally(() => this.loadingSave = false);
  }

  redirectToList() {
    this.$router.push('/drd');
  }

  addNewIndicator(indicator: Indicators) {
    this.data.indicators.push(indicator);
  }

  editKeyArea(keyAreaUpdate: PersistAssignmentKeyArea) {
    this.keyAreaUpdateId = keyAreaUpdate.keyAreaId;
    this.isUpdateKeyArea = true;
    this.keyAreaDrawer = true;
  }

  createKeyArea() {
    this.keyAreaUpdateId = '';
    this.newKeyAreaMenu = false;
    this.isUpdateKeyArea = false;
    this.keyAreaDrawer = true;
  }

  keyAreaSaved(keyAreaSaved: KeyArea) {
    if (this.isUpdateKeyArea) {
      const keyAreaFinded = this.data.keyAreas.find(it => it.keyAreaId === keyAreaSaved.id);
      if (keyAreaFinded) {
        keyAreaFinded.keyAreaName = keyAreaSaved.name;
      }
      return;
    }
    this.data.keyAreas.push(PersistAssignmentKeyArea.of(keyAreaSaved));
    this.keyAreaDrawer = false;
  }

  handleKeyAreaSelected(keyAreaSelected: KeyArea, selected: boolean) {
    if (selected) {
      const order = this.data.keyAreas.length;
      this.data.keyAreas.push({
        keyAreaId: keyAreaSelected.id,
        keyAreaName: keyAreaSelected.name,
        order,
        active: true,
        isDeletable: true,
      })
      this.keyAreaDrawerKey += 1;
      return;
    }
    this.data.keyAreas = this.data.keyAreas
      .filter(it => it.keyAreaId !== keyAreaSelected.id);
    this.keyAreaDrawerKey += 1;
  }

  swapKeyArea(swapEventData: any) {
    swapEventData.forEach((it) => {
      const currentSwapIndex = this.data.keyAreas
        .findIndex(keyArea => keyArea.keyAreaId === it.item);
      const temp = this.data.keyAreas[Number(it.slot)];
      temp.order = currentSwapIndex;
      const itemSwap = this.data.keyAreas.find(keyArea => keyArea.keyAreaId === it.item)!;
      itemSwap.order = Number(it.slot);
      this.data.keyAreas[Number(it.slot)] = itemSwap;
      this.data.keyAreas[currentSwapIndex] = temp;
    })
  }

  deleteIndicator(index: number) {
    const indicatorDelete = this.data.indicators[index];
    if (indicatorDelete.saved) {
      indicatorDelete.active = false;
      return;
    }
    this.data.indicators.splice(index, 1);
  }

  updateRevisionDate(date: string) {
    Drd20Service.updateDRDRevisionDate(this.data.assignmentId, date)
      .then(() => {
        this.data.revisionDate = date;
        this.showEditRevisionDate = false;
        VbSnackbarService.showSuccess('Data de revisão atualizada com sucesso!');
      }).catch(VbSnackbarService.handleHttpError);
  }
}
