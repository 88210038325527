import axios from 'axios';
import Level from '../entity/level.entity';
import CreateKeyAreaDTO from '../entity/create-key-area.entity';
import KeyAreaFilter from '../entity/key-area-filter.entity';
// eslint-disable-next-line import/no-cycle
import KeyArea from '../entity/key-area.entity';

export default class KeyAreaService {
  public static async findAllLevels(): Promise<Level[]> {
    return axios.get('/api/keyarea/levels')
      .then(({ data }: any) => data);
  }

  public static async create(createKeyAreaDTO: CreateKeyAreaDTO): Promise<KeyArea> {
    return axios.post('/api/keyarea/', createKeyAreaDTO)
      .then(({ data }) => new KeyArea(data));
  }

  public static async update(id: string, dto: CreateKeyAreaDTO): Promise<KeyArea> {
    return axios.put(`/api/keyarea/${id}`, dto).then(({ data }) => new KeyArea(data));
  }

  public static async search(
    filter: KeyAreaFilter,
    page: number,
    size: number,
  ) {
    return axios.get('/api/keyarea', {
      params: {
        name: filter.hasName ? filter.name : null,
        skill: filter.skill ? filter.skill : null,
        page: page - 1,
        size,
      },
    });
  }

  public static async getById(id: string) {
    return axios.get(`/api/keyarea/${id}`);
  }

  public static async disable(id: string) {
    return axios.patch(`/api/keyarea/${id}`);
  }

  public static async updateLevel(level: Level) {
    return axios.put(`/api/keyarea/level/${level.id}`, { name: level.name });
  }

  public static async deleteLevel(id: string) {
    return axios.delete(`/api/keyarea/level/${id}`);
  }

  public static async createLevel(name: string) {
    return axios.post('/api/keyarea/level', { name });
  }

  public static async deleteIndicator(keyAreaId: string, indicatorId: string): Promise<void> {
    return axios.delete(`/api/keyarea/${keyAreaId}/indicators/${indicatorId}`);
  }

  public static async getResponsiblesOptions() {
    return axios.get('/api/keyarea/responsibility-options')
      .then(({ data }) => data);
  }
}
