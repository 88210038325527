import Quantities from './quantities.entity';

export default class CategoryQuantities {
  public juniorQuantity: Quantities;

  public plainQuantity: Quantities;

  public seniorQuantity: Quantities;

  constructor(obj: any = {}) {
    this.juniorQuantity = new Quantities(obj.juniorQuantity);
    this.plainQuantity = new Quantities(obj.plainQuantity);
    this.seniorQuantity = new Quantities(obj.seniorQuantity);
  }
}
