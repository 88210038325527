














































































































































import AreaKey from '@/domain/drd/entity/area-key/area-key.entity';
import DrdService from '@/domain/drd/service/drd.service';
import AddButton from '@/shared/buttons/add-button.component.vue';
import ScrollToTop from '@/shared/scroll-to-top.component.vue';
import Loader from '@/views/drd/commons/loader.component.vue';
import { VbSnackbarService } from 'bag-of-holding-library';
import AreaKeyItem from '@/domain/drd/entity/area-key/area-key-item.entity';
import PerformanceDescription from '@/domain/drd/entity/performance-description.entity';
import { Component, Prop, Vue } from 'vue-property-decorator';
import AreaKeyComponent from './area-key.component.vue';
import ItemComponent from '../common-components/item.component.vue';
import StatusTabComponent from '../common-components/status-tab.component.vue';

@Component({
  components: {
    AddButton,
    AreaKeyComponent,
    Loader,
    ScrollToTop,
    ItemComponent,
    StatusTabComponent,
  },
})
export default class AreaKeyIndex extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  performanceDescription!: PerformanceDescription;

  @Prop({
    type: Boolean,
    default: false,
  }) hasDrdApproveProccess!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) showApproveAndRejectBtns!: boolean;

  loading: boolean = true;

  areaKeys: AreaKey[] = [];

  tab: number = 0;

  addNewAreaKey(): void {
    this.areaKeys.push(AreaKey.ofNew());
    this.tab = 1;
  }

  updateField(field: string, value: any, index: number): void {
    this.areaKeys[index][field] = value;
  }

  updateItemField(field: string, item: any, index: number): void {
    this.areaKeys[index].items[item.index][field] = item.value;
  }

  pushAreaKeyItem(areaKey: AreaKey): void {
    areaKey.pushNewItem()
  }

  deleteAreaKey(areaKey: AreaKey): void {
    if (areaKey.isNew) {
      this.areaKeys = this.areaKeys.filter(it => it.idTemporary !== areaKey.idTemporary);
      return;
    }
    const index = this.areaKeys.findIndex(it => it.id === areaKey.id);
    this.areaKeys[index].active = false;
  }

  deleteAreaKeyItem(areaKey: AreaKey, areaKeyItem: AreaKeyItem): void {
    if (areaKeyItem.isNew) {
      areaKey.items = areaKey.items.filter(it => it.idTemporary !== areaKeyItem.idTemporary);
      return;
    }
    const index = areaKey.items.findIndex(it => it.id === areaKeyItem.id);
    areaKey.items[index].active = false;
  }

  save(): Promise<void> {
    const { form }: any = this.$refs;
    return new Promise((resolve, reject) => {
      if (!form.validate()) {
        reject();
        return;
      }
      DrdService.updateAreaKeys(this.performanceDescription.id, this.areaKeys)
        .then(({ data }) => {
          VbSnackbarService.showSuccess('Áreas chave salvas com sucesso!');
          this.areaKeys = data.map(it => new AreaKey(it));
          resolve();
        }).catch((err) => {
          VbSnackbarService.handleHttpError(err);
          reject();
        });
    });
  }

  created(): void {
    this.loadAreaKeys();
  }

  loadAreaKeys(): void {
    this.loading = true;
    DrdService.getAreaKeys(this.performanceDescription.id).then((data) => {
      this.areaKeys = data.map((it: any) => new AreaKey(it));
    }).catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loading = false;
      });
  }

  async approvedAreaKey(areaKey: AreaKey) {
    if (areaKey.isApproved !== null && areaKey.isApproved) {
      return;
    }
    areaKey.loadingApprove = true;
    this.updateIsApprovedAreaKey(areaKey, true)
      .finally(() => {
        areaKey.loadingApprove = false;
      });
  }

  async rejectedAreaKey(areaKey: AreaKey) {
    if (areaKey.isApproved !== null && !areaKey.isApproved) {
      return;
    }
    areaKey.loadingReject = true;
    this.updateIsApprovedAreaKey(areaKey, false)
      .finally(() => {
        areaKey.loadingReject = false;
      });
  }

  fetchObservation(areaKey: AreaKey): void {
    areaKey.loadingObservation = true;
    DrdService.updateAreaKeyObservation(areaKey.id, areaKey.observation)
      .catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        areaKey.loadingObservation = false;
      });
  }

  async updateIsApprovedAreaKey(areaKey: AreaKey, value: boolean): Promise<void> {
    return DrdService.approveOrRejectAreaKey(areaKey.id, value)
      .then(() => {
        areaKey.isApproved = value;
      })
      .catch(VbSnackbarService.handleHttpError);
  }

  get approvedAndPendingAreaKeys() {
    return this.areaKeys.filter(it => it.isApproved === true && it.active);
  }

  get rejectedAreaKeys() {
    return this.areaKeys.filter(it => it.isApproved === false && it.active);
  }

  get penddingAreaKeys() {
    return this.areaKeys.filter(it => it.isApproved === null && it.active);
  }
}
