import { ResponsibilityText } from '@/views/drd-2.0/responsibility/responsibility-form.component.vue';
import KeyAreaResponsibilityLevel from './key-area-responsibility-level.entity';

export default class KeyAreaResponsibility {
  id: string;

  levels: KeyAreaResponsibilityLevel[];

  descriptionPTBR: string;

  descriptionEN: string;

  descriptionES: string;

  children: KeyAreaResponsibility[];

  isRoot: boolean;

  active: boolean;

  isNew: boolean = false;

  constructor(obj: any) {
    this.id = obj.id || '';
    this.levels = obj.levels
      ? obj.levels.map((level: any) => new KeyAreaResponsibilityLevel(level)) : [];
    this.descriptionPTBR = obj.descriptionPTBR || '';
    this.descriptionEN = obj.descriptionEN || '';
    this.descriptionES = obj.descriptionES || '';
    this.children = obj.children ? obj.children
      .map((child: any) => new KeyAreaResponsibility(child)) : [];
    this.isRoot = obj.isRoot;
    this.active = obj.active;
    this.isNew = obj.isNew || false;
  }

  toRequest() {
    return {
      id: this.isNew ? null : this.id,
      levels: this.levels,
      descriptionPTBR: this.descriptionPTBR,
      descriptionEN: this.descriptionEN,
      descriptionES: this.descriptionES,
      children: this.children.map(child => child.toRequest()),
      active: this.active,
    }
  }

  public updateDescription(newDescriptions: ResponsibilityText) {
    this.descriptionPTBR = newDescriptions.portuguese;
    this.descriptionEN = newDescriptions.english;
    this.descriptionES = newDescriptions.espanish
  }

  public addChildren(childrenData: ResponsibilityText) {
    this.children.push(new KeyAreaResponsibility({
      id: new Date().getTime().toString(),
      levels: [],
      descriptionPTBR: childrenData.portuguese,
      descriptionEN: childrenData.english,
      descriptionES: childrenData.espanish,
      children: [],
      isRoot: false,
      active: true,
      isNew: true,
    }))
  }

  public inactivate() {
    this.active = false;
    this.children.forEach(child => child.inactivate());
  }
}
