
































import { Vue, Component } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import ManagerService from '@/domain/employees/service/employee.service';
import FilteredSeniorityRemumeration from '@/domain/seniority-remuneration/entity/filtered-seniority-remuneration.entity';
import Filter from '@/domain/seniority-remuneration/entity/filter.entity';
import SeniorityRemunerationService from '@/domain/seniority-remuneration/services/seniority-remuneration.service';
import Employee from '@/domain/employees/entity/employee.entity';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import AuthorityStore from '@/domain/security/authority.store';
import { VbSnackbarService } from 'bag-of-holding-library';
import TableComponent from './seniority-remuneration-table.component.vue';
import FilterComponent from './filter/filter.component.vue';
import EmployeeSummary from '../../../domain/schedule/employee/employee-summary.entity';
import CareerService from '../../../domain/employees/service/career.service';
import UpdateAtribute from '../../../domain/employees/entity/update-atribute.entity';
import HeaderComponent from './seniority-remuneration-table-header.component.vue';

@Component({
  components: {
    FilterComponent, CardTitle, TableComponent, HeaderComponent,
  },
})
export default class seniorityRemunerations extends Vue {
  filteredRemuneration: FilteredSeniorityRemumeration[] = [];

  employees: Employee[] = [];

  businessUnit: UpdateAtribute[] = [];

  roles: UpdateAtribute[] = [];

  filterEmployees: EmployeeSummary[] = [];

  lastFilterManager: string = '';

  lastFilterEmployee: string = '';

  tableLoading: boolean = false;

  lastFilter: Filter = new Filter({});

  seniorities: any[] = [];

  resultCenters: any[] = [];

  isAdministrator: boolean = false;

  sortBy: Array<string> = ['name'];

  sortDesc: boolean[] = [false];

  isManager: boolean = false;

  managerName: string = '';

  applyInitialFilter() {
    if (this.isManager) {
      this.searchManagerName();
    }
  }

  searchManagerName() {
    if (this.isManager) {
      this.managerName = this.$store.state.name;
      return this.findManagersAndSet(this.managerName);
    }
    return '';
  }

  getCheckAuthorities() {
    AuthorityStore.hasAccessWages()
      .then((response) => {
        if (response) {
          this.findSeniorityRemuneration();
        }
      });
    this.checkIsManger();
  }

  checkIsManger() {
    AuthorityStore.isManager()
      .then((response) => {
        this.isManager = response;
      })
  }

  pagination: any = {
    itemsPerPage: 10,
    page: 1,
    sortBy: this.sortBy,
    sortDesc: this.sortDesc,
    total: 0,
    pageCount: 0,
  }

  filter: Filter = Filter.of({});

  managers: EmployeeSummary[] = [];

  setFilterValues(value: any) {
    if (this.pagination.itemsPerPage < value.itemsPerPage) {
      value.page = 1;
    }
    this.pagination = value;
    this.findSeniorityRemuneration();
  }

  created() {
    this.$destroy();
    CareerService.getRoles().then((resp: any) => this.roles = resp.data);
    CareerService.getBusinessUnit().then(resp => this.businessUnit = resp);
    this.getAllSeniorities();
    this.getCheckAuthorities();
    this.setInitialFilter();
  }

  setInitialFilter() {
    const { employeeId } = this.$route.query;
    if (employeeId) {
      this.filter.employeeId = employeeId.toString();
    }
  }

  findSeniorityRemuneration() {
    this.filter.prepareToSend();
    this.tableLoading = true;
    SeniorityRemunerationService.findEmployeeRemuneration(
      this.pagination.page, this.pagination.itemsPerPage, this.filter,
    )
      .then((response: any) => {
        this.filteredRemuneration = FilteredSeniorityRemumeration.ofList(response.content);
        this.pagination.total = response.totalElements || 0;
        this.pagination.pageCount = response.totalPages;
      }).catch((error) => {
        VbSnackbarService.handleHttpError(error);
      })
      .finally(() => this.tableLoading = false);
  }

  findManagers(value: string) {
    if ((this.lastFilterManager !== value) && value) {
      this.lastFilterManager = value;
      if (value.length > 2) {
        sessionStorage.setItem('managerStore', value)
        ManagerService.findManagersByName(value)
          .then((resp: EmployeeSummary[]) => {
            this.managers = resp;
          });
      }
    }
  }

  findManagersAndSet(value: string) {
    if ((this.lastFilterManager !== value) && value) {
      this.lastFilterManager = value;
      if (value.length > 2) {
        sessionStorage.setItem('managerStore', value)
        ManagerService.findManagersByName(value)
          .then((resp: EmployeeSummary[]) => {
            this.managers = resp;
            this.filter.managerId = this.managers[0].id;
            this.findSeniorityRemuneration();
          });
      }
    }
  }

  findEmployeesByName(value: string) {
    if ((this.lastFilterEmployee !== value) && value) {
      this.lastFilterEmployee = value;
      if (value.length > 2) {
        sessionStorage.setItem('employeeStore', value)
        EmployeeService.findEmployeesByName(value)
          .then((resp: EmployeeSummary[]) => this.filterEmployees = resp);
      }
    }
  }

  getAllSeniorities() {
    CareerService.getSeniority()
      .then((resp: any) => this.seniorities = resp.data);
  }

  getResultCenter() {
    CareerService.getResultCenter(this.filter.businessUnitId)
      .then((resp: any) => this.resultCenters = resp)
  }
}
