import store from '@/store';
import axios from 'axios';
import moment from 'moment';

const TOKEN_KEY = 'people_access_token';
const REFRESH_TOKEN_KEY = 'people_refresh_token';
const EXPIRES_AT_KEY = 'people_expires_at';
const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';

const formatExpireAt = (expiresIn: number): string => moment().add(expiresIn, 'seconds')
  .subtract(60, 'seconds')
  .format(DATETIME_FORMAT);

const saveTokenInLocalStorage = async (
  token: string,
  refreshToken: string,
  expiresIn: number,
) => {
  const expireAt = formatExpireAt(expiresIn);
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  localStorage.setItem(EXPIRES_AT_KEY, expireAt);
}

const exchangeCodeForToken = async (code: string) => {
  const response = await axios.post('/api/login', null, {
    params: {
      code,
    },
  });
  return response;
}

const refreshTokenRequest = async (token: string) => {
  const response = await axios.post('/api/refresh-token', token);
  return response;
}

const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(EXPIRES_AT_KEY);
}

const getToken = () => localStorage.getItem(TOKEN_KEY)

const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY)

const getExpiresAt = () => {
  const expiresAt = localStorage.getItem(EXPIRES_AT_KEY);
  return moment(expiresAt, DATETIME_FORMAT);
}

const isAuthenticated = (): boolean => {
  const token = getToken();
  const expiresAt = getExpiresAt();
  const tokenExpiration = moment().isAfter(expiresAt);
  return !!token && !tokenExpiration;
}

const getUserPhotoByEmail = async (email: string) => {
  const response = await axios.get(`/graph-api/users/${email}/photo/$value`, {
    responseType: 'blob',
  });
  return response.data;
}

const getUserLoggedInPhoto = async () => {
  const response = await getUserPhotoByEmail(store.state.email!);
  return response;
}

export {
  exchangeCodeForToken,
  refreshTokenRequest,
  logout,
  getToken,
  getRefreshToken,
  getExpiresAt,
  isAuthenticated,
  saveTokenInLocalStorage,
  getUserPhotoByEmail,
  getUserLoggedInPhoto,
}
