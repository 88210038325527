





























































































































































































































































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue'
import CardTitle from '@/shared/card/card-title.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import splitContent from '@/shared/directive/split-content';
import DateHandler from '@/shared/utils/date-format';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import Rules from '@/shared/rules/rules';
import TooltipIcon from '@/shared/tooltip-icon.component.vue';
import ScoreDependentBenefits from '@/domain/employees/entity/score-dependent-benefits';
import BooleanValuesOption from './boolean-values-option';
import healthPlanType from './health-plan-type.json';

@Component({
  components: {
    LabelSlot,
    DatePickerField,
    CardTitle,
    ModalWarning,
    TooltipIcon,
  },
  filters: {
    splitContent,
    formatDate(value: string) {
      return DateHandler.timestampToLocal(value.split('T')[0]);
    },
  },
})
export default class DependentBenefitsListComponent extends Vue {
  @Prop() dependentBenefitList!: ScoreDependentBenefits[]

  rules: Rules = new Rules();

  booleanValuesOption = BooleanValuesOption

  type = healthPlanType;

  relationship = ['Filho(a)', 'Pai', 'Mãe', 'Cônjuge', 'Outros']

  addDependents: boolean | null = null;

  addDependent() {
    this.$emit('addDependent')
  }

  removeDependent(item: ScoreDependentBenefits) {
    this.$emit('removeDependent', item)
  }

  verifyHealthPlanDependentIsFalse(dependentBenefit: ScoreDependentBenefits) {
    if (!dependentBenefit.healthPlanDependent) {
      dependentBenefit.healthPlanDependentPayedByDB1 = false;
    }
  }

  verifyDentalPlanDependentIsFalse(dependentBenefit: ScoreDependentBenefits) {
    if (!dependentBenefit.dentalPlanDependent) {
      dependentBenefit.dentalPlanDependentPayedByDB1 = false;
    }
  }

  verifyHealthPlanDependentPayedDB1IsFalse(dependentBenefit: ScoreDependentBenefits) {
    if (!dependentBenefit.healthPlanDependentPayedByDB1) {
      dependentBenefit.type = 'REGION_INFIRMARY';
    }
  }

  applyBirthDate(value: string, item: ScoreDependentBenefits) {
    item.birthDate = value;
  }

  clearBirthDate(item: ScoreDependentBenefits) {
    item.birthDate = '';
  }
}
