var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"agreements-table",attrs:{"items":_vm.agreements,"headers":_vm.headers,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.previousAgreement",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.isTotal ? 'font-weight-bold' : ''},[_vm._v(" "+_vm._s(_vm.formatToCurrencyIfNumericField(item.previousAgreement, item.numericField))+" ")])]}},{key:"item.newAgreement",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.isTotal ? 'font-weight-bold' : ''},[_vm._v(" "+_vm._s(_vm.formatToCurrencyIfNumericField(item.newAgreement, item.numericField))+" ")])]}},{key:"item.readjustment",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.isTotal ? 'font-weight-bold' : ''},[_vm._v(" "+_vm._s(_vm.formatToPercent(item))+" ")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.isTotal ? 'font-weight-bold' : ''},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.validUntil",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.isTotal ? 'font-weight-bold' : ''},[_vm._v(" "+_vm._s(item.validUntil ? _vm.formatDate(item.validUntil) : '-')+" ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }