








































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import PerformanceDescription from '@/domain/drd/entity/performance-description.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import {
  hasEmployeeAdministratorAuthority,
  hasManagerAuthority,
  hasAdministratorAuthority,
  hasFacilitiesOrEmployeeAdministratorAuthority,
} from '@/shared/directive/has-authority.directive';
import { VbSnackbarService, VbTooltipSlot } from 'bag-of-holding-library';
import DrdService from '@/domain/drd/service/drd.service';
import { PerformanceDescriptionStatus } from '@/domain/drd/entity/performance-description-status.enum';
import CopyClipboardService from '@/shared/utils/copy-clipboard.service';
import DrdReviewCheckboxComponent from '@/shared/checkbox/drd-review-checkbox.component.vue';
import Loading from '@/shared/loading/loading.component.vue';
import { StatusLabel } from '../commons/status-label';

@Component({
  components: {
    CardTitle,
    VbTooltipSlot,
    LabelSlot,
    DrdReviewCheckboxComponent,
    Loading,
  },
  directives: {
    hasEmployeeAdministratorAuthority,
    hasManagerAuthority,
    hasAdministratorAuthority,
    hasFacilitiesOrEmployeeAdministratorAuthority,
  },
})
export default class PerformanceDescriptionHeader extends Vue {
  @Prop() performanceDescription!: PerformanceDescription;

  @Prop({
    type: Boolean,
    default: false,
  }) hasDrdApproveProccess!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) showApproveAndRejectBtns!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) hasUpdateDrd!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) isRead!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) existsReviewOpen!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) showUpdateObjectiveBtn!: boolean;

  statusLabel = StatusLabel;

  loadingApprove: boolean = false;

  loadingReject: boolean = false;

  loadingSendToApprove: boolean = false;

  editObjective: boolean = false;

  loadingUpdateObjective: boolean = false;

  approve(): void {
    this.loadingApprove = true;
    this.updateDrdStatus(PerformanceDescriptionStatus.APPROVED)
      .then(() => {
        VbSnackbarService.showSuccess('DRD aprovado com sucesso');
      })
      .finally(() => {
        this.loadingApprove = false;
      });
  }

  reject(): void {
    this.loadingReject = true;
    this.updateDrdStatus(PerformanceDescriptionStatus.REJECTED)
      .then(() => {
        VbSnackbarService.showSuccess('DRD recusado com sucesso');
      })
      .finally(() => {
        this.loadingReject = false;
      });
  }

  sendToApprove(): void {
    this.loadingSendToApprove = true;
    this.updateDrdStatus(PerformanceDescriptionStatus.PENDING_APPROVAL)
      .then(() => {
        VbSnackbarService.showSuccess('DRD enviado para aprovação com sucesso');
      })
      .finally(() => {
        this.loadingSendToApprove = false;
      });
  }

  async updateDrdStatus(status: string): Promise<void> {
    return DrdService.updateDrdStatus(this.performanceDescription.id, status)
      .then(() => {
        this.$emit('updateDrdStatus', status);
      })
      .catch((error) => {
        VbSnackbarService.handleHttpError(error);
        throw error;
      });
  }

  back() {
    const isDirectAccess = window.history.length === 1;
    if (isDirectAccess) {
      this.$router.push('/performance-description-list');
      return;
    }
    this.$router.go(-1);
  }

  copyToClipboard(text: string): void {
    CopyClipboardService.copyToClipboard(text);
  }

  updateObjective() {
    this.loadingUpdateObjective = true;
    DrdService.updateObjective(
      this.performanceDescription.id,
      this.performanceDescription.objective,
    )
      .then(() => {
        this.editObjective = false;
        VbSnackbarService.showSuccess('Objetivo atualizado com sucesso');
      })
      .catch((error) => {
        VbSnackbarService.handleHttpError(error);
      })
      .finally(() => {
        this.loadingUpdateObjective = false;
      });
  }
}
