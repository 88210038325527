export default class EmployeeRevenue {
  baseSalaryEmployee: number;

  baseSalaryDB1: number;

  vlDuczFoodEmployee: number;

  vlDuczFoodDB1: number;

  vlSintinorpAllowanceEmployee: number;

  vlSintinorpAllowanceDB1: number;

  vlSubsidyEmployee: number;

  vlSubsidyDB1: number;

  vlHealthPlanEmployee: number;

  vlHealthPlanDB1: number;

  vlDentalPlanEmployee: number;

  vlDentalPlanDB1: number;

  vlInssEmployee: number;

  vlInssDB1: number;

  vl13thSalaryEmployee: number;

  vl13thSalaryDB1: number;

  vlLifeInsuranceEmployee: number;

  vlLifeInsuranceDB1: number;

  vlDaycareEmployee: number;

  vlDaycareDB1: number;

  vlVacationEmployee: number;

  vlVacationDB1: number;

  vlGympassEmployee: number;

  vlGympassDB1: number;

  vlEducationalPassEmployee: number;

  vlEducationalPassDB1: number;

  vlTrainingsEmployee: number;

  vlTrainingsDB1: number;

  vlFgtsEmployee: number;

  vlFgtsDB1: number;

  vlRvEmployee: number;

  vlRvDB1: number;

  vlIRRFEmployee: number;

  vlIRRFDB1: number;

  year: number;

  series: any = [];

  constructor(obj: any = {}) {
    this.baseSalaryEmployee = 0;
    this.baseSalaryDB1 = obj.baseSalary || 0;
    this.vlDuczFoodEmployee = obj.vlDuczFoodEmployee || 0;
    this.vlDuczFoodDB1 = obj.vlDuczFoodDB1 || 0;
    this.vlSintinorpAllowanceEmployee = obj.vlSintinorpAllowanceEmployee || 0;
    this.vlSintinorpAllowanceDB1 = obj.vlSintinorpAllowanceDB1 || 0;
    this.vlSubsidyEmployee = obj.vlSubsidyEmployee || 0;
    this.vlSubsidyDB1 = obj.vlSubsidyDB1 || 0;
    this.vlHealthPlanEmployee = obj.vlHealthPlanEmployee || 0;
    this.vlHealthPlanDB1 = obj.vlHealthPlanDB1 || 0;
    this.vlDentalPlanEmployee = obj.vlDentalPlanEmployee || 0;
    this.vlDentalPlanDB1 = obj.vlDentalPlanDB1 || 0;
    this.vlInssEmployee = obj.vlInssEmployee || 0;
    this.vlInssDB1 = obj.vlInssDB1 || 0;
    this.vl13thSalaryEmployee = obj.vl13thSalaryEmployee || 0;
    this.vl13thSalaryDB1 = obj.vl13thSalaryDB1 || 0;
    this.vlLifeInsuranceEmployee = obj.vlLifeInsuranceEmployee || 0;
    this.vlLifeInsuranceDB1 = obj.vlLifeInsuranceDB1 || 0;
    this.vlDaycareEmployee = obj.vlDaycareEmployee || 0;
    this.vlDaycareDB1 = obj.vlDaycareDB1 || 0;
    this.vlVacationEmployee = obj.vlVacationEmployee || 0;
    this.vlVacationDB1 = obj.vlVacationDB1 || 0;
    this.vlGympassEmployee = obj.vlGympassEmployee || 0;
    this.vlGympassDB1 = obj.vlGympassDB1 || 0;
    this.vlEducationalPassEmployee = obj.vlEducationalPassEmployee || 0;
    this.vlEducationalPassDB1 = obj.vlEducationalPassDB1 || 0;
    this.vlTrainingsEmployee = obj.vlTrainingsEmployee || 0;
    this.vlTrainingsDB1 = obj.vlTrainingsDB1 || 0;
    this.vlFgtsEmployee = obj.vlFgtsEmployee || 0;
    this.vlFgtsDB1 = obj.vlFgtsDB1 || 0;
    this.vlRvEmployee = obj.vlRvEmployee || 0;
    this.vlRvDB1 = obj.vlRvDB1 || 0;
    this.vlIRRFEmployee = obj.vlIRRFEmployee || 0;
    this.vlIRRFDB1 = obj.vlIRRFDB1 || 0;
    this.year = obj.year;
    this.setSeries();
  }

  setSeries() {
    this.series = [
      {
        name: 'Seu Rendimento Anual',
        data: [this.totalEmployee, 0],
      },
      {
        name: 'Seus benefícios anuais pagos pela empresa',
        data: [this.totalBenefitDB1, 0],
      },
      {
        name: 'Investimento anual total da empresa para que você tenha o seu rendimento',
        data: [0, this.totalDB1],
      },
    ]
  }

  calculateBySuffix(suffix: string) {
    return Object.keys(this).reduce((total, key) => (
      key.endsWith(suffix) ? total += this[key] : total
    ), 0)
  }

  get totalByEmployee() {
    return this.calculateBySuffix('Employee');
  }

  get totalByDB1() {
    return this.calculateBySuffix('DB1');
  }

  get totalEmployee(): number {
    return (this.baseSalaryDB1
     + this.vlRvDB1
     + this.vlDuczFoodDB1
     + this.vlSintinorpAllowanceDB1
     + this.vlSubsidyDB1
     + this.vl13thSalaryDB1
     + this.vlDaycareDB1
     + this.vlVacationDB1)
     - this.vlInssEmployee
     - this.vlIRRFEmployee
  }

  get totalBenefitDB1(): number {
    return this.vlHealthPlanDB1
    + this.vlDentalPlanDB1
    + this.vlGympassDB1
    + this.vlEducationalPassDB1
    + this.vlTrainingsDB1
    + this.vlLifeInsuranceDB1
  }

  get totalDB1(): number {
    return this.totalEmployee + this.totalBenefitDB1 + this.vlFgtsDB1
    + this.vlInssEmployee + this.vlInssDB1
  }
}
