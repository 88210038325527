export default class RescissionEvidence {
  id: string;

  createdDate: string;

  createdBy: string;

  area: string;

  loadingDownload: boolean = false;

  attachmentName: string;

  observation: string;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.createdDate = obj.createdDate || '';
    this.createdBy = obj.createdBy || '';
    this.area = obj.area || '';
    this.attachmentName = obj.attachmentName || '';
    this.observation = obj.observation || '';
  }
}
