



















































































































import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue'
import CardTitle from '@/shared/card/card-title.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import splitContent from '@/shared/directive/split-content';
import DateHandler from '@/shared/utils/date-format';
import RiskAttendance from '@/domain/risk-attendance/entity/risk-attendance.entity';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import Rules from '@/shared/rules/rules';
import RequestReasonJson from '@/views/attendance/table/modal/requestReason.json';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import EmployeeAttendanceDTO from '@/domain/attendance/entity/employee-attendance-dto';
import StatusRiskAttendance from './status.json'
import RiskAttendanceTypes from '../risk-attendance-type.json';

@Component({
  components: {
    LabelSlot, DatePickerField, CardTitle,
  },
  filters: {
    splitContent,
    formatDate(value: string) {
      return DateHandler.timestampToLocal(value.split('T')[0]);
    },
  },
})
export default class FormRiskAttendanceComponent extends Vue {
  @Prop() riskAttendance!: RiskAttendance;

  @Prop() hasUpdateRiskAttendance!: boolean;

  riskAttendanceAux: RiskAttendance = RiskAttendance.of({});

  valid: boolean = false;

  private loading = false;

  rules: Rules = new Rules();

  responsibles: EmployeeSummary[] = [];

  managerName: string = '';

  lastEmployeeFilter: string = '';

  private status = StatusRiskAttendance;

  private requestReason = RequestReasonJson;

  private riskAttendanceTypes = RiskAttendanceTypes;

  @Watch('riskAttendance')
  initResponsible() {
    if (this.riskAttendance.responsible.name === '') {
      this.searchResponsibleName();
    }
  }

  @Watch('valid')
  emitValid() {
    this.$emit('isValid', this.valid);
  }

  searchResponsibleName() {
    const responsibleName = this.$store.state.name;
    EmployeeService.findEmployeesByName(responsibleName)
      .then((res) => {
        this.responsibles = res;
        const responsible = res.find(el => el.name.toLowerCase() === responsibleName.toLowerCase());
        if (responsible) {
          this.riskAttendance.responsible = EmployeeAttendanceDTO.of(responsible);
        }
      })
  }

  statusIsDisabled(status: any): boolean {
    return !status.active
  }
}

