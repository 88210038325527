







































































import PersistAssignmentKeyArea from '@/domain/drd-2.0/entity/persist-assignment-keyarea.entity';
import ModalOptions from '@/shared/modal/modal-options.entity';
import { Vue, Component, Prop } from 'vue-property-decorator';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import draggable from 'vuedraggable'

@Component({
  components: {
    ModalWarning,
    draggable,
  },
})
export default class KeyAreaSwapComponent extends Vue {
  @Prop() keyAreas!: PersistAssignmentKeyArea[];

  showDeleteModalWarning: boolean = false;

  keyAreaToDeleteId: string | null = null;

  get dragOptions() {
    return {
      animation: 200,
      disabled: false,
      ghostClass: 'ghost',
    };
  }

  deleteModalWarningOptions: ModalOptions = {
    title: 'Atenção',
    description: 'Tem certeza que deseja excluir este item?',
    labelCancel: 'Cancelar',
    labelOk: 'Excluir',
    okColor: 'error',
    showCancel: true,
    showOk: true,
    width: 400,
  }

  openModalWarning(keyAreaToDeleteId: string) {
    this.keyAreaToDeleteId = keyAreaToDeleteId;
    this.showDeleteModalWarning = true;
  }
}
