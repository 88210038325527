import DateFormat from '@/shared/utils/date-format';
import EmployeeHappiness from './employee-happiness.entity';

export enum ActivityStatus {
  APPROVED = 'Aprovada',
  REFUSED = 'Recusada',
  LATE = 'Atrasada',
  PENDING = 'Pendente'
}

export enum ActivityType {
  ENTRANCE_PDI = 'PDI de Entrada',
  FEED_45 = 'Feed 45',
  ACTUALIZATION_FEED = 'Feedback Efetivação',
  COMPETENCE_EVALUATION = 'ADM',
  BUILD = 'Contagem de Pontos',
  PDI_REVIEW = 'Revisão de PDI',
  EXTRA_BUILD = 'Acordo Salarial Avulso',
}

export default class ActivityHappiness {
  id: string;

  name: string;

  expectedDate: string;

  employee: EmployeeHappiness;

  status: string;

  activityType: string;

  defaultDate: string;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.name = obj.name || '';
    this.expectedDate = obj.expectedDate || '';
    this.employee = obj.employee ? new EmployeeHappiness(obj.employee) : new EmployeeHappiness();
    this.status = obj.status || '';
    this.activityType = obj.activityType || '';
    this.defaultDate = obj.defaultDate || '';
  }

  public static of(obj: any) {
    return new ActivityHappiness(obj);
  }

  buildActivityTitle(): string {
    return `Nos diga como se sentiu na realização do processo <strong>"${this.name}"</strong>
      realizado no mês <strong>"${DateFormat.monthNumberYear(this.expectedDate)}"</strong>`;
  }

  public isApproved(): boolean {
    return this.status === ActivityStatus.APPROVED;
  }

  public isADM(): boolean {
    return this.activityType === 'COMPETENCE_EVALUATION'
  }
}
