import moment from 'moment';

export default class RevisionDateHistory {
  revisionDate: string;

  createdDate: string;

  createdBy: string;

  constructor(obj: any = {}) {
    this.revisionDate = obj.revisionDate || '';
    this.createdDate = obj.createdDate || '';
    this.createdBy = obj.createdBy || '';
  }

  get revisionDateFormat() {
    return this.revisionDate ? moment(this.revisionDate).format('DD/MM/YYYY') : '';
  }

  get createdDateFormat() {
    return this.createdDate ? moment(this.createdDate).format('DD/MM/YYYY HH:mm') : '';
  }
}
