import axios from 'axios';
import moment from 'moment';
import router from '@/router/index';
import {
  refreshTokenRequest,
} from '@/domain/security/auth.service';
import store, { DATETIME_FORMAT } from '@/store';

const publicRouters = ['/api/login', '/public/', '/api/refresh-token'];

const setRedirectAfterLogin = () => {
  const currentPath = router.currentRoute.path;
  const isNonRedirectableRouteAfterLogin = currentPath.includes('/login') || currentPath.includes('/maintenance');
  if (isNonRedirectableRouteAfterLogin) return;
  localStorage.setItem('redirectAfterLogin', currentPath);
}

const handleToLoginPage = () => {
  setRedirectAfterLogin();
  store.dispatch('logout');
  router.push('/login');
  throw new axios.Cancel('Operation canceled because session expired.');
}

const handleRefreshToken = async () => {
  try {
    const { refreshToken } = store.state;
    if (!refreshToken) {
      router.push('/login');
    }
    const response = await refreshTokenRequest(refreshToken!);
    const { data } = response
    store.dispatch('updateToken', {
      token: data.access_token,
      refreshToken: data.refresh_token,
      expirationAt: data.expires_in,
    })
    return data.access_token;
  } catch (error) {
    handleToLoginPage();
    return null;
  }
}

const getAccessToken = async () => {
  const userLocalStorage = localStorage.getItem('user');
  const userData = JSON.parse(userLocalStorage || '{}');
  let { token } = userData;
  if (!token) {
    handleToLoginPage();
  }
  const expiresAt = moment(store.state.expirationAt, DATETIME_FORMAT);
  const tokenExpiration = moment().isAfter(expiresAt);
  if (tokenExpiration) {
    token = await handleRefreshToken();
    return token;
  }
  return token;
}

const requestInterceptor = async (config: any) => {
  const newConfig: any = {};
  Object.assign(newConfig, config);
  const isPublicRouter = publicRouters.some(publicRouter => config.url.includes(publicRouter));
  if (!isPublicRouter) {
    const token: any = await getAccessToken();
    newConfig.headers.Authorization = `Bearer ${token}`;
    return newConfig;
  }
  return newConfig;
}

export default {
  install: () => {
    axios.interceptors.request.use(requestInterceptor);
  },
}
