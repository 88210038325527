

























































import { Vue, Component, Watch } from 'vue-property-decorator';
import { VbNavBar, VbLoadingComponent, VbSnackbarComponent } from 'bag-of-holding-library';
import MenuHeader from '@/shared/menu-header/menu-header.component.vue';
import Card from '@/shared/card/card.component.vue';
import MobileHandler from '@/shared/utils/mobile-handler';
import ReleaseService from '@/domain/release/service/release.service';
import NavbarButtons from './nav-buttons.json';
import AuthorityStore from './domain/security/authority.store';
import MenuSidebar from './shared/main-menu/menu-sidebar.component.vue';
import MenuTopbar from './shared/main-menu/menu-topbar.component.vue';
import Release from './domain/release/entity/release.entity';
import ReleaseComponent from './shared/release/release-component.vue';
import LoginComponent from './views/login/login.component.vue';

@Component({
  components: {
    MenuHeader,
    Card,
    VbNavBar,
    VbLoadingComponent,
    VbSnackbarComponent,
    MenuSidebar,
    MenuTopbar,
    ReleaseComponent,
    LoginComponent,
  },
})
export default class App extends Vue {
  isMobile: boolean = false;

  loadingAuthorities: boolean = true;

  authorities: any = [];

  navButtons = NavbarButtons;

  buttons: any = [];

  showRelease: boolean = false;

  release: Release = new Release();

  showCountdown: boolean = false;

  isAuth: boolean = false;

  closeRelease() {
    this.showRelease = false;
  }

  async created() {
    if (this.$route.name === 'Formulario') {
      return;
    }
    if (this.isAuthenticated) {
      this.getAuthorities();
      this.getRelease();
    }
  }

  get isAuthenticated() {
    return this.$store.getters.isAuthenticated;
  }

  @Watch('isAuthenticated')
  onIsAuthenticatedChange(isAuth: boolean) {
    if (isAuth) {
      this.getAuthorities()
      this.getRelease();
    }
  }

  get isHome(): boolean {
    return this.$route.name === 'Home';
  }

  onResize() {
    this.isMobile = MobileHandler.isMobile();
  }

  getAuthorities() {
    this.loadingAuthorities = true;
    return AuthorityStore.fetchedAuthority()
      .then((resp: any) => {
        this.authorities = resp.authorities;
      })
      .finally(() => { this.loadingAuthorities = false; });
  }

  get showHeader() {
    return this.$route.name === 'Perfil'
  }

  get padding() {
    return this.$route.name === 'Cronograma' && !this.isMobile ? 'pl-8' : 'pl-5';
  }

  async getRelease() {
    await ReleaseService.getRelease()
      .then(response => this.release = response);
    this.showCountdown = this.release.showCountdown;
    this.showRelease = this.release.showRelease;
  }
}
