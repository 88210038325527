


































































import RevisionDateHistory from '@/domain/drd-2.0/entity/revision-date-history.entity';
import DrdService from '@/domain/drd-2.0/service/drd.service';
import { Vue, Component, Prop } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import Loading from '@/shared/loading/loading.component.vue';
import { VbSnackbarService } from 'bag-of-holding-library';

@Component({
  components: {
    CardTitle,
    Loading,
  },
})
export default class RevisionDateHistoryDrawer extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  }) showDrawer!: boolean;

  revisionDateHistory: RevisionDateHistory[] = [];

  loading: boolean = false;

  created() {
    this.getHistory();
  }

  getHistory() {
    const assignmentId = this.$route.params.id;
    if (!assignmentId) return
    this.loading = true;
    DrdService.getDRDRevisionDateHistory(this.$route.params.id)
      .then((response) => {
        this.revisionDateHistory = response;
      })
      .catch(VbSnackbarService.handleHttpError).finally(() => {
        this.loading = false;
      });
  }
}
