export default class Quantities {
  public especialistQuantity: number;

  public recomendedQuantity: number;

  public noviceQuantity: number;

  public practiceQuantity: number;

  public professionalQuantity: number;

  public expectedQuantity: number;

  constructor(obj: any = {}) {
    this.especialistQuantity = obj.especialistQuantity || 0;
    this.recomendedQuantity = obj.recomendedQuantity || 0;
    this.noviceQuantity = obj.noviceQuantity || 0;
    this.practiceQuantity = obj.practiceQuantity || 0;
    this.professionalQuantity = obj.professionalQuantity || 0;
    this.expectedQuantity = obj.expectedQuantity || 0;
  }
}
